import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import { isString } from '@dmp/utils'
import { useAppDispatch } from '../../app/hooks'
import { resetCreation } from '../../features/segment'
import { CreateLookalikeSegment } from './components/LookalikeSegment/CreateLookalikeSegment'
import { CreateNormalSegment } from './components/NormalSegment/CreateNormalSegment'
import { CreateSelfSegment } from './components/SelfSegment/CreateSelfSegment'
import { PersonalSegmentEditor } from './components/PersonalSegment'

// 新建人群页面
export const SegmentPageCreate: React.FC = () => {
  const { search } = useLocation()
  const { type, seed } = qs.parse(search) || {}
  const dispatch = useAppDispatch()
  const his = useHistory()

  useEffect(() => {
    dispatch(resetCreation())
  }, [dispatch])

  const handleSuccess = (id: string | number) => his.replace(`read/${id}`)

  // 新建多维标签人群
  if (!type || type === 'default') return <CreateNormalSegment onSuccess={handleSuccess} />

  // 人群放大 / 受众相似
  if (type === 'lookalike')
    return <CreateLookalikeSegment seedSegmentId={isString(seed) ? seed : ''} onSuccess={handleSuccess} />

  // 新建自有人群包
  if (type === 'self') return <CreateSelfSegment onSuccess={handleSuccess} />

  // 新建个性化人群包
  if (type === 'personalized') return <PersonalSegmentEditor onSuccess={handleSuccess} />

  return <div>bad type: {type}</div>
}
