import React from 'react'
import { toast } from 'react-toastify'
import { Layout } from '@dmp/components'
import { ConfigProvider } from 'react-avatar'
import { eventBus, EventNames } from '../../utils/eventBus'
import { useTracker } from '../../utils/tracker'
import PageHeader from '../../components/PageHeader'
import { sessionContext } from '../../sessionContext'
import RequireAuth from '../../components/RequireAuth'
import { bootstrapApplication } from '../../features/global'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { isAuthenticated, selectSession } from '../../features/global/globalSlice'
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom'
import { MyAsyncPrompt, MyAsyncPromptProvider } from '../../components/MyAsyncConfirm'
import { SafeRoute } from '../../features/global'
import HomePage from '../../pages/HomePage'
import StaffsPage from '../../pages/StaffsPage'
import OrganizationPage from '../../pages/OrganizationPage'
import LaunchDetailPage from '../../pages/LaunchDetailPage'
import {
  apiBaseUrl,
  defaultAuthenticationPath,
  isProduction,
  noAuthRequiredPathWhiteList,
  colors,
} from '../../constants'
import WorkspaceLayout from '../WorkspaceLayout'

// 这些地址都不需要再跳到登陆页面了
const whitelist = noAuthRequiredPathWhiteList

// AuthFailFallback 的 children 范围一旦出现 HTTP_401 错误，将默认跳转到登录页面
const AuthFailFallback = withRouter(({ children }) => {
  const his = useHistory()
  const { pathname } = useLocation()
  const gotoLogin = React.useCallback(() => his.push(`${defaultAuthenticationPath}?from=${pathname}`), [his, pathname])

  React.useEffect(() => {
    const handleHttp401 = () => {
      if (whitelist.includes(pathname)) {
        // URI 白名单，不需要跳转到 LOGIN
        return
      }
      toast.error(`需要登录，将自动跳转到登录页面 ${pathname}`, {
        autoClose: 800,
        onClose: gotoLogin,
      })
    }

    eventBus.addListener(EventNames.Http401, handleHttp401)
    return () => {
      eventBus.removeListener(EventNames.Http401, handleHttp401)
    }
  }, [gotoLogin, pathname])

  return <>{children}</>
})

/**
 * 统一 PV 埋点
 */
const PageViewWatcher: React.FC = () => {
  const { track } = useTracker()
  const his = useHistory()

  React.useEffect(() => {
    track('PageView', {
      path: '/',
    })
  }, [track])

  React.useEffect(() => {
    if (!his) {
      return () => {}
    }
    const unreg = his.listen(e => {
      track('PageView', {
        path: e.pathname,
        search: e.search,
      })
    })
    return () => unreg()
  }, [his, track])

  return <></>
}

const BaseLayout: React.FC = ({ children }) => {
  const { track } = useTracker()
  const dispatch = useAppDispatch()
  const session = useAppSelector(selectSession)
  const authed = useAppSelector(isAuthenticated)
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    const bootstrap = async () => {
      // 执行完 bootstrap 才认为是 App 初始完成，无论是否有 authenticated
      await dispatch(bootstrapApplication())
      setInitialized(true)
      track('AppBootstrap')
    }
    bootstrap()
  }, [dispatch, track])

  if (!initialized) {
    return (
      <div className='full-screen-mask d-flex justify-content-center align-items-center'>
        <div className='h6'>正在加载应用 ...</div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <PageViewWatcher />
      <AuthFailFallback>
        <sessionContext.Provider value={{ current: session, login: () => {}, logout: () => {} }}>
          <RequireAuth>
            <Layout>
              <Layout.Header>
                <PageHeader />
              </Layout.Header>
              <Layout.Content>
                <div data-app-info={`isProd:${isProduction} apiBaseUrl:${apiBaseUrl} authenticated:${authed}`} />
                <ConfigProvider colors={colors}>
                  <MyAsyncPromptProvider>
                    <MyAsyncPrompt />
                    {children}
                    <Switch>
                      <SafeRoute exact path='/' Component={HomePage} />
                      <SafeRoute
                        exact
                        path='/origination/profile'
                        hasAnyRole={['root', 'admin']}
                        Component={OrganizationPage}
                      />
                      <SafeRoute
                        exact
                        path='/origination/staffs'
                        hasAnyRole={['root', 'admin']}
                        Component={StaffsPage}
                      />
                      <SafeRoute
                        exact
                        path='/origination/launch_detail'
                        hasAnyRole={['root']}
                        Component={LaunchDetailPage}
                      />
                      <Route path='/workspaces/:workspaceId' component={WorkspaceLayout} />
                      <Route path='*'>
                        <Redirect to={{ pathname: '/error', search: `?from=${window.location.pathname}` }} />
                      </Route>
                    </Switch>
                  </MyAsyncPromptProvider>
                </ConfigProvider>
              </Layout.Content>
            </Layout>
          </RequireAuth>
        </sessionContext.Provider>
      </AuthFailFallback>
    </React.Fragment>
  )
}

export default BaseLayout
