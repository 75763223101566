import React from 'react'
import qs from 'query-string'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { Workspace } from '@dmp/api-sdk'
import { apiClient } from '../../utils/apiClient'
import { useRBAC } from '../../features/global/hooks'
import { BasePageLayout } from '../../layout/PageLayout'
import CreateWorkSpaceModal from './CreateWorkSpaceModal'
import { Button, Table, TableCellParams, Dialog, Loading } from '@dmp/components'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectOrgQuota, selectSession } from '../../features/global/globalSlice'
import { systemAbilityEnum } from '../../constants'
import WsQuotaLine from '../../components/WsQuotaLine'

const WsQuotaInfoDialog: React.FC<{
  visible: boolean
  onClose: (refresh?: boolean) => void
  workspaceId?: string
}> = ({ onClose, visible, workspaceId }) => {
  const session = useAppSelector(selectSession)

  const { data: wsInfo, mutate: wsInfoMutate } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const workspaceData = await apiClient.queryWorkspaceInfo(workspaceId)
    return workspaceData?.success ? workspaceData?.data : null
  })
  const { mutate, isLoading, data } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryWsQuota({ workspaceId: workspaceId })
    if (!res?.success) return Promise.reject()
    return res?.data?.result
  })

  const list = React.useMemo(
    () => [
      { label: '用户数量', key: 'MEMBER_QUOTA' },
      { label: '角色数量', key: 'ROLE_QUOTA' },
      { label: '我的人群数量', key: 'SEGMENT_QUOTA' },
      {
        label: '人群实时可用数量',
        key: 'SEGMENT_CALL_STATUS_SUCCESS_QUOTA',
        hidden: !systemAbilityEnum.hasAbility('RealTimeCall', wsInfo?.channel?.name),
      },
      { label: '人群包推送数量（广点通）', key: 'SEGMENT_PUSH_SUCCESS_QUOTA_TENCENT' },
      { label: '人群包推送数量（巨量引擎）', key: 'SEGMENT_PUSH_SUCCESS_QUOTA_OCEANENGINE' },
      { label: 'DMP授权管理数量（广点通）', key: 'OAUTH_SUCCESS_QUOTA_TENCENT' },
      { label: 'DMP授权管理数量（巨量引擎）', key: 'OAUTH_SUCCESS_QUOTA_OCEANENGINE' },
      {
        label: 'RTA投放管理数量（广点通）',
        key: 'RTA_LAUNCH_BIND_QUOTA_TENCENT',
        hidden: session?.user?.accountType !== 'RTA' || !systemAbilityEnum.hasAbility('RTA', wsInfo?.channel?.name),
      },
      {
        label: 'RTA投放管理数量（巨量引擎）',
        key: 'RTA_LAUNCH_BIND_QUOTA_OCEANENGINE',
        hidden: session?.user?.accountType !== 'RTA' || !systemAbilityEnum.hasAbility('RTA', wsInfo?.channel?.name),
      },
    ],
    [session?.user?.accountType, wsInfo?.channel?.name]
  )

  React.useEffect(() => {
    if (visible) {
      wsInfoMutate()
      mutate()
    }
  }, [visible])

  return (
    <Dialog width={550} header='配额查看' onClose={() => onClose()} onConfirm={() => onClose()} visible={visible}>
      <Loading loading={isLoading}>
        <div className='row my-2'>
          <label className='col-6'></label>
          <span className='col-3'>已有</span>
          <span className='col-3'>剩余</span>
        </div>
        {list?.map(item =>
          item.hidden ? null : (
            <div className='row my-2' key={item.key}>
              <label className='col-6'>{item.label}</label>
              <span className='col-3 text-tips'>{data?.[item.key]?.useCount || '0'}</span>
              <span className='col-3 text-tips'>{data?.[item.key]?.surplusCount || '0'}</span>
            </div>
          )
        )}
      </Loading>
    </Dialog>
  )
}

const HomePage: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { hasSystemRole } = useRBAC()
  const orgQuotaResult = useAppSelector(selectOrgQuota)

  const [show, setShow] = React.useState(false)
  const [quotaVisible, setQuotaVisible] = React.useState(false)
  const [spaceInfo, setSpaceInfo] = React.useState<Workspace>()
  const { page = '1', size = '10' } = qs.parse(location.search)

  const canCreate = hasSystemRole({ role: 'root' }) || hasSystemRole({ role: 'admin' })

  const handleEditSpace = React.useCallback(space => {
    setSpaceInfo(space)
    setShow(true)
  }, [])

  const handleViewQuota = React.useCallback(space => {
    setSpaceInfo(space)
    setQuotaVisible(true)
  }, [])

  const {
    isLoading,
    data: workspaceData,
    refetch,
  } = useQuery(
    ['queryWorkspacePage', page, size],
    async () => {
      const res = await apiClient.queryWorkspacePage({
        page: Number(page) - 1,
        size: Number(size),
        sort: 'createdAt,desc',
      })
      if (res.success) {
        return res.data
      } else {
        toast.error(res.message)
        return
      }
    },
    {
      keepPreviousData: true,
    }
  )

  const columns = React.useMemo(() => {
    return [
      {
        title: '名称',
        width: 200,
        colKey: 'name',
        ellipsis: true,
        cell: ({ row }: TableCellParams<Workspace>) => <Link to={`/workspaces/${row.id}`}>{row.name}</Link>,
      },
      { title: '描述', colKey: 'description', width: 150, ellipsis: true },
      {
        title: '人员数量',
        colKey: 'members',
        width: 120,
        cell: ({ row }: TableCellParams<Workspace>) => row.members?.length,
      },
      {
        title: '创建时间',
        width: 200,
        colKey: 'createdAt',
        cell: ({ row }: TableCellParams<Workspace>) => (row.createdAt ? UTCToLocal(row.createdAt) : ''),
      },
      {
        title: '操作',
        width: 210,
        colKey: 'action',
        fixed: 'right' as const,
        cell: ({ row }: TableCellParams<Workspace>) => {
          return (
            <>
              <Button variant='text' theme='primary' className='me-4' onClick={() => handleViewQuota(row)}>
                配额查看
              </Button>
              {canCreate && (
                <Button variant='text' theme='primary' className='me-4' onClick={() => handleEditSpace(row)}>
                  编辑
                </Button>
              )}
              <Button variant='text' theme='primary' onClick={() => history.push(`/workspaces/${row.id}`)}>
                进入
              </Button>
            </>
          )
        },
      },
    ]
  }, [canCreate, handleEditSpace, handleViewQuota, history])

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?page=${current}&size=${pageSize}`)
    },
    [match, history]
  )

  const handleHideModal = React.useCallback(
    (isRefresh?: boolean) => {
      if (isRefresh) refetch()
      setShow(false)
      setQuotaVisible(false)
      setSpaceInfo(undefined)
    },
    [setShow, refetch]
  )

  return (
    <BasePageLayout
      title='工作空间管理'
      headerRight={
        <>
          <WsQuotaLine
            list={[
              { label: '已有工作空间数量', value: orgQuotaResult?.WORKSPACE_QUOTA?.useCount },
              { label: '剩余工作空间数量', value: orgQuotaResult?.WORKSPACE_QUOTA?.surplusCount },
            ]}
          />
          {canCreate ? (
            <Button icon={<i className='iconfont icon-add-select' />} onClick={() => setShow(true)}>
              新建工作空间
            </Button>
          ) : null}
        </>
      }
    >
      <Table
        columns={columns}
        data={workspaceData?.items || []}
        empty='暂无工作空间，请进行创建'
        loading={isLoading}
        rowKey='id'
        pagination={{
          current: Number(page),
          pageSize: Number(size),
          total: workspaceData?.total || 0,
          onChange: onPaginationChange,
        }}
      />
      <CreateWorkSpaceModal show={show} onHide={handleHideModal} defaultValues={spaceInfo} />
      <WsQuotaInfoDialog visible={quotaVisible} onClose={handleHideModal} workspaceId={spaceInfo?.id} />
    </BasePageLayout>
  )
}

export default HomePage
