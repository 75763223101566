import clsx from 'clsx'
import './TargetingEditor.scss'
import GeoEditor from './GeoEditor'
import { isStringArray, noop } from '@dmp/utils'
import React, { useCallback, useState } from 'react'
import { TagOption, UITag, Component as FormComponent, UITagOption } from '../../../../shared/models'

// 人群标签编辑大小
export type TargetingEditorSize = 'default' | 'small' | 'large'

const defaultOpt: TagOption = {
  value: '-1',
  text: '不限',
}

const TargetLineWrapper: React.FC<{ label?: string }> = ({ label = '', children }) => (
  <div className='targeting-line'>
    <div className='line-title'>{`${label}`}</div>
    <div className='line-component'>{children}</div>
  </div>
)

const whatIsDefaultSelects = (defaultVal?: string[] | unknown): Record<string, boolean> => {
  const ret = { [defaultOpt.value]: true }
  if (!defaultVal) {
    return ret
  }

  if (isStringArray(defaultVal) && defaultVal.length > 0) {
    defaultVal.forEach(v => {
      ret[v] = true
    })
    delete ret[defaultOpt.value]
  }
  return ret
}

const TargetLine: React.FC<{
  tag: UITag
  onChange?: (tagName: string, params: string[]) => void
  readOnly?: boolean
  defaultValue?: string[] | unknown
}> = ({ tag, onChange = noop, readOnly = false, defaultValue }) => {
  const [selects, setSelects] = useState<Record<string, boolean>>(whatIsDefaultSelects(defaultValue))
  const [selectedOption, setSelectedOption] = useState<TagOption | undefined>(undefined)

  const { component, status } = tag

  const handleChange = useCallback(
    (tagName: string, params: string[]) => {
      if (onChange) {
        onChange(tagName, params)
      }
    },
    [onChange]
  )

  const handleCityPickerChange = useCallback(
    (valueOfEditor: string[], ...typeN: boolean[]) => {
      const indicator = `:regions:${typeN.map(t => (t ? '1' : '0')).join(':')}:`
      setTimeout(() => handleChange(tag.name, [indicator, ...valueOfEditor]), 200)
    },
    [handleChange, tag]
  )

  const renderEditor = useCallback(
    (aTag: UITag, tagOpt?: UITagOption) => {
      if (selects[defaultOpt.value]) {
        return <></>
      }

      // 无需 editor
      if (!tagOpt || !tagOpt.editor) {
        return <></>
      }

      if (tagOpt.editor === 'CityPicker' && selects[tagOpt.value]) {
        return (
          <TargetLineWrapper>
            <GeoEditor data={[]} onChange={handleCityPickerChange} />
          </TargetLineWrapper>
        )
      }
      if (tagOpt.editor === 'GeoFencing' && selects[tagOpt.value]) {
        return (
          <TargetLineWrapper>
            <p className='text-warning'>地理围栏圈选编辑器还未开发!</p>
          </TargetLineWrapper>
        )
      }
      return <TargetLineWrapper>{`NO EDITOR FOUND ${tagOpt.editor}`}</TargetLineWrapper>
    },
    [selects, handleCityPickerChange]
  )

  const renderComponent = useCallback(() => {
    if (component && component.type === 'options') {
      const toggle = (compo: FormComponent, opt: TagOption) => () => {
        if (readOnly) {
          return
        }

        // 选择了默认选项
        if (opt === defaultOpt) {
          setSelects({ [opt.value]: true })
          handleChange(tag.name, [])
          return
        }

        // 单选
        if (compo.type === 'options' && !compo.multiSelect) {
          const params = [opt.value]
          setSelectedOption(opt)
          setSelects({ [opt.value]: true })
          handleChange(tag.name, params)
          return
        }

        // 多选
        if (compo && compo.type === 'options' && compo.multiSelect) {
          // 多选时，不需要保存最后一个选择 opt
          setSelectedOption(undefined)

          // 多选
          setSelects(ss => {
            const newSS = { ...ss, [opt.value]: !ss[opt.value], [defaultOpt.value]: false }
            const params = compo?.options.filter(opt0 => !!newSS[opt0.value]).map(opt1 => opt1.value) || []

            handleChange(tag.name, params)

            // 没有一个被选中，需要将默认选中
            if (Object.keys(newSS).filter(k => newSS[k]).length === 0) {
              return { [defaultOpt.value]: true }
            }

            return newSS
          })
        }
      }

      return (
        <>
          <ul className={clsx('targeting-options', { 'multi-select': component.multiSelect, 'is-readonly': readOnly })}>
            <li
              className={clsx('option-default', { selected: selects[defaultOpt.value] })}
              onClick={toggle(component, defaultOpt)}
              onKeyDown={noop}
            >
              <span>{defaultOpt.text}</span>
            </li>

            {component.options
              ? component.options.map(opt => (
                  <li
                    key={opt.value}
                    className={selects[opt.value] ? 'selected' : ''}
                    onClick={toggle(component, opt)}
                    onKeyDown={noop}
                    title={opt.title || ''}
                  >
                    <span>{opt.text}</span>
                  </li>
                ))
              : ''}
          </ul>
        </>
      )
    }
    return <div>no component</div>
  }, [component, handleChange, tag, setSelectedOption, selects, readOnly])

  // TODO: 默认选中默认选项，考虑到支持编辑，后续会增加 value 属性
  // useEffect(() => setSelects({ [defaultOpt.value]: true }), [])

  if (status === 'AVAILABLE') {
    return (
      <>
        <TargetLineWrapper label={tag.cname}>{renderComponent()}</TargetLineWrapper>
        {renderEditor(tag, selectedOption)}
      </>
    )
  }

  return (
    <TargetLineWrapper label={tag.cname}>
      <span className='badge rounded-pill bg-info text-light'>开发中，敬请期待</span>
    </TargetLineWrapper>
  )
}

// 定向模块条件编辑器
const TargetingEditor: React.FC<{
  data: UITag[]
  size?: TargetingEditorSize
  largeMode?: boolean
  onItemChange?: (tagName: string, params: string[]) => void
  onChange?: (newValue: Record<string, string[]>) => void
  defaultValue?: Array<{ name: string; params: string[] }>
  readOnly?: boolean
}> = ({ data, onChange, onItemChange, size = 'default', largeMode = false, defaultValue = [], readOnly = false }) => {
  const [value, setValue] = useState<Record<string, string[]>>({})

  const handleChange = useCallback(
    (tagName: string, params: string[]) => {
      setValue(ov => {
        const newValue = { ...ov, [tagName]: params }

        if (onChange) {
          onChange(newValue)
        }

        return newValue
      })

      if (onItemChange) {
        onItemChange(tagName, params)
      }
    },
    [onChange, onItemChange]
  )

  const findValues = useCallback(
    (name: string): string[] => {
      if (!defaultValue) {
        return []
      }

      let ret: string[] = []

      defaultValue.forEach(tag => {
        if (tag.name === name) {
          ret = [...tag.params]
        }
      })

      return ret
    },
    [defaultValue]
  )

  return (
    <div
      className={clsx('targeting-lines', {
        'targeting-lg': largeMode || size === 'large',
        'targeting-sm': size === 'small',
      })}
    >
      {data.map(tag => (
        <TargetLine
          key={tag.name}
          tag={tag}
          onChange={handleChange}
          readOnly={readOnly}
          defaultValue={findValues(tag.name)}
        />
      ))}
    </div>
  )
}

export default TargetingEditor
