/**
 * 复制文本到 clipboard
 *
 * 值得注意的是，默认情况下，浏览器的 clipboard 需要 https 协议或者 localhost 主机地址才会可用。
 * https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
 *
 * @param text 需要复制的文本
 * @returns true 复制成功， false 复制不成功
 */
export const copyToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    return Promise.reject('no clipboard support, and no fallback')
  }

  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (e) {
    return false
  }
}
