import React from 'react'
import { useMutation } from 'react-query'
import { apiClient } from '../../utils/apiClient'
import { Dialog, Form, FormItem, Input, Select } from '@dmp/components'
import { platformEnum } from '../../constants/enums'
import { toast } from 'react-toastify'
import { useAppSelector } from '../../app/hooks'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { PlatformEnum } from '@dmp/api-sdk'

export interface NewAuthFormValuesType {
  id?: number
  advertPlatformAccountId: string //广告平台账号id
  accountName: string //账号名称
  advertPlatform: PlatformEnum //广告平台类型
}

export default (props: {
  show: boolean
  onHide: (refresh?: boolean) => void
  defaultValues?: NewAuthFormValuesType
  authCallback: (url: URL) => void
}) => {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const { show, onHide, defaultValues, authCallback } = props

  const { isLoading, mutate } = useMutation(
    (params: NewAuthFormValuesType) => {
      if (!workspaceId) return Promise.reject()
      const data = { workspaceId, ...params }
      return defaultValues?.id
        ? apiClient.updateAdvAuthAccount({ id: defaultValues.id, ...data })
        : apiClient.createAdvAuthAccount(data)
    },
    {
      onSuccess: res => {
        if (!res.success) {
          toast.error(res.message)
          return
        }
        onHide()
        authCallback(res.data)
      },
      onError: err => {
        toast.error('系统异常')
      },
    }
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values: NewAuthFormValuesType = formRef.current?.getFieldsValue(true)
      mutate(values)
    },
    [mutate]
  )

  const rules = React.useMemo(
    () => ({
      accountName: [{ required: true, message: '请输入账号名称' }],
      advertPlatformAccountId: [{ required: true, message: '请输入广告平台账号ID' }],
      advertPlatform: [{ required: true, message: '请选择所属广告平台' }],
    }),
    []
  )

  return (
    <Dialog
      visible={show}
      onClose={() => onHide()}
      header={`${defaultValues?.id ? '编辑' : '新建'}授权`}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ content: '开始授权', loading: isLoading }}
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='账号名称' name='accountName' initialData={defaultValues?.accountName}>
          <Input placeholder='请输入账号名称，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem
          label='广告平台账号ID'
          name='advertPlatformAccountId'
          initialData={defaultValues?.advertPlatformAccountId}
        >
          <Input maxlength={32} placeholder='请输入广告平台账号ID，最多32个字符' />
        </FormItem>
        <FormItem label='所属广告平台' name='advertPlatform' initialData={defaultValues?.advertPlatform}>
          <Select options={platformEnum.list} placeholder='请选择广告平台' />
        </FormItem>
      </Form>
    </Dialog>
  )
}
