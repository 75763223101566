/**
 * Mixpanel 埋点
 */
export const mixpanelToken =
  localStorage.getItem('REACT_APP_MIXPANEL_TOKEN') || process.env.REACT_APP_MIXPANEL_TOKEN || undefined

/**
 * 是否开启 Tracker debug 模式
 */
export const trackerDebug =
  localStorage.getItem('REACT_APP_TRACKER_DEBUG') === 'true' || process.env.REACT_APP_TRACKER_DEBUG === 'true' || false
