import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import Filters from './Filters'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { useExpand, useThrottledEffect } from '../../../hooks'
import { apiClient } from '../../../utils/apiClient'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { PushRecord, QueryPushRecordsReg, SegmentPushStatusEnum } from '@dmp/api-sdk'
import { digitization } from '@dmp/utils'
import { crowdPushStatusEnum, platformEnum } from '../../../constants'
import { Popup, Table, TableCellParams, Icon, TableColumnType } from '@dmp/components'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import StatusLabel from '../../../components/StatusLabel'

export default () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { expand, expandIcon } = useExpand()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const [filters, setFilters] = React.useState<QueryPushRecordsReg>()
  const { page = '1', size = '10' } = qs.parse(location.search)

  const {
    isLoading,
    mutate,
    data: dataSource,
  } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryPushRecordsPage(workspaceId, {
      page: Number(page) - 1,
      size: Number(size),
      sort: 'segmentPushTime,desc',
      ...(filters || {}),
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?page=${current}&size=${pageSize}`)
    },
    [match, history]
  )

  const handleSearch = React.useCallback(
    (values?: QueryPushRecordsReg) => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const columns = React.useMemo<TableColumnType<PushRecord>[]>(
    () => [
      {
        title: '推送时间',
        colKey: 'segmentPushTime',
        width: 200,
        fixed: 'left' as const,
        cell: ({ row }) => UTCToLocal(row.segmentPushTime),
      },
      {
        title: '人群包ID',
        width: 200,
        colKey: 'remoteSegmentId',
      },
      {
        title: '人群包名称',
        width: 200,
        colKey: 'segmentName',
        ellipsis: true,
      },
      {
        title: '广告平台',
        width: 150,
        colKey: 'advertPlatform',
        cell: ({ row }) => platformEnum.map.get(row?.advertPlatform)?.label,
      },
      {
        title: '所属企业',
        colKey: 'organizationName',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => row.organizationEntity?.displayName,
      },
      {
        title: '所属数据源',
        colKey: 'channel',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => row.segmentChannel?.name,
      },
      {
        title: '所属工作空间',
        colKey: 'workspaceName',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => row.workspace?.displayName,
      },
      {
        title: '人群包行数',
        colKey: 'segmentLineCount',
        width: 150,
        cell: ({ row }) => digitization(row.segmentLineCount),
      },
      {
        title: '推送状态',
        colKey: 'status',
        width: 150,
        cell({ row }) {
          const obj = crowdPushStatusEnum.map.get(row.status)
          const err = row.status === SegmentPushStatusEnum.Failed ? row.errMsg : ''
          return <StatusLabel color={obj?.color} label={obj?.label} error={err} />
        },
      },
    ],
    []
  )

  useThrottledEffect(mutate, [page, size, filters])

  return (
    <WorkspacePageLayout title='推送记录' headerRight={expandIcon}>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          columns={columns}
          data={dataSource?.items || []}
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: dataSource?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
    </WorkspacePageLayout>
  )
}
