import './index.scss'
import clsx from 'clsx'
import axios from 'axios'
import React from 'react'
import { add, format, parseISO, sub } from 'date-fns'
import { noop } from '@dmp/utils'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { EstimateAction, IEstimateState } from '../../types'
import TargetingEditor from '../TargetingEditor/TargetingEditor'
import { segmentContext, ISegmentContext } from '../../segmentContext'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  loadRegions,
  selectStatus,
  selectRegions,
  unloadRegions,
  selectErrorsOnCreation,
  createSegment,
  startLabelSegmentCreation,
} from '../../../../features/segment'
import { useQuery } from 'react-query'
import { SegmentResource } from '@dmp/api-sdk'
import { dateFmt } from '../../../../constants'
import { apiClient } from '../../../../utils/apiClient'
import { TagParamsTuple } from '../../../../shared/models'
import { Form, FormItem, Input, Textarea, DateRangePicker, Button } from '@dmp/components'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import { WorkspacePageLayout, Module } from '../../../../layout/PageLayout'

interface CreateSegmentParamsDraft {
  name: string
  description: string
  startDate: string
  endDate: string
  targeting: Array<{ name: string; params: string[] }>
}

const initialDraft: CreateSegmentParamsDraft = {
  name: '',
  description: '',
  targeting: [],
  startDate: format(new Date(), dateFmt),
  endDate: format(add(new Date(), { days: 30 }), dateFmt),
}

interface CreateNormalProps {
  onSuccess?: (newId: number | string) => void
}

// 人群预估面板
export const SegmentEstimate: React.FC<{ velocity: number }> = ({ velocity = 0 }) => {
  const sizeString = React.useMemo(() => `${new Intl.NumberFormat('en-US', {}).format(velocity)}`, [velocity])
  return (
    <div className='estimate-modal__layout'>
      <div className='estimate-modal__line'>
        <div className='title'>预估最大覆盖人群</div>
        <div className='estimate-number'>{sizeString}</div>
      </div>
      {/* <div className='p-3'>
        <h4>预估单日最大曝光量</h4>
        <p>???</p>
      </div>
      <div className='p-3'>
        <button className='btn btn-primary' type='button' onClick={rewind}>
          重来
        </button>
      </div> */}
    </div>
  )
}

const initialState: IEstimateState = {
  isDoingEstimate: false,
  estimationElapseInSeconds: 0,
  estimateSize: 0,
  targeting: [],
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: IEstimateState = initialState, action: EstimateAction): IEstimateState => {
  switch (action.action) {
    case 'targeting/addTagParam': {
      const { tagName, param } = action.payload
      return {
        ...state,
        targeting: state.targeting.map(t => {
          if (t.name === tagName) {
            return {
              ...t,
              params: [...t.params, param],
            }
          }
          return t
        }),
      }
    }
    case 'targeting/removeTagParam':
      // TODO
      return state
    case 'targeting/setTagParams': {
      const { tagName, params } = action.payload
      if (tagName === 'pay_stability') {
        // TODO: 忽略
        return state
      }
      const tag = state.targeting.find(x => x.name === tagName)
      if (tag) {
        if (params?.length > 0) {
          return {
            ...state,
            targeting: [
              ...state.targeting.map(t => {
                if (t.name === tagName) {
                  return {
                    ...t,
                    params: [...params],
                  }
                }
                return t
              }),
            ],
          }
        }
        return {
          ...state,
          targeting: [...state.targeting.filter(t => t.name !== tagName)],
        }
      }
      return {
        ...state,
        targeting: [
          ...state.targeting,
          {
            name: tagName,
            params: [...params],
          },
        ],
      }
    }
    case 'targeting/updateEstimateSize':
      return {
        ...state,
        estimateSize: action.payload.size,
      }
    case 'targeting/startEstimate':
      return {
        ...state,
        isDoingEstimate: true,
        estimationElapseInSeconds: 0,
      }
    case 'targeting/finishEstimate':
      return {
        ...state,
        isDoingEstimate: false,
        estimationElapseInSeconds: 0,
      }
    case 'targeting/stillEstimate':
      return {
        ...state,
        estimationElapseInSeconds: state.estimationElapseInSeconds + 1,
      }
    case 'targeting/reset':
      return {
        ...initialState,
      }
    default:
      return state
  }
}

const StatusModal: React.FC<{
  show: boolean
  elapseInSeconds: number
}> = ({ show, elapseInSeconds }) => {
  const isShow = show

  const waitingText = React.useMemo(() => {
    if (elapseInSeconds > 15) {
      return '抱歉，实在是有点久了 :('
    }
    if (elapseInSeconds > 10) {
      return '好像还需要更多一点时间等待 :('
    }
    if (elapseInSeconds > 5) {
      return '还需要多一点时间等待'
    }
    return '努力查询中'
  }, [elapseInSeconds])

  return (
    <>
      <div
        className={clsx('modal fade', { show: isShow })}
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
        style={{ display: show ? 'block' : 'none' }}
      >
        <div className='modal-dialog '>
          <div className='modal-content bg-light shadow-sm'>
            <div className='modal-body'>
              <div className='d-flex justify-content-start  align-items-center'>
                <div className='px-2'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading</span>
                  </div>
                </div>
                <div
                  className={clsx('pt-0 px-2', {
                    'text-muted': elapseInSeconds <= 10,
                    'text-warning': elapseInSeconds > 10,
                  })}
                >
                  {waitingText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TargetingSection: React.FC<{
  onChange?: (
    targeting: Array<{
      name: string
      params: string[]
    }>
  ) => void
}> = ({ onChange = noop }) => {
  const errors = useAppSelector(selectErrorsOnCreation)
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const { setTagParams, targeting, dispatch, isDoingEstimate, estimationState } = React.useContext(segmentContext)

  // 跟随定向条件的变化，而自动发起估算人群规模的请求
  React.useEffect(() => {
    if (!workspaceId) return
    const src = axios.CancelToken.source()
    const cancelToken = src.token

    dispatch({ action: 'targeting/startEstimate' })

    const intervalHandle = setInterval(() => {
      // 开始计时，每秒跳一次
      dispatch({
        action: 'targeting/stillEstimate',
      })
    }, 1000)

    apiClient
      .estimateSegment({ targeting: [...targeting], cancelToken, workspaceId })
      .then(data => {
        if (data.success) {
          dispatch({
            action: 'targeting/updateEstimateSize',
            payload: {
              size: data.count ?? 0,
            },
          })
        }
        if (!data.success) {
          toast.error(`出错了：${data.message || '未知'}`)
        }
      })
      .catch(e => {
        if (`${e}`.indexOf('Cancel') > -1) {
          // cancel
          return
        }
        // eslint-disable-next-line no-console
        console.error(e)
      })
      .finally(() => {
        clearInterval(intervalHandle) // 停止计时
        dispatch({ action: 'targeting/finishEstimate' })
      })

    return () => {
      console.log(`手欠了是吧，取消 token`)
      src.cancel()
    }
  }, [targeting, dispatch])

  const handleItemChange = React.useCallback((n: string, p: string[]) => setTagParams(n, p), [setTagParams])

  const handleChange = React.useCallback(
    (val: Record<string, string[]>) => {
      const targeting0 = Object.keys(val).map(k => ({ name: k, params: val[k] } as TagParamsTuple))
      if (onChange) {
        onChange(targeting0)
      }
    },
    [onChange]
  )

  const { data: tags } = useQuery(['queryTagsByChannel', workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryTagsByChannel({ workspaceId })
    return res?.success ? res.data || [] : []
  })

  return (
    <>
      <div className=''>
        <TargetingEditor largeMode data={tags || []} onChange={handleChange} onItemChange={handleItemChange} />

        {errors.targeting ? (
          <ul>
            {errors.targeting.map(msg => (
              <li key={msg}>
                <span className='text-danger'>{msg}</span>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <StatusModal show={isDoingEstimate} elapseInSeconds={estimationState?.estimationElapseInSeconds || 0} />
    </>
  )
}

const creationContext = React.createContext<{ draft: Partial<SegmentResource> }>({ draft: {} })

const BaseInfo: React.FC<{
  info: CreateSegmentParamsDraft
  onFieldChange?: (field: string, value: string) => void
}> = ({ onFieldChange = noop }) => {
  return (
    <Module title='基本信息' hasGap>
      <FormItem label='人群名称' name='name' rules={[{ required: true, message: '请输入人群名称，最多32个字符' }]}>
        <Input
          placeholder='请输入人群名称，最多32个字符'
          onChange={(val: any) => onFieldChange('name', val)}
          maxlength={32}
        />
      </FormItem>
      <FormItem
        label='人群描述'
        name='description'
        rules={[{ required: true, message: '请输入人群描述，最多256个字符' }]}
      >
        <Textarea
          placeholder='请输入人群描述，最多256个字符'
          onChange={(val: any) => onFieldChange('description', val)}
          maxlength={256}
        />
      </FormItem>
      <FormItem
        label='人群有效期'
        name='dateRange'
        initialData={[initialDraft?.startDate, initialDraft?.endDate]}
        rules={[{ validator: val => val?.every(Boolean), message: '请选择人群有效期' }]}
      >
        <DateRangePicker
          format='YYYY-MM-DD'
          style={{ width: 400 }}
          startDisableDate={{ before: format(sub(new Date(), { days: 1 }), dateFmt) }}
          endDisableDate={{ after: initialDraft?.endDate }}
          onChange={val => {
            onFieldChange('startDate', val?.[0])
            onFieldChange('endDate', val?.[1])
          }}
        />
      </FormItem>
    </Module>
  )
}

const ContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const theDispatch = React.useMemo(() => dispatch, [dispatch])

  // 通过 context 传值或者 fn 都需要处理 memo，防止 children 死循环
  const setTagParams = React.useCallback(
    (tagName: string, params: []) => {
      dispatch({
        action: 'targeting/setTagParams',
        payload: {
          tagName,
          params,
        },
      })
    },
    [dispatch]
  )

  const setEstimateSize = React.useCallback(
    (n: number) =>
      dispatch({
        action: 'targeting/updateEstimateSize',
        payload: {
          size: n,
        },
      }),
    [dispatch]
  )

  const reset = React.useCallback(() => dispatch({ action: 'targeting/reset' }), [dispatch])

  const ctx = {
    estimationState: state,
    estimateSize: state.estimateSize,

    setTagParams,

    getTagParams: tag => {
      const pp = state.targeting.find(t => t.name === tag)?.params || []
      return [...pp]
    },

    setEstimateSize,
    reset,

    dispatch: theDispatch,
    targeting: state.targeting,
    isDoingEstimate: state.isDoingEstimate,
  } as ISegmentContext

  return <segmentContext.Provider value={ctx}>{children}</segmentContext.Provider>
}

/**
 * 通过标签组合创建人群
 */
const CreateNormal: React.FC<CreateNormalProps> = ({ onSuccess = noop }) => {
  const his = useHistory()
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectStatus)
  const regions = useAppSelector(selectRegions)
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const { estimateSize } = React.useContext(segmentContext)
  const [draft, setDraft] = React.useState<CreateSegmentParamsDraft>(initialDraft)

  const handleFieldChange = React.useCallback((field: string, val: string) => {
    if (field === 'name' || field === 'description' || field === 'startDate' || field === 'endDate') {
      setDraft(d => ({ ...d, [field]: val }))
    }
  }, [])

  const handleSubmit = React.useCallback(async () => {
    const promise = await dispatch(
      createSegment({
        type: 'label',
        name: draft.name || '',
        description: draft.description || '',
        startDate: draft.startDate || '',
        endDate: draft.endDate || '',
        targeting: draft.targeting || [],
        estimatedVelocity: estimateSize || 0,
      })
    )

    if (createSegment.rejected.match(promise)) {
      toast.error(promise?.payload?.message || '新建人群异常')
    }

    if (createSegment.fulfilled.match(promise)) {
      if (promise.payload.success) {
        toast.success('新建人群成功，系统正在审核中，请耐心等待!')
        // FIXME: 新建成功之后，跳转到 LIST 还是跳到 DETAIL 需要再考虑一下，倾向于后者。
        onSuccess(promise.payload.id)
      } else {
        toast.error('新建人群不成功')
      }
    }
  }, [dispatch, draft, onSuccess, estimateSize])

  const handleCancel = React.useCallback(() => {
    his.push('list')
  }, [dispatch, his])

  const handleTargetingChange = React.useCallback(
    (targeting: TagParamsTuple[]) => {
      let raw = [...targeting]
      raw = raw.filter(item => item.params.length > 0)
      setDraft(d => ({ ...d, targeting: raw }))
    },
    [setDraft]
  )

  React.useEffect(() => {
    dispatch(startLabelSegmentCreation())
    // dispatch(loadRegions())
  }, [])

  // if (regions.ids.length === 0) {
  //   return <div className='p-4'>加载中，稍候 ...</div>
  // }

  return (
    <creationContext.Provider value={{ draft: {} }}>
      <WorkspacePageLayout title='新建人群' hasBack>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <BaseInfo info={draft} onFieldChange={handleFieldChange} />
          <Module title='用户定向'>
            <div style={{ marginRight: 180 }}>
              <TargetingSection onChange={handleTargetingChange} />
              <SegmentEstimate velocity={estimateSize} />
            </div>
            <div className='mt-5 d-flex justify-content-between'>
              <Button variant='outline' onClick={handleCancel}>
                取消
              </Button>
              <Button loading={status === 'loading'} onClick={() => formRef.current?.submit()}>
                创建并关闭
              </Button>
            </div>
          </Module>
        </Form>
      </WorkspacePageLayout>
    </creationContext.Provider>
  )
}

export const CreateNormalSegment: React.FC<CreateNormalProps> = ({ onSuccess }) => (
  <ContextProvider>
    <CreateNormal onSuccess={onSuccess} />
  </ContextProvider>
)
