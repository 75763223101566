import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { emailReg } from '../../constants'
import { Organization } from '@dmp/api-sdk'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { apiClient } from '../../utils/apiClient'
import { BasePageLayout } from '../../layout/PageLayout'
import { selectOrganization, selectOrgQuota, setOrganization } from '../../features/global/globalSlice'
import { Button, Dialog, Form, FormItem, Input } from '@dmp/components'

export const FillInfoModal: React.FC<{
  show: boolean
  onHide: () => void
  defaultValues: Organization | null
}> = props => {
  const { show, onHide, defaultValues } = props
  const dispatch = useDispatch()
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      const params = { id: defaultValues?.id, ...values, name: values.displayName }
      const res = await apiClient.updateOrganizationInfo(params)
      if (res.success) {
        dispatch(setOrganization(res.data))
        onHide()
        toast.success('操作成功')
      } else {
        toast.error(res.message)
      }
    },
    [defaultValues?.id, dispatch, onHide]
  )

  const rules = React.useMemo(
    () => ({
      displayName: [{ required: true, message: '请输入公司名称' }],
      contactName: [{ required: true, message: '请输入联系人姓名' }],
      email: [
        {
          validator: (val: string) => Boolean(!val || emailReg.test(val)),
          message: '邮箱格式错误，请重新输入',
        },
      ],
      mobile: [
        {
          validator: (val: string) => Boolean(!val || /^\d{11}$/g.test(val)),
          message: '请输入正确的手机号',
        },
      ],
    }),
    []
  )

  return (
    <Dialog visible={show} onClose={onHide} header='基础信息管理' onConfirm={() => formRef.current?.submit()}>
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='公司名称' name='displayName' initialData={defaultValues?.displayName}>
          <Input placeholder='请输入公司名称，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='请输入公司地址' name='address' initialData={defaultValues?.address}>
          <Input placeholder='请输入公司地址，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='联系人姓名' name='contactName' initialData={defaultValues?.contactName}>
          <Input placeholder='请输入联系人姓名,最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='邮箱' name='email' initialData={defaultValues?.email}>
          <Input placeholder='请输入邮箱,最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='手机' name='mobile' initialData={defaultValues?.mobile}>
          <Input placeholder='请输入手机号' maxlength={11} />
        </FormItem>
      </Form>
    </Dialog>
  )
}

export const BasicInfo: React.FC = () => {
  const { search } = useLocation()
  const info = useAppSelector(selectOrganization)
  const [show, setShow] = React.useState(qs.parse(search).from === 'login' ? true : false)
  return (
    <BasePageLayout
      title='基本信息'
      headerRight={
        <Button icon={<i className='iconfont icon-editor' />} onClick={() => setShow(true)}>
          编辑
        </Button>
      }
    >
      <div className='row col-form-label'>
        <label className='col-sm-2'>公司名称</label>
        <span className='col text-muted'>{info?.displayName || '-'}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>公司地址</label>
        <span className='col text-muted'>{info?.address || '-'}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>联系人姓名</label>
        <span className='col text-muted'>{info?.contactName || '-'}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>邮箱</label>
        <span className='col text-muted'>{info?.email || '-'}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>手机</label>
        <span className='col text-muted'>{info?.mobile || '-'}</span>
      </div>
      <FillInfoModal
        show={show}
        onHide={() => {
          setShow(false)
        }}
        defaultValues={info}
      />
    </BasePageLayout>
  )
}
const OrgQuotaInfo: React.FC = () => {
  const orgQuotaResult = useAppSelector(selectOrgQuota)

  return (
    <BasePageLayout title='配额管理信息'>
      <div className='row col-form-label'>
        <label className='col-sm-2'>企业已有工作空间</label>
        <span className='col text-muted'>{orgQuotaResult?.WORKSPACE_QUOTA?.useCount || 0}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>企业剩余工作空间</label>
        <span className='col text-muted'>{orgQuotaResult?.WORKSPACE_QUOTA?.surplusCount || 0}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>企业已有人员</label>
        <span className='col text-muted'>{orgQuotaResult?.USER_QUOTA?.useCount || 0}</span>
      </div>
      <div className='row col-form-label'>
        <label className='col-sm-2'>企业剩余人员管理</label>
        <span className='col text-muted'>{orgQuotaResult?.USER_QUOTA?.surplusCount || 0}</span>
      </div>
    </BasePageLayout>
  )
}

export default () => {
  return (
    <>
      <BasicInfo />
      <OrgQuotaInfo />
    </>
  )
}
