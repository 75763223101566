import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { noop } from '@dmp/utils'
import { Dialog, Select, FormItem, Checkbox, Form } from '@dmp/components'
import { WorkspaceResource, WorkspaceRolePermission } from '@dmp/api-sdk'
import { useAppSelector } from '../../app/hooks'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { apiClient } from '../../utils/apiClient'

const PermissionWrapper = styled.div`
  border: 1px solid #efefef;
  max-height: 200px;
  overflow-y: auto;
`

const PermissionOptionWrapper = styled.div`
  border-radius: 0.2rem;
  background-color: #f3f4f5;
`

interface IState {
  // 已经选择的，尚未保存确认的
  candidates: WorkspaceRolePermission[]

  resources: WorkspaceResource[]
  selectedResource: WorkspaceResource | undefined
}

const initializedState: IState = { candidates: [], resources: [], selectedResource: undefined }

type ActionType =
  | {
      type: 'Reset'
    }
  | {
      type: 'LoadResources'
      payload: {
        resources: WorkspaceResource[]
      }
    }
  | {
      type: 'ActiveResource'
      payload: {
        identifier: string
      }
    }
  | {
      type: 'SelectOne'
      payload: {
        permissionName: string
        resourceIdentifier: string
      }
    }
  | {
      type: 'UnSelectOne'
      payload: {
        permissionName: string
        resourceIdentifier: string
      }
    }

const reducer = (state: IState, action: ActionType): IState => {
  if (!action) {
    return { ...initializedState }
  }

  switch (action.type) {
    case 'LoadResources':
      return {
        ...state,
        resources: action.payload.resources,
      }
    case 'ActiveResource': {
      const { identifier } = action.payload
      return {
        ...state,
        selectedResource: state.resources.find(r => r.identifier === identifier),
      }
    }
    case 'SelectOne': {
      const { permissionName, resourceIdentifier } = action.payload
      const i = state.candidates.findIndex(
        p => p.name === permissionName && p.resourceIdentifier === resourceIdentifier
      )
      if (i > -1 || !state.selectedResource) {
        return { ...state }
      }

      const { name: resourceName, scopes } = state.selectedResource
      const scope = scopes.find(s => s.value === permissionName)
      // 理论上不存
      if (!scope) {
        return { ...state }
      }
      return {
        ...state,
        candidates: [
          ...state.candidates,
          {
            resourceIdentifier,
            resourceName,
            name: permissionName,
            description: scope.description,
          },
        ],
      }
    }
    case 'UnSelectOne': {
      const { permissionName, resourceIdentifier } = action.payload
      const { candidates } = state
      return {
        ...state,
        candidates: candidates.filter(p => p.name !== permissionName || p.resourceIdentifier !== resourceIdentifier),
      }
    }
    default:
      return { ...initializedState }
  }
}

interface PermissionPickerModalProps {
  show?: boolean
  cancel?: () => void
  proceed?: (data?: WorkspaceRolePermission[]) => void
  title?: string
  isAdminRole: boolean
  permissions?: WorkspaceRolePermission[] // 已经存在的权限，这里需要排除掉
}

export const PermissionPickerModal: React.FC<PermissionPickerModalProps> = ({
  permissions = [],
  title = '添加权限',
  show = false,
  cancel = noop,
  proceed = noop,
  isAdminRole,
}) => {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const [state, dispatch] = useReducer(reducer, initializedState)

  useEffect(() => {
    // 关闭，就不要再加载了
    if (!show) {
      return
    }

    const load = async () => {
      if (!workspaceId) {
        return
      }
      const data = await apiClient.listWorkspaceResources({ workspaceId })
      if (data.success && data.data) {
        dispatch({ type: 'LoadResources', payload: { resources: [...data.data] } })
      }
    }

    dispatch({ type: 'Reset' }) // 每次重新显示 modal (或切换 workspace)，都先重置一下 state
    load()
  }, [workspaceId, show])

  const checkedP = (identifier: string, name: string): boolean => {
    return state.candidates.some(perm => perm.name === name && perm.resourceIdentifier === identifier)
  }

  const filterPermission = (list: { value: string; description: string }[], identifier: string) => {
    const pNames = permissions?.map(it => `${it.name}${it.resourceIdentifier}`)
    return list?.filter(it => {
      const isExisted = !pNames.includes(`${it.value}${identifier}`)
      return isAdminRole ? isExisted : isExisted && it.value !== 'manager:workspace_user_role'
    })
  }

  const renderPermissions = () => {
    const res = state.selectedResource
    if (!res) {
      return <></>
    }

    return (
      <FormItem
        label={
          <>
            权限 （已选择<span className='text-primary'>{state.candidates.length}</span>个）
          </>
        }
        name='resources'
      >
        <PermissionWrapper className='d-flex flex-wrap'>
          {filterPermission(res.scopes, res.identifier).map(s => {
            return (
              <PermissionOptionWrapper className='border mx-2 my-1 py-1 px-2' key={s.value}>
                <Checkbox
                  label={s.description}
                  checked={checkedP(res.identifier, s.value)}
                  onChange={checked => {
                    const payload = { permissionName: s.value, resourceIdentifier: res.identifier }
                    if (checked) {
                      dispatch({ type: 'SelectOne', payload })
                    } else {
                      dispatch({ type: 'UnSelectOne', payload })
                    }
                  }}
                />
              </PermissionOptionWrapper>
            )
          })}
        </PermissionWrapper>
      </FormItem>
    )
  }

  return (
    <Dialog
      visible={show}
      header={title}
      onClose={cancel}
      width={800}
      onConfirm={() => proceed(state.candidates)}
      confirmBtnProps={{ disabled: !state.candidates?.length }}
    >
      <Form labelAlign='top'>
        <FormItem label='选择一个工作空间中已经添加的资源' name='identifier'>
          <Select
            placeholder='请选择资源'
            options={(state.resources ?? []) as any}
            keys={{ value: 'identifier', label: 'name' }}
            onChange={val => dispatch({ type: 'ActiveResource', payload: { identifier: val as string } })}
            value={state.selectedResource?.identifier || ''}
          />
        </FormItem>
        {renderPermissions()}
      </Form>
    </Dialog>
  )
}
