import React from 'react'
import { noop } from '@dmp/utils'
import { Button } from '@dmp/components'

export const DangerZone: React.FC<{
  title: string
  subtitle: string
  className?: string
  label?: string
  pending?: boolean
  disabled?: boolean
  onConfirm?: () => void
}> = ({ title, subtitle, className = '', label = 'Action', pending = false, onConfirm = noop, disabled }) => {
  const handleConfirm = React.useCallback(() => {
    onConfirm()
  }, [onConfirm])

  return (
    <div className={`danger-zone d-flex justify-content-between align-items-center ${className}`}>
      <div className='danger-zone-content'>
        <h4>{title}</h4>
        <div>{subtitle}</div>
      </div>
      <div>
        <Button onClick={handleConfirm} theme='danger' loading={pending} disabled={pending || disabled}>
          {label}
        </Button>
      </div>
    </div>
  )
}
