import Avatar from 'react-avatar'
import { User } from '@dmp/api-sdk'
import { EventNames } from './shared'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { emailReg } from '../../constants'
import { userAtom } from '../../recoil/user'
import { dialog, Tag } from '@dmp/components'
import { localDetailContext } from './context'
import { eventBus } from '../../utils/eventBus'
import { useAppSelector } from '../../app/hooks'
import { RolesTab } from './components/RolesTab'
import { apiClient } from '../../utils/apiClient'
import { DetailTab } from './components/DetailTab'
import React, { useCallback, useEffect } from 'react'
import { PermissionTab } from './components/PermissionTab'
import SubNav, { ISubNavItem } from '../../components/SubNav'
import { useMyAsyncPrompt } from '../../components/MyAsyncConfirm'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Module, WorkspacePageLayout } from '../../layout/PageLayout'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'

const subnavs: ISubNavItem[] = [
  {
    label: '详情',
    linkTo: 'detail',
  },
  {
    label: '权限',
    linkTo: 'permissions',
  },
  {
    label: '角色',
    linkTo: 'roles',
  },
]

/**
 * 在 UserDetail 范围内监听并响应基础信息编辑动作
 */
const InfoEditor: React.FC<{ user: User }> = ({ user }) => {
  const { isConfirmed } = useMyAsyncPrompt()
  const queryClient = useQueryClient()
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const mutation = useMutation(
    ({
      userId,
      displayName,
      email,
    }: {
      close: () => void
      userId: string | number
      displayName?: string
      email?: string
    }) => {
      if (!(workspaceId && userId)) return Promise.reject()
      const data = {
        userId,
        workspaceId,
        displayName,
        email,
      }
      return apiClient.updateUser(data)
    },
    {
      onSuccess(res, { userId, close }) {
        if (res.success) {
          queryClient.invalidateQueries(['user', `${userId}`])
          toast.success('修改成功')
          close()
        } else {
          toast.error(res.message || '系统异常')
        }
      },
      onError() {
        toast.error('系统异常')
      },
    }
  )

  const handleChangeName = useCallback(() => {
    if (!user) {
      return
    }
    isConfirmed({
      title: '编辑姓名',
      placeholder: '请输入姓名，最多32个字符',
      defaultValue: user.displayName,
      maxlength: 32,
      rules: [{ required: true, message: '请输入姓名', type: 'error' }],
      onOk(result, close) {
        if (!result) {
          return
        }
        mutation.mutate({
          userId: user.id,
          displayName: result,
          close,
        })
      },
    })
  }, [isConfirmed, user, mutation])

  const handleChangeEmail = useCallback(async () => {
    if (!user) {
      return
    }
    isConfirmed({
      title: '编辑 Email',
      placeholder: '请输入Email，最多32个字符',
      defaultValue: user.email,
      maxlength: 32,
      rules: [
        { required: true, message: '请输入 Email', type: 'error' },
        {
          validator: val => ({
            result: emailReg.test(val),
            message: '邮箱格式错误，请重新输入',
            type: 'error',
          }),
        },
      ],
      onOk(result, close) {
        if (!result) {
          return
        }
        mutation.mutate({
          userId: user.id,
          email: result,
          close,
        })
      },
    })
  }, [isConfirmed, user, mutation])

  useEffect(() => {
    const listener1 = () => handleChangeEmail()
    const listener2 = () => handleChangeName()
    eventBus.on(EventNames.ShowEmailEditor, listener1)
    eventBus.on(EventNames.ShowDisplayNameEditor, listener2)
    return () => {
      eventBus.off(EventNames.ShowEmailEditor, listener1)
      eventBus.off(EventNames.ShowDisplayNameEditor, listener2)
    }
  }, [handleChangeEmail, handleChangeName])
  return <></>
}

/**
 * 在 UserDetail 范围内监听并响应删除动作
 */
const DeleteAction: React.FC<{ user: User }> = ({ user }) => {
  const activeWorkspace = useAppSelector(selectActiveWorkspace)
  const queryClient = useQueryClient()
  const mutation = useMutation(
    ({ userId, workspaceId }: { workspaceId: string; userId: string | number; hide?: () => void }) => {
      return apiClient.removeMember({
        workspaceId,
        userId,
      })
    },
    {
      onSuccess: (_, { userId, hide }) => {
        hide?.()
        queryClient.invalidateQueries(['user', `${userId}`])
        toast.success('删除成功')
      },
      onError: () => {
        toast.error('删除出错了，请稍后再试')
      },
    }
  )
  const handleAction = useCallback(() => {
    if (!user || !activeWorkspace) return
    dialog({
      icon: <i className='iconfont icon-warning-fill text-warning' />,
      body: '确认要移除此用户吗',
      onConfirm({ hide }) {
        mutation.mutate({
          hide,
          userId: user.id,
          workspaceId: activeWorkspace,
        })
      },
    })
  }, [user, mutation, activeWorkspace])

  useEffect(() => {
    const listener1 = () => handleAction()
    eventBus.on(EventNames.DeleteUserAction, listener1)
    return () => {
      eventBus.off(EventNames.DeleteUserAction, listener1)
    }
  }, [handleAction])
  return <></>
}

/**
 * 下拉菜单唯一键
 */
enum MenuKeys {
  ChangeEmail = '1',
  ChangePassword = '2',
  DeleteMember = '4',
}

const UserDetailPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const [user, setUser] = useRecoilState(userAtom)
  const { pathname } = useLocation()
  const match = useRouteMatch()

  const { isLoading } = useQuery(
    ['user', `${userId}`],
    () => {
      if (!userId) {
        return Promise.reject()
      }
      return apiClient.readUser({ userId })
    },
    {
      onSuccess: resp => {
        if (resp.success && resp.data) {
          setUser(resp.data)
          return
        }
        toast.error('加载用户详情出错了')
      },
      onError: () => {
        toast.error('加载用户详情异常')
      },
    }
  )

  if (!userId) {
    return <>NO userId IN PARAMS</>
  }

  if (isLoading) {
    return <>Loading ...</>
  }

  const handleDropdown = (eventKey: unknown) => {
    if (eventKey === MenuKeys.ChangeEmail) {
      eventBus.emit(EventNames.ShowEmailEditor)
      return
    }
    if (eventKey === MenuKeys.DeleteMember) {
      eventBus.emit(EventNames.DeleteUserAction)
    }
  }

  return (
    <WorkspacePageLayout title='用户详情' hasBack to='../../users'>
      <Module>
        {user ? (
          <>
            <InfoEditor user={user} />
            <DeleteAction user={user} />

            <div className='d-flex flex-row justify-content-between my-4'>
              <div className='d-flex flex-row align-items-center'>
                {/* 用户信息卡片 */}
                <div>
                  <Avatar name={user.displayName} size='80' round='50%' />
                </div>
                <div className='px-3'>
                  <h2>{user.displayName}</h2>
                  <p className='text-muted'>
                    User ID <Tag>{userId}</Tag>
                  </p>
                </div>
              </div>
              <div>
                {/* <DropdownButton onSelect={handleDropdown} align='end' title='操作' id='dropdown-menu-align-end'>
                    <Dropdown.Item eventKey={MenuKeys.ChangeEmail}>修改邮箱地址</Dropdown.Item>
                  </DropdownButton> */}
              </div>
            </div>
          </>
        ) : null}

        <SubNav menuItems={subnavs} />

        <localDetailContext.Provider value={{ user }}>
          <Switch>
            <Route exact path={`${match.path}/detail`} render={() => <DetailTab />} />
            <Route exact path={`${match.path}/permissions`} render={() => <PermissionTab />} />
            <Route exact path={`${match.path}/roles`} render={() => <RolesTab />} />
            <Route exact path='' render={() => <Redirect to={`${pathname}/detail`} />} />
          </Switch>
        </localDetailContext.Provider>
      </Module>
    </WorkspacePageLayout>
  )
}

export default UserDetailPage
