import clsx from 'clsx'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { apiClient } from '../../utils/apiClient'
import { AuthedAccount, PlatformEnum } from '@dmp/api-sdk'
import { Table, TableCellParams, Button, dialog } from '@dmp/components'
import { platformEnum, authStatusEnum } from '../../constants/enums'
import { toast } from 'react-toastify'
import qs from 'query-string'
import { useExpand, usePlatform, useThrottledEffect } from '../../hooks'
import { useAppSelector } from '../../app/hooks'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { UTCToLocal } from '@dmp/utils'
import Filters, { FiltersType } from './Filters'
import { useRBAC } from '../../features/global/hooks'
import { WorkspacePageLayout, Module } from '../../layout/PageLayout'
import NewAuthModal, { NewAuthFormValuesType } from './NewAuthModal'
import { selectWorkspaceQuota } from '../../features/workspace/workspaceSlice'
import WsQuotaLine from '../../components/WsQuotaLine'
import StatusLabel from '../../components/StatusLabel'

export default () => {
  const history = useHistory()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const search = qs.parse(location.search)
  const { expand, expandIcon } = useExpand()
  const { platform, platformRadioGroup } = usePlatform()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const wsQuotaResult = useAppSelector(selectWorkspaceQuota)

  const [show, setShow] = React.useState(false)
  const [filters, setFilters] = React.useState<FiltersType>()
  const [editAuthInfo, setEditAuthInfo] = React.useState<NewAuthFormValuesType>()

  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const {
    isLoading,
    data: authAccountData,
    mutate,
  } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryAdvAuthAccountListPage({
      workspaceId,
      page: Number(page) - 1,
      size: Number(size),
      sort: 'createdAt,desc',
      advertPlatform: platform,
      ...filters,
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const authCallback = React.useCallback((url: URL) => {
    if (!url) return
    window.open(url, '_target', 'resizable,scrollbars,status')
  }, [])

  const handleEditAuth = React.useCallback(auth => {
    setEditAuthInfo(auth)
    setShow(true)
  }, [])

  const handleSearch = (values?: FiltersType) => {
    setFilters(values || {})
    onPaginationChange({ current: 1, pageSize: Number(size) })
  }

  const authMutation = useMutation((id: number) => apiClient.authByAuthId(id), {
    onSuccess: res => {
      if (!res.success) {
        toast.error(res.message)
        return
      }
      authCallback(res.data)
    },
    onError: err => {
      toast.error('系统异常')
    },
  })

  const handleRevoke = React.useCallback(
    (params: { id: number }) => {
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: '确认撤销授权？',
        async onConfirm({ hide }) {
          const res = await apiClient.revokeAdvAuthAccount(params)
          if (!res.success) {
            toast.error(res.message)
            return
          }
          toast.success('撤销成功')
          hide?.()
          mutate()
        },
      })
    },
    [mutate]
  )

  const columns = React.useMemo(
    () => [
      { title: '账号名称', colKey: 'accountName', width: 200, ellipsis: true },
      { title: '账号ID', colKey: 'advertPlatformAccountId', width: 150, ellipsis: true },
      {
        title: '广告平台',
        width: 150,
        colKey: 'advertPlatform',
        cell({ row }: TableCellParams<AuthedAccount>) {
          return platformEnum.map.get(row?.advertPlatform)?.label
        },
      },
      {
        title: '创建时间',
        colKey: 'createdAt',
        width: 200,
        cell({ row }: TableCellParams<AuthedAccount>) {
          return UTCToLocal(row?.createdAt)
        },
      },
      {
        title: '状态',
        width: 120,
        colKey: 'status',
        cell({ row }: TableCellParams<AuthedAccount>) {
          const obj = authStatusEnum.map.get(row.status)
          return <StatusLabel color={obj?.color} label={obj?.label} />
        },
      },
      {
        title: '操作',
        width: 200,
        colKey: 'action',
        fixed: 'right' as const,
        cell({ row }: TableCellParams<AuthedAccount>) {
          return (
            <>
              {hasPermission({ permission: 'operate:oauth' }) && ['INIT', 'INVALID', 'FAILED'].includes(row.status) && (
                <Button variant='text' theme='primary' className='me-2' onClick={() => authMutation.mutate(row.id)}>
                  授权
                </Button>
              )}
              {hasPermission({ permission: 'cancel:oauth' }) && ['SUCCESS'].includes(row.status) && (
                <Button variant='text' theme='danger' className='me-2' onClick={() => handleRevoke({ id: row.id })}>
                  撤销
                </Button>
              )}
              {hasPermission({ permission: 'update:oauth' }) && ['FAILED'].includes(row.status) && (
                <Button variant='text' theme='primary' onClick={() => handleEditAuth(row)}>
                  编辑
                </Button>
              )}
            </>
          )
        },
      },
    ],
    [handleEditAuth, authMutation, handleRevoke, hasPermission]
  )

  const handleHideModal = React.useCallback(() => {
    setShow(false)
    setEditAuthInfo(undefined)
  }, [])

  const canCreateAuth = hasPermission({ permission: 'create:oauth' })

  const headerTop = (
    <WsQuotaLine
      list={[
        { label: '广点通已有授权', value: wsQuotaResult?.OAUTH_SUCCESS_QUOTA_TENCENT?.useCount },
        { label: '广点通剩余授权', value: wsQuotaResult?.OAUTH_SUCCESS_QUOTA_TENCENT?.surplusCount },
        { label: '巨量引擎已有授权', value: wsQuotaResult?.OAUTH_SUCCESS_QUOTA_OCEANENGINE?.useCount },
        { label: '巨量引擎剩余授权', value: wsQuotaResult?.OAUTH_SUCCESS_QUOTA_OCEANENGINE?.surplusCount },
      ]}
    />
  )

  const headerRight = (
    <>
      {canCreateAuth && (
        <Button
          icon={<i className='iconfont icon-add-select' />}
          onClick={() => handleEditAuth({ advertPlatform: platform })}
        >
          新建授权
        </Button>
      )}
      <span className='ms-3'>{expandIcon}</span>
    </>
  )

  React.useEffect(() => {
    onPaginationChange({ current: 1, pageSize: Number(size) })
  }, [platform])

  React.useEffect(() => {
    window.addEventListener('message', e => {
      if (e.origin === window.origin && e.data === 'SUCCESS') {
        mutate()
      }
    })
    return () => {
      window.removeEventListener('message', () => {})
    }
  }, [mutate, workspaceId])

  useThrottledEffect(mutate, [page, size, platform, filters])

  return (
    <WorkspacePageLayout
      title='授权管理'
      headerRight={headerRight}
      headerChildren={platformRadioGroup}
      headerTop={headerTop}
    >
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          columns={columns}
          loading={isLoading}
          data={authAccountData?.items || []}
          empty='暂无已授权账号，请进行授权'
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: authAccountData?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
      <NewAuthModal show={show} onHide={handleHideModal} defaultValues={editAuthInfo} authCallback={authCallback} />
    </WorkspacePageLayout>
  )
}
