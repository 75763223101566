import React from 'react'
import { EventNames } from '../shared'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../../../recoil/user'
import { eventBus } from '../../../utils/eventBus'
import { Button, Form, FormItem } from '@dmp/components'

export const DetailTab: React.FC = () => {
  const user = useRecoilValue(userAtom)
  if (!user) {
    return <></>
  }

  return (
    <Form labelAlign='left' className='mt-4'>
      <FormItem label='显示名称'>
        <span className='me-2'>{user.displayName}</span>
        <Button variant='text' theme='primary' onClick={() => eventBus.emit(EventNames.ShowDisplayNameEditor)}>
          <i className='iconfont icon-editor' />
        </Button>
      </FormItem>
      <FormItem label='Email'>
        <span className='me-2'>{user.email}</span>
        <Button variant='text' theme='primary' onClick={() => eventBus.emit(EventNames.ShowEmailEditor)}>
          <i className='iconfont icon-editor' />
        </Button>
      </FormItem>
    </Form>
  )
}
