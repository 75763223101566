import React from 'react'
import PersonalSegmentPage from './PersonalSegmentPage'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
import PersonalSegmentDetailPage from '../../SegmentPage/SegmentDetailPage'
export default () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/read/:segmentId`} exact render={() => <PersonalSegmentDetailPage readonly />} />
      <Route exact render={() => <PersonalSegmentPage />} />
    </Switch>
  )
}
