import React from 'react'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import { useHistory, useParams } from 'react-router'
import CopyableText from '../../../components/CopyableText'
import { Form, FormItem, Input, Button, Alert } from '@dmp/components'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { platformEnum, rtaStrategyAttributeEnum, rtaStrategyStatusEnum } from '../../../constants/enums'
import { composeBidUrl } from '../../../utils'

export default () => {
  const history = useHistory()
  const { strategyId } = useParams<{ strategyId: string }>()
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const { data: strategyData } = useQuery(['queryStrategyListWthPage', strategyId, workspaceId], async () => {
    if (!(workspaceId && strategyId)) return Promise.reject()
    const res = await apiClient.queryStrategyListPage({
      page: 0,
      size: 10,
      sort: 'createdAt,desc',
      workspaceId,
      strategyId,
    })
    if (res.success) {
      const data = res.data?.items?.[0]
      if (data) {
        formRef.current?.setFieldsValue({
          name: data?.name,
          strategyId: data?.strategyId,
          advertRtaLaunchBind: data?.advertRtaLaunchBind?.name,
          segmentName: data?.segmentName,
          strategyProperty: rtaStrategyAttributeEnum.map.get(data?.strategyProperty)?.label,
          status: rtaStrategyStatusEnum.map.get(data?.status)?.label,
        })
      }
      return data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const handleDetailClick = React.useCallback(() => {
    if (!(workspaceId && strategyData?.segmentId)) return
    history.push(`/workspaces/${workspaceId}/strategies/segment/read/${strategyData.segmentId}`)
  }, [history, strategyData, workspaceId])

  return (
    <WorkspacePageLayout title='RTA策略详情' hasBack>
      <Module>
        <Form labelAlign='left' labelWidth={120} ref={formRef} disabled>
          <FormItem label='策略名称' name='name'>
            <Input />
          </FormItem>
          <FormItem label='RTA策略id' name='strategyId'>
            <CopyableText />
          </FormItem>
          <FormItem label='已绑定的投放平台' name='advertRtaLaunchBind'>
            <Input />
          </FormItem>
          <FormItem>
            <Alert
              style={{ width: '100%' }}
              icon={<i />}
              message={
                <>
                  <FormItem label='广告平台'>
                    {platformEnum.map.get(strategyData?.advertRtaLaunchBind?.advertPlatform)?.label}
                  </FormItem>
                  <FormItem label='bidUrl'>{composeBidUrl(strategyData?.advertRtaLaunchBind)}</FormItem>
                </>
              }
            />
          </FormItem>
          <FormItem label='圈定人群' name='segmentName'>
            <Input />
            <Button variant='text' theme='primary' className='ms-3 flex-shrink-0' onClick={handleDetailClick}>
              详情
            </Button>
          </FormItem>
          <FormItem label='策略属性' name='strategyProperty'>
            <Input />
          </FormItem>
          <FormItem label='状态' name='status'>
            <Input />
          </FormItem>
        </Form>
      </Module>
    </WorkspacePageLayout>
  )
}
