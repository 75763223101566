import 'rc-slider/assets/index.css'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import LogoutPage from './pages/LogoutPage'
import CallbackLayout from './pages/Callback'
import { SafeRoute } from './features/global'
import 'react-toastify/dist/ReactToastify.css'
import ErrorPage from './pages/ErrorPage/5xx'
import BaseLayout from './layout/BaseLayout'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

export default () => {
  return (
    <>
      <Router>
        <Switch>
          <SafeRoute exact path='/error' Component={ErrorPage} />
          <SafeRoute exact path='/session/new' Component={LoginPage} />
          <SafeRoute exact path='/signup/new' Component={SignupPage} />
          <SafeRoute exact path='/logout' Component={LogoutPage} />
          <SafeRoute path='/callback' Component={CallbackLayout} />
          <Route path='/' component={BaseLayout} />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  )
}
