import { Route, Switch, useRouteMatch } from 'react-router-dom'
import MainPage from './MainPage'
import SegmentDetailPage from '../SegmentPage/SegmentDetailPage'

export default () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/read/:segmentId`} exact render={() => <SegmentDetailPage readonly />} />
      <Route path='' render={() => <MainPage />} />
    </Switch>
  )
}
