import React from 'react'
import qs from 'query-string'
import { Radio } from '@dmp/components'
import { useExpand } from '../../../hooks'
import OrganizationPage from './Organization'
import WorkspacePage from './Workspace'
import { WorkspacePageLayout } from '../../../layout/PageLayout'
import { useHistory, useLocation } from 'react-router'
import { useRBAC } from '../../../features/global/hooks'

export enum TabsEnum {
  Organization = '1',
  Workspace = '2',
}

export const useRadioTabs = () => {
  const history = useHistory()
  const location = useLocation()
  const search = qs.parse(location.search)
  const { hasPermission } = useRBAC()

  const list = [
    {
      value: TabsEnum.Organization,
      label: '企业组织维度',
      visible: hasPermission({ permission: 'read:organ_quota' }),
      Component: OrganizationPage,
    },
    {
      value: TabsEnum.Workspace,
      label: '工作空间维度',
      visible: hasPermission({ permission: 'read:workspace_quota' }),
      Component: WorkspacePage,
    },
  ].filter(it => it.visible)
  const handleChange = React.useCallback(
    val => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: 1, dimension: val })}`)
    },
    [history, location.pathname, search]
  )

  const value = (search?.dimension ?? list[0].value) as TabsEnum

  return {
    value,
    Component: list.find(it => it.value === value)?.Component,
    radioTabs: (
      <Radio.Group value={value} onChange={handleChange}>
        {list.map(it =>
          it.visible ? (
            <Radio.Button value={it.value} key={it.value}>
              {it.label}
            </Radio.Button>
          ) : null
        )}
      </Radio.Group>
    ),
  }
}

export default () => {
  const { Component, radioTabs } = useRadioTabs()
  const { expand, expandIcon } = useExpand()
  return (
    <WorkspacePageLayout title='配额管理' headerRight={expandIcon} headerChildren={radioTabs}>
      {Component && <Component expand={expand} />}
    </WorkspacePageLayout>
  )
}
