import { atom } from 'recoil'
import { Strategy, CreateOrUpdateStrategyReg } from '@dmp/api-sdk'

/**  创建或更新RTA策略的表单信息 */
export const CreateOrUpdateStrategyValueAtom = atom<CreateOrUpdateStrategyReg | null>({
  key: 'CreateOrUpdateStrategyValueAtom',
  default: null,
})

/**  RTA策略详情 */
export const StrategyDetailValueAtom = atom<Strategy | null>({
  key: 'StrategyDetailValueAtom',
  default: null,
})
