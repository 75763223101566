import React from 'react'
import * as echarts from 'echarts'
import { EChartsOption } from 'echarts'

export interface EChartsPropTypes {
  /**
   * 高度
   * @default 300
   */
  height?: number
  options?: EChartsOption
}

const ECharts = (props: EChartsPropTypes) => {
  const { options, height } = props
  const chartRef = React.useRef(null)

  React.useEffect(() => {
    if (!(chartRef?.current && options)) return
    const myChart = echarts.init(chartRef?.current, undefined, {
      height,
    })
    myChart.setOption(options)

    window.addEventListener('resize', () => {
      myChart.resize()
    })
    return () => {
      myChart.dispose()
      window.removeEventListener('resize', () => {})
    }
  }, [height, options])

  return <div ref={chartRef} />
}

ECharts.defaultProps = {
  height: 300,
}

export default ECharts
