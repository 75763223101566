import React from 'react'
import { throttle, ThrottleSettings } from 'lodash'

export const useThrottledEffect = <T extends (...args: any) => any>(
  callback: T,
  deps: any[] = [],
  delay = 200,
  options: ThrottleSettings = { leading: false }
) => {
  const fnRef = React.useRef(callback)

  fnRef.current = callback

  const throttledFn = React.useMemo(
    () => throttle((args?: Parameters<T>): ReturnType<T> => fnRef.current(args), delay, options),
    []
  )

  React.useEffect(() => throttledFn(), [...deps])
}
