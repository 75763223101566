import React from 'react'
import { noop } from '@dmp/utils'
import { Dialog, Form, FormItem, Input, FormItemProps } from '@dmp/components'
interface MyAsyncPromptProps {
  title: string
  placeholder?: string
  show: boolean
  proceed: (val: string) => void
  cancel: () => void
  rules?: FormItemProps['rules']
  defaultValue?: string
  maxlength?: number
}

interface MyAsyncPromptContext {
  value: MyAsyncPromptProps
  setValue: React.Dispatch<React.SetStateAction<any>>
}

const context = React.createContext<MyAsyncPromptContext>({
  value: {
    title: '',
    show: false,
    proceed: noop,
    cancel: noop,
  },
  setValue: noop,
})

interface PromptParams {
  placeholder: string
  title?: string
  defaultValue?: string
  rules?: FormItemProps['rules']
  maxlength?: number
  onOk: (value: string, close: () => void) => void
}

export const useMyAsyncPrompt = () => {
  const { setValue } = React.useContext(context)

  const isConfirmed = React.useCallback(
    ({ title = '-', placeholder, defaultValue, rules, maxlength, onOk }: PromptParams) => {
      setValue({
        title,
        placeholder,
        show: true,
        rules,
        proceed: (val: string) => onOk(val, () => setValue({ show: false })),
        cancel: () => {
          setValue({ show: false })
        },
        defaultValue,
        maxlength,
        errorText: '',
        onOk,
      })
    },
    [setValue]
  )

  return {
    isConfirmed,
  }
}

export const MyAsyncPromptProvider: React.FC = ({ children }) => {
  const { value } = React.useContext(context)
  const [state, setValue] = React.useReducer((prev: any, next: any) => ({ ...prev, ...next }), { ...value })
  return (
    <context.Provider
      value={{
        value: state,
        setValue,
      }}
    >
      {children}
    </context.Provider>
  )
}

export const MyAsyncPrompt = () => {
  const {
    value: { show, title, placeholder, cancel, proceed, defaultValue, rules, maxlength },
  } = React.useContext(context)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const val = formRef.current?.getFieldValue('value')
      proceed(val)
    },
    [proceed]
  )

  return (
    <Dialog visible={show} onClose={cancel} header={title} onConfirm={() => formRef.current?.submit()}>
      <Form labelAlign='top' ref={formRef} onSubmit={onSubmit}>
        <FormItem label={placeholder} name='value' rules={rules} initialData={defaultValue}>
          <Input maxlength={maxlength} placeholder={placeholder} />
        </FormItem>
      </Form>
    </Dialog>
  )
}
