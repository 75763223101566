import React from 'react'
import leftTopBg from '../../assets/login/login-left-top-bg.png'
import rightBottomBg from '../../assets/login/login-right-bottom-bg.png'

export const MainContentWrapper: React.FC = ({ children }) => (
  <div className='container my-4'>
    <div className='px-4'>{children}</div>
  </div>
)

export const LoginWrapper: React.FC<{ closed?: boolean }> = ({ closed = false, children }) => {
  return (
    <div className='vh-100 vw-100 d-flex justify-content-center position-relative' style={{ background: '#f7f9fc' }}>
      {!closed && <img src={leftTopBg} className='position-absolute' style={{ top: 0, left: 0 }} />}
      <div className='align-self-center position-absolute' style={{ zIndex: 4 }}>
        {children}
      </div>
      {!closed && (
        <img src={rightBottomBg} className='position-absolute right-0 bottom-0' style={{ bottom: 0, right: 0 }} />
      )}
    </div>
  )
}
