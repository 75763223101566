export const appVersion = process.env.REACT_APP_VERSION || '0.0.0'
export const appName = process.env.REACT_APP_NAME || '睿查DMP'
export const appBuildTime = process.env.REACT_APP_BUILD_TIME || '?' // 构建时间
export const refetchInterval = 60000 // 统一轮询时长

export const apiBaseUrl = localStorage.getItem('API_ROOT') || process.env.REACT_APP_API_ROOT || `/api/v1`
// export const apiBaseUrl = 'http://dmp-ops-demo.dev.tzdata.site/api/v1/' // test
// export const apiBaseUrl = 'http://192.168.199.197:8333/'

export const isProduction = `${process.env.NODE_ENV}` === 'production'

// export const ApiEndpoints = {
//   // 人群预估 API
//   segmentEstimate: `${apiBaseUrl}/segment/estimate`,
//   // Deprecated: 兼容旧版人群数量预估 API
//   queryCrowd: `${apiBaseUrl}/api/crowd/query`,
// }

// Token 在 localStorage 存储的 name
export const accessTokenName = 'access_token'

// 默认登录页面
export const defaultAuthenticationPath = '/session/new'

/**
 * 标准请求缓存失效时间
 */
export const stdRequestStaleTimeInMs = 15 * 1000

/**
 * 默认日期格式
 */
export const dateFmt = 'yyyy-MM-dd'

/**
 * 默认日期时间格式
 */
export const dateTimeFmt = 'yyyy-MM-dd HH:mm'

/**
 * 以下这些地址，即便是发生了 401 错误，都不需要再跳到登陆页面了
 */
export const noAuthRequiredPathWhiteList = ['/session/new', '/logout']

/**  email 校验正则 采用async-validate 的type=email验证 */
export const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/

/**  url 校验正则 采用async-validate 的 type=url */
export const urlReg = new RegExp(
  '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
  'i'
)

export const colors = [
  '#5570C7',
  '#91CB74',
  '#FAC859',
  '#EE6666',
  '#73C1DF',
  '#3CA273',
  '#FC8453',
  '#9A60B4',
  '#41C8BA',
  '#B3BE55',
  '#768ACB',
  '#FF7D7D',
  '#FF7331',
  '#4499F5',
  '#7AE2F8',
  '#FFA353',
  '#F53F01',
]
