import './index.scss'
import React, { useContext, forwardRef, ForwardRefRenderFunction, useReducer, createContext } from 'react'
import { TransferPropsType, ContextType, TransferStateType } from './interface'

const initialState = {
  checkedAll: false,
}

const Context = createContext<ContextType>({
  ...initialState,
  options: [],
  setContext: () => {},
})

const Container: React.FC<{ header: React.ReactNode }> = ({ children, header }) => {
  return (
    <div className='border' style={{ width: 160 }}>
      <div className='border-bottom bg-light p-2'>{header}</div>
      <div className='text-muted' style={{ height: 200, overflowY: 'auto' }}>
        {children}
      </div>
    </div>
  )
}

const Selected = () => {
  const { options, onChange, value = [], setContext } = useContext(Context)

  const handleDeleteItem = (val: string | number) => {
    onChange?.(value.filter(v => v !== val))
  }

  const handleClearAll = () => {
    setContext({ checkedAll: false })
    onChange?.([])
  }

  return (
    <Container
      header={
        <div className='d-flex justify-content-between'>
          <span>已选</span>
          <span className='text-primary text-end' role='button' onClick={handleClearAll}>
            清空
          </span>
        </div>
      }
    >
      {options?.map(item => {
        const checked = value?.includes(item.value)
        return checked ? (
          <div
            className={'member-item-wrapper d-flex justify-content-between px-2 py-1 my-1 rounded'}
            key={item.value}
            title={item.label}
          >
            <span className='text-truncate' title={item.label}>
              {item.label}
            </span>
            <i className='dmp-transfer__remove-icon iconfont icon-close' onClick={() => handleDeleteItem(item.value)} />
          </div>
        ) : null
      })}
    </Container>
  )
}

const Choice: React.FC<TransferPropsType> = ({ title, renderOption }) => {
  const { options, value, onChange, checkedAll, setContext } = useContext(Context)
  const handleTriggerSelectItem = (checked: boolean, val: string | number) => {
    let newValue = [...(value || [])]
    newValue = checked ? [...newValue, val] : newValue.filter(v => v !== val)
    onChange?.(newValue)
  }

  const handleTriggerCheckedAll = () => {
    const checked = !checkedAll
    const newValue = checked ? options.map(item => item.value) : []
    onChange?.(newValue)
    setContext({ checkedAll: checked })
  }

  const render = () => {
    return (
      <>
        {options.map(item => {
          const checked = value?.includes(item.value)
          return checked ? null : (
            <div
              key={item.value}
              className={'member-item-wrapper d-flex align-item-center px-2 py-1 my-1 rounded'}
              title={item.label}
              onClick={() => handleTriggerSelectItem(!checked, item.value)}
            >
              {renderOption ? renderOption(item) : <span className='text-truncate'>{item.label}</span>}
            </div>
          )
        })}
      </>
    )
  }

  const renderEmpty = () => {
    return <div className='my-5'>暂无数据...</div>
  }

  return (
    <Container
      header={
        <>
          <div className='d-flex justify-content-between'>
            <span>{title}</span>
            <span className='text-primary text-end' role='button' onClick={handleTriggerCheckedAll}>
              {checkedAll ? '反选' : '全选'}
            </span>
          </div>
        </>
      }
    >
      {options?.length ? render() : renderEmpty()}
    </Container>
  )
}

const Transfer: ForwardRefRenderFunction<HTMLDivElement, TransferPropsType> = (props, ref) => {
  const [state, setState] = useReducer((prev: TransferStateType, next: TransferStateType) => ({ ...prev, ...next }), {
    ...initialState,
  })

  return (
    <Context.Provider value={{ ...state, ...props, setContext: setState }}>
      <div className='d-flex' ref={ref}>
        <Choice {...props} />
        <div className='mx-2' />
        <Selected />
      </div>
    </Context.Provider>
  )
}

export default forwardRef(Transfer)
