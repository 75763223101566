import React from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { SegmentList } from './SegmentList'
import { SegmentPageCreate } from './SegmentPageCreate'
import SegmentDetailPage from './SegmentDetailPage'
import InsightSegment from './components/InsightSegment'

export default () => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const match = useRouteMatch()

  if (workspaceId) {
    return (
      <>
        <Switch>
          <Route path={`${match.path}/create`} exact render={() => <SegmentPageCreate />} />
          <Route path={`${match.path}/list`} render={() => <SegmentList />} />
          <Route path={`${match.path}/read/:segmentId`} render={() => <SegmentDetailPage />} />
          <Route path={`${match.path}/insight/:segmentId`} render={() => <InsightSegment />} />
          <Route path={`${match.path}`} render={() => <Redirect to={`${match.url}/list`} />} />
        </Switch>
      </>
    )
  }

  return (
    <>
      <div className='layout-content'>
        <p className='text-danger'>
          路由错误，请在路径中提供 <code>workspaceId</code> 参数。
        </p>
      </div>
    </>
  )
}
