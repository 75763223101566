import { RegionResource } from '../models'

// 行政区划数据来源：
// http://www.mca.gov.cn/article/sj/xzqh/1980/2019/202002281436.html
export const regions: RegionResource[] = [
  { id: '110000', name: '北京市', level: 1 },
  { id: '120000', name: '天津市', level: 1 },
  { id: '310000', name: '上海市', level: 1 },
  { id: '500000', name: '重庆市', level: 1 },

  { id: '130000', name: '河北省', level: 1 },
  { id: '130100', name: '石家庄市', pid: '130000', level: 2 },
  { id: '130200', name: '唐山市', pid: '130000', level: 2 },
  { id: '130300', name: '秦皇岛市', pid: '130000', level: 2 },
  { id: '130400', name: '邯郸市', pid: '130000', level: 2 },
  { id: '130500', name: '邢台市', pid: '130000', level: 2 },
  { id: '130600', name: '保定市', pid: '130000', level: 2 },
  { id: '130700', name: '张家口市', pid: '130000', level: 2 },
  { id: '130800', name: '承德市', pid: '130000', level: 2 },
  { id: '130900', name: '沧州市', pid: '130000', level: 2 },
  { id: '131000', name: '廊坊市', pid: '130000', level: 2 },
  { id: '131100', name: '衡水市', pid: '130000', level: 2 },

  { id: '140000', name: '山西省', level: 1 },
  { id: '140100', name: '太原市', pid: '140000', level: 2 },
  { id: '140200', name: '大同市', pid: '140000', level: 2 },
  { id: '140300', name: '阳泉市', pid: '140000', level: 2 },
  { id: '140400', name: '长治市', pid: '140000', level: 2 },
  { id: '140500', name: '晋城市', pid: '140000', level: 2 },
  { id: '140600', name: '朔州市', pid: '140000', level: 2 },
  { id: '140700', name: '晋中市', pid: '140000', level: 2 },
  { id: '140800', name: '运城市', pid: '140000', level: 2 },
  { id: '140900', name: '忻州市', pid: '140000', level: 2 },
  { id: '141000', name: '临汾市', pid: '140000', level: 2 },
  { id: '141100', name: '吕梁市', pid: '140000', level: 2 },

  { id: '320000', name: '江苏省', level: 1 },
  { id: '320100', name: '南京市', pid: '320000', level: 2 },
  { id: '320200', name: '无锡市', pid: '320000', level: 2 },
  { id: '320300', name: '徐州市', pid: '320000', level: 2 },
  { id: '320400', name: '常州市', pid: '320000', level: 2 },
  { id: '320500', name: '苏州市', pid: '320000', level: 2 },
  { id: '320600', name: '南通市', pid: '320000', level: 2 },
  { id: '320700', name: '连云港市', pid: '320000', level: 2 },
  { id: '320800', name: '淮安市', pid: '320000', level: 2 },
  { id: '320900', name: '盐城市', pid: '320000', level: 2 },
  { id: '321000', name: '扬州市', pid: '320000', level: 2 },
  { id: '321100', name: '镇江市', pid: '320000', level: 2 },
  { id: '321200', name: '泰州市', pid: '320000', level: 2 },
  { id: '321300', name: '宿迁市', pid: '320000', level: 2 },

  { id: '710000', name: '台湾省', level: 1 },
  { id: '810000', name: '香港特别行政区', level: 1 },
  { id: '820000', name: '澳门特别行政区', level: 1 },
]
