import { createAsyncThunk } from '@reduxjs/toolkit'
import { ExpressionNode } from '@rsql/ast'
import builder from '@rsql/builder'
// eslint-disable-next-line import/no-cycle
import { AppDispatch, RootState } from '../../app/store'
import { PageEnvelope, SegmentResource } from '@dmp/api-sdk'
import { apiClient } from '../../utils/apiClient'

interface ReadSegmentParams {
  id: string
}

type ReadSegmentResult =
  | {
      success: true
      segment: SegmentResource
    }
  | {
      success: false
    }

// 加载单个人群包
export const readSegment = createAsyncThunk<
  ReadSegmentResult,
  ReadSegmentParams,
  {
    rejectValue: {
      success: boolean
      message?: string
    }
  }
>('segment/read', async ({ id }) => {
  const result = await apiClient.readSegment(id)

  if (result.success && result.data) {
    return {
      success: true,
      segment: result.data,
    }
  }

  return {
    success: false,
  }
})

export interface LoadSegmentSearch {
  callStatus?: string
  segmentName?: string
  segmentType?: string
  segmentStatus?: string
  createdAtFrom?: string // yyyy-MM-dd HH:mm
  createdAtTo?: string // yyyy-MM-dd HH:mm
}

// TODO 加载人群列表
export const loadSegments = createAsyncThunk<
  PageEnvelope<SegmentResource> | undefined,
  {
    workspaceId: string
    page?: number
    size?: number
    sorts?: string[]
    search?: LoadSegmentSearch
  }
>('segment/loadSegments', async ({ workspaceId, search, page = 0, size = 10, sorts = [] }) => {
  try {
    let searchCriteria: ExpressionNode | undefined = undefined

    if (search) {
      const parts: ExpressionNode[] = []
      if (search.segmentName) {
        parts.push(builder.eq('name', `*${search.segmentName}*`))
      }
      // 将 VO 中的类型字段转换为 PO 中对应的值
      if (search.segmentType) {
        parts.push(builder.eq('type', search.segmentType))
      }
      if (search.segmentStatus) {
        parts.push(builder.eq('status', search.segmentStatus))
      }
      if (search.callStatus) {
        parts.push(builder.eq('callStatus', search.callStatus))
      }
      if (search.createdAtFrom && search.createdAtTo) {
        parts.push(
          builder.logic(
            [builder.ge('createdAt', search.createdAtFrom), builder.le('createdAt', search.createdAtTo)],
            'and'
          )
        )
      } else if (search.createdAtFrom) {
        parts.push(builder.ge('createdAt', search.createdAtFrom))
      } else if (search.createdAtTo) {
        parts.push(builder.le('createdAt', search.createdAtTo))
      }
      searchCriteria = parts.length ? builder.and(...parts) : undefined
    }
    const data = await apiClient.listSegment({
      workspaceId,
      sorts,
      page: page - 1,
      size,
      search: searchCriteria,
    })
    if (data.success) {
      return data.data
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e)
  }
  return undefined
})
