import React from 'react'
import qs from 'query-string'
import { Radio } from '@dmp/components'
import { useExpand } from '../../hooks'
import AdvertiserPage from './Advertiser'
import AdGroupPage from './AdGroup'
import CrowdPackPage from './CrowdPack'
import { useHistory, useLocation } from 'react-router'
import { BasePageLayout } from '../../layout/PageLayout'

export enum TabsEnum {
  Advertiser = '1',
  AdGroup = '2',
  CrowdPack = '3',
}

export const useRadioTabs = () => {
  const history = useHistory()
  const location = useLocation()
  const search = qs.parse(location.search)

  const list = [
    { value: TabsEnum.Advertiser, label: '广告主维度', Component: AdvertiserPage },
    { value: TabsEnum.AdGroup, label: '广告组维度', Component: AdGroupPage },
    { value: TabsEnum.CrowdPack, label: '人群包维度', Component: CrowdPackPage },
  ]

  const handleChange = React.useCallback(
    val => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: 1, dimension: val })}`)
    },
    [history, location.pathname, search]
  )

  const value = (search?.dimension ?? list[0].value) as TabsEnum

  return {
    value,
    Component: list.find(it => it.value === value)?.Component,
    radioTabs: (
      <Radio.Group value={value} onChange={handleChange}>
        {list.map(it => (
          <Radio.Button value={it.value} key={it.value}>
            {it.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    ),
  }
}

export default () => {
  const { radioTabs, Component } = useRadioTabs()
  const { expand, expandIcon } = useExpand()
  return (
    <BasePageLayout title='投放明细' headerRight={expandIcon} headerChildren={radioTabs}>
      {Component && <Component expand={expand} />}
    </BasePageLayout>
  )
}
