import './index.scss'
import clsx from 'clsx'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Layout } from '@dmp/components'
import { appName } from '../../constants'
import { useHistory } from 'react-router-dom'

interface PropsTypes {
  title: string
  hasBack?: boolean
  to?: string
  className?: string
  remark?: React.ReactNode
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  headerChildren?: React.ReactNode
  headerTop?: React.ReactNode
}

export const BasePageLayout: React.FC<PropsTypes> = props => {
  const { title, headerLeft, headerRight, className, remark, children, headerChildren } = props
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title} - {appName}
        </title>
        <meta name='description' content={title} />
      </Helmet>
      <Layout className={clsx('base-page__layout container', className)}>
        <Layout.Header className='my-2 d-flex justify-content-between align-items-center'>
          {headerLeft || (
            <div>
              <span className='base-page__title'>{title}</span>
              <small className='text-muted ps-3'>{remark}</small>
            </div>
          )}
          <div>{headerRight}</div>
        </Layout.Header>
        <Layout.Content>
          {headerChildren && <div className='pb-4'>{headerChildren}</div>}
          {children}
        </Layout.Content>
      </Layout>
    </React.Fragment>
  )
}

export const Module: React.FC<{
  title?: React.ReactNode
  maxHeight?: number
  maxWidth?: number
  className?: string
  hasGap?: boolean
  icon?: React.ReactNode
}> = props => {
  const { title, icon = <i className='workspace-module-title-icon' />, maxHeight, maxWidth, hasGap, className } = props
  return (
    <div className={clsx('workspace-module__layout', className)}>
      <div className={clsx('workspace-module')} style={{ maxHeight, maxWidth }}>
        {title && (
          <header className={clsx('workspace-module-title')}>
            {icon}
            {title}
          </header>
        )}
        {props.children}
      </div>
      {hasGap && <div className='workspace-module-gap' />}
    </div>
  )
}

export const WorkspacePageLayout: React.FC<PropsTypes> = props => {
  const { title, headerTop, headerLeft, hasBack, to, headerChildren, headerRight, className, remark, children } = props

  const history = useHistory()

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {title} - {appName}
        </title>
        <meta name='description' content={title} />
      </Helmet>
      <Layout className={clsx('workspace-page__layout', className)}>
        <div
          className={clsx('workspace-page__layout__top', {
            'd-none': !headerTop,
          })}
        >
          {headerTop}
        </div>
        <Layout.Header className='workspace__page__header d-flex justify-content-between align-items-center'>
          <div>
            {hasBack && (
              <span className='workspace-page__back' onClick={() => (to ? history.push(to) : history.goBack())}>
                <i className='arrow left me-2' />
                返回
              </span>
            )}
            <span className='workspace-page__title'>{headerLeft || title}</span>
            {remark && <small className='text-muted ps-3'>{remark}</small>}
          </div>
          <div>{headerRight}</div>
        </Layout.Header>
        <Layout.Content>
          {headerChildren && <div className='workspace-page__header__children pb-4'>{headerChildren}</div>}
          {children}
        </Layout.Content>
      </Layout>
    </React.Fragment>
  )
}
