import ErrorImage from '../../assets/error.png'

export default () => {
  return (
    <div className='vh-100 d-flex align-items-center justify-content-center'>
      <div className='text-center'>
        <img src={ErrorImage} width={656} height={403} alt='' />
        <p className='text-muted mt-4'>请耐心等候，工程师正在紧急修复中!</p>
      </div>
    </div>
  )
}
