import clsx from 'clsx'
import React from 'react'

export const useExpand = (initialValue = true) => {
  const [expand, setExpand] = React.useState(initialValue)
  return {
    expand,
    expandIcon: (
      <i
        className={clsx('align-middle fs-5 iconfont', `icon-${expand ? 'expand' : 'fold'}`)}
        onClick={() => setExpand(!expand)}
      />
    ),
  }
}
