import React from 'react'
import { localToUTC } from '@dmp/utils'
import { authStatusEnum } from '../../constants/enums'
import { Button, Form, FormItem, Input, DateRangePicker, Select } from '@dmp/components'

export interface FiltersType {
  advertPlatformAccountId?: string // 平台账号id
  accountName?: string // 账号名称
  advertPlatform?: string // 所属广告平台
  status?: string // 授权状态
  startDate?: string // 创建时间
  endDate?: string // 创建时间
}

export default ({ onSearch }: { onSearch: (values?: FiltersType) => void }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const handleSubmit = React.useCallback(
    ctx => {
      const values = formRef.current?.getFieldsValue(true) ?? {}
      onSearch({
        ...values,
        dateRange: undefined,
        startDate: localToUTC(values?.dateRange?.[0]),
        endDate: localToUTC(values?.dateRange?.[1]),
      })
    },
    [onSearch]
  )

  const handleReset = React.useCallback(
    ctx => {
      formRef.current?.reset()
      onSearch()
    },
    [onSearch]
  )

  const width = 260

  return (
    <div className='d-flex justify-content-between'>
      <Form ref={formRef} className='d-flex align-items-center flex-wrap' labelAlign='top' onSubmit={handleSubmit}>
        <FormItem label='账号名称' name='accountName' className='mb-2 me-2'>
          <Input placeholder='请输入账号名称，最多32个字符' maxlength={32} style={{ width }} />
        </FormItem>
        <FormItem label='广告平台账号ID' name='advertPlatformAccountId' className='mb-2 me-2'>
          <Input style={{ width }} placeholder='请输入广告平台账号ID，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='状态' name='status' className='mb-2 me-2'>
          <Select style={{ width }} options={authStatusEnum.list} placeholder='不限' />
        </FormItem>
        <FormItem label='创建时间' name='dateRange' className='mb-2 me-2'>
          <DateRangePicker style={{ width }} />
        </FormItem>
      </Form>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button onClick={() => formRef.current?.submit()}>搜索</Button>
        <Button className='ms-2' variant='outline' onClick={handleReset}>
          重置
        </Button>
      </div>
    </div>
  )
}
