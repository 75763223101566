import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { apiClient } from '../../utils/apiClient'
import { IFormError } from './types'

export type CreateSegmentParams =
  | {
      type: 'label'
      name: string
      description: string
      startDate: string
      endDate: string
      targeting: Array<{ name: string; params: string[] }>
      estimatedVelocity: number // 预估的人群规模， 2021-12-22 增加
    }
  | {
      type: 'lookalike'
      name: string
      description: string
      seedSegmentId: number
      upScaleTarget: number
    }

const doCreateNormalSegment = async (
  params: CreateSegmentParams,
  workspaceId: string
): Promise<
  | {
      success: true
      id: number
    }
  | {
      success: false
      code: number
      message?: string
      errors: IFormError[]
    }
> => {
  if (params.type !== 'label') {
    throw new Error(`only normal can reach here: ${params.type}`)
  }

  const { name, description, startDate, endDate, targeting, estimatedVelocity } = params
  let errors: IFormError[] = []

  if (targeting.length === 0) {
    errors = [...errors, { field: 'targeting', message: '没有选择任何标签' }]
  }

  if (errors.length > 0) {
    return { success: false, code: -1, errors }
  }

  const result = await apiClient.createSegment({
    workspaceId,
    name,
    description,
    startDate,
    endDate,
    targeting: targeting.map(({ name: tagName, params: tagValues }) => ({ tagName, tagValues })),
    estimatedVelocity,
  })

  if (result.success) {
    const { id } = result.data || { id: 0 }
    return {
      success: true,
      id,
    }
  }

  // API 抛出 SQL 异常：
  // duplicate key value violates unique constraint \"dmp_seg_segment_name_key\"\n  Detail: Key (name)=(3)
  if (result.message && result.message.indexOf('violates unique constraint') && result.message.indexOf('(name)')) {
    result.message = '名称已被占用，请修改后重试'
  }

  return {
    success: false,
    code: result.code,
    message: result.message || 'ERROR',
    errors,
  }
}

const doCreateLookalikeSegment = async (
  params: CreateSegmentParams,
  workspaceId: string
): Promise<
  | {
      success: true
      id: number
    }
  | {
      success: false
      code: number
      message?: string
      errors: IFormError[]
    }
> => {
  if (params.type !== 'lookalike') {
    throw new Error(`only lookalike can reach here: ${params.type}`)
  }

  console.log('doCreateLookalikeSegment -> ', params)

  const { name, description, seedSegmentId, upScaleTarget } = params

  const result = await apiClient.expandSegment({
    workspaceId,
    name,
    description,
    seedSegmentId,
    upScaleTarget,
  })

  console.log('result=', result)

  if (result.success) {
    return {
      success: true,
      id: result.segmentId,
    }
  }
  return { success: false, code: -1, errors: [], message: result?.message || 'FAIL' }
}

type CreateSegmentResult =
  | {
      success: true
      id: number // 新建的 segment ID
    }
  | {
      success: false
      message?: string
    }

// 新建人群 (new)
export const createSegment = createAsyncThunk<
  CreateSegmentResult,
  CreateSegmentParams,
  {
    state: RootState
    rejectValue: {
      message?: string
      errors: IFormError[]
    }
  }
>('segment/create', async (params, { rejectWithValue, ...rest }) => {
  // FIXME: 有点丑，没有别的优雅的办法了么？
  const workspaceId = rest.getState().workspace?.workspace?.id || ''

  // 正常创建流程
  if (params.type === 'label') {
    const ret = await doCreateNormalSegment(params, workspaceId)

    if (!ret.success) {
      return rejectWithValue(ret)
    }

    return {
      success: true,
      id: ret.id,
    }
  }

  // 放大人群新建
  if (params.type === 'lookalike') {
    const ret = await doCreateLookalikeSegment(params, workspaceId)
    if (!ret.success) {
      return rejectWithValue(ret)
    }

    return {
      success: true,
      id: ret.id,
    }
  }

  // SHOULD NOT REACHED HERE AT ANY CHANCE
  return {
    success: false,
  }
})
