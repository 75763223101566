import React, { useState } from 'react'
import { Popup } from '@dmp/components'

interface PropsTypes {
  /** @default 100% */
  maxWidth?: number | string
  text: string
  className?: string
}

export default (props: PropsTypes) => {
  const [visible, setVisible] = useState(false)
  const { maxWidth = '100%', text, className = '' } = props
  const handleTooltipIn = (e: { currentTarget: { scrollWidth: number; offsetWidth: number } }) => {
    // scrollWidth: 对象的实际内容的宽度，不包含边线宽度，会随对象中内容超过可视区而变大
    // offsetWidth: 对象整体的实际宽度，包含滚动条等边线，会随对象显示大小的变化而改变
    setVisible(e.currentTarget.scrollWidth > e.currentTarget.offsetWidth)
  }
  const handleTooltipOut = () => {
    setVisible(false)
  }
  return (
    <Popup visible={visible} placement='bottom' content={text}>
      <div
        className={`d-inline-block text-truncate ${className}`}
        style={{ maxWidth: maxWidth }}
        onMouseEnter={handleTooltipIn}
        onMouseLeave={handleTooltipOut}
      >
        {text}
      </div>
    </Popup>
  )
}
