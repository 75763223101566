/* eslint-disable */
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../../app/store'
import { SegmentCallStatusType, SegmentResource } from '@dmp/api-sdk'
import { RegionResource } from '../../shared/models'
import { createSegment } from './create'
import { loadRegions } from './region'
import { loadSegments, readSegment } from './read'
import { deleteSegment } from './delete'

const regionsAdapter = createEntityAdapter<RegionResource>({
  selectId: region => region.id,
  sortComparer: (a, b) => `${a.id}`.localeCompare(`${b.id}`),
})

const segmentsAdapter = createEntityAdapter<SegmentResource>({
  selectId: entity => entity.id!,
  sortComparer: (a, b) => (a.id || 0) - (b.id || 0),
})

interface SegmentCreationSketch {
  // 预估数量（仅对普通人群预估有效）
  estimateVelocity: number

  entity: Omit<SegmentResource, 'id'> | {}
}

export interface SegmentState {
  touched: number
  list: SegmentResource[] // FIXME: 似乎没有用了
  status: 'loading' | 'idle'

  // 人群列表 分页信息
  segmentsTotal: number
  segmentPageIds: Array<number>

  segments: EntityState<SegmentResource> // normalized
  regions: EntityState<RegionResource> // normalized
  activeSegmentId: string | number | undefined // 当前查看详情的 segment

  // 新建表单错误
  errorsOnCreation: { [key: string]: string[] } // key: field, value: messages

  // 新建草稿
  creationSketch: SegmentCreationSketch
}

const initialState: SegmentState = {
  touched: 0,
  list: [],
  status: 'idle',
  segmentsTotal: 0,
  segmentPageIds: [],
  segments: segmentsAdapter.getInitialState(),
  regions: regionsAdapter.getInitialState(),
  activeSegmentId: undefined,

  errorsOnCreation: {},
  creationSketch: {
    estimateVelocity: 0,
    entity: {
      name: '',
      description: '',
      status: 'UNKNOWN',
      source: 'UNKNOWN',
      velocity: 0,
      type: 'NONE',
      createdAt: '',
      updatedAt: '',
      callStatus: SegmentCallStatusType.Unavailable,
    },
  },
}

export const segmentSlice = createSlice({
  name: 'segment',
  initialState,

  reducers: {
    increment: state => {
      state.touched += 1
    },
    decrement: state => {
      state.touched -= 1
    },
    incrementBy: (state, action: PayloadAction<{ value: number }>) => {},

    unloadRegions: state => {
      // reset
      state.regions = { ids: [], entities: {} }
    },

    // 开始一个默认人群创建的初始化
    // FIXME: 没有实际作用，可以移除？
    startLabelSegmentCreation: state => {
      state.creationSketch = {
        estimateVelocity: 0,
        entity: {
          ...state.creationSketch.entity,
          type: 'LABEL',
          // spec: {
          //   targeting: [],
          // },
        },
      }
    },

    // 开始一个拓展人群创建的初始化
    // FIXME: 没有实际作用，可以移除？
    startLookalikeCreation: state => {
      state.creationSketch = {
        estimateVelocity: 0,
        entity: {
          ...state.creationSketch.entity,
          type: 'LOOKALIKE',
          // spec: {
          //   seedSegmentId: 0,
          //   upScaleTarget: 0,
          // },
        },
      }
    },

    // FIXME: 没有实际作用，可以移除？
    updateCreationSketch: (
      state,
      action: PayloadAction<{ op: 'replace' | 'merge'; data: Partial<SegmentResource> }>
    ) => {
      const { op, data } = action.payload
      if (op === 'merge') {
        // if (data.targeting && data.targeting.length > 0) {
        //   // FIXME: 这里好像不对
        //   state.creationSketch.entity.targeting = [...state.creationSketch.entity.targeting, ...data.targeting]
        // }
      }
      if (op === 'replace') {
        // FIXME: 这里也不对了
        state.creationSketch = {
          ...state.creationSketch,
          entity: {
            ...state.creationSketch.entity,
            ...data,
          },
        }
      }
    },

    resetCreation: state => {
      state.errorsOnCreation = {}
    },
  },

  extraReducers: builder => {
    builder
      .addCase(createSegment.rejected, (state, action) => {
        if (state.status === 'loading') {
          state.status = 'idle'
        }
        console.log('redux createSegment.rejected, action = ', action)
        const { payload } = action

        if (!payload) {
          return
        }

        if (payload.errors) {
          // merge errors
          const newErrors = payload?.errors.reduce((prev, cur) => {
            let messages = prev[cur.field] || []
            messages = [...messages, cur.message]
            prev[cur.field] = messages
            return prev
          }, {} as { [key: string]: string[] })
          state.errorsOnCreation = newErrors
        }
      })
      .addCase(createSegment.pending, state => {
        state.status = 'loading'
      })
      .addCase(createSegment.fulfilled, (state, action) => {
        if (state.status === 'loading') {
          state.status = 'idle'
        }
      })
      .addCase(loadRegions.pending, state => {})
      .addCase(loadRegions.fulfilled, (state, action) => {
        regionsAdapter.setMany(state.regions, action.payload)
      })
      .addCase(loadSegments.pending, state => {
        state.status = 'loading'
      })
      .addCase(loadSegments.rejected, state => {
        state.status = 'idle'
      })
      .addCase(loadSegments.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }
        const { items, pages, total } = action.payload
        if (items) {
          segmentsAdapter.setMany(state.segments, items)
          state.segmentPageIds = items.map(it => it.id!)
        }
        state.segmentsTotal = total || 0
        state.status = 'idle'
      })

    builder
      .addCase(readSegment.pending, state => {
        state.status = 'loading'
        state.activeSegmentId = undefined
      })
      .addCase(readSegment.fulfilled, (state, action) => {
        state.status = 'idle'
        if (action.payload.success) {
          const { segment } = action.payload
          segmentsAdapter.addOne(state.segments, segment)
          if (segment.id) {
            state.activeSegmentId = segment.id
          }
        }
      })
      .addCase(readSegment.rejected, state => {
        state.status = 'idle'
      })

    builder
      .addCase(deleteSegment.pending, state => {
        state.status = 'loading'
      })
      .addCase(deleteSegment.fulfilled, (state, action) => {
        state.status = 'idle'
        // if (action.payload.success) {
        //   const { segment } = action.payload
        //   segmentsAdapter.addOne(state.segments, segment)
        //   if (segment.id) {
        //     state.activeSegmentId = segment.id
        //   }
        // }
      })
      .addCase(deleteSegment.rejected, state => {
        state.status = 'idle'
      })
  },
})

// actions
export const {
  increment,
  decrement,
  unloadRegions,
  updateCreationSketch,
  startLabelSegmentCreation,
  startLookalikeCreation,
  resetCreation,
} = segmentSlice.actions

const selectors = {
  selectStatus: (state: RootState) => state.segment.status,
  selectRegions: (state: RootState) => state.segment.regions,

  // TODO: 考虑权限，过滤无权限访问的
  selectSegments: (state: RootState) => {
    const { segments } = state.segment
    const { selectById } = segmentsAdapter.getSelectors()
    return segments.ids.map(id => selectById(segments, id)).filter(x => !!x) as SegmentResource[]
  },

  // 顺序正确的分页结果
  selectPage: (state: RootState) => {
    const { segmentPageIds, segments } = state.segment
    const { selectById } = segmentsAdapter.getSelectors()
    const result = segmentPageIds.map(id => selectById(segments, id)).filter(x => !!x) as SegmentResource[]
    return result
  },

  selectActiveSegment: (state: RootState) => {
    if (!state.segment.activeSegmentId) {
      return null
    }
    return segmentsAdapter.getSelectors().selectById(state.segment.segments, state.segment.activeSegmentId)
  },

  selectErrorsOnCreation: (state: RootState) => {
    return state.segment.errorsOnCreation
  },

  // 当前分页的ids
  selectSegmentPageIds: (state: RootState) => state.segment.segmentPageIds,
}

// selectors
export const { selectStatus, selectRegions, selectSegments, selectErrorsOnCreation, selectActiveSegment, selectPage } =
  selectors

// reducer
export default segmentSlice.reducer

// ---------------------------------------------------------------------
// 以下是非正式实验性质的内容
// ---------------------------------------------------------------------

export const submitSegmentCreation = (): AppThunk<Promise<string>> => async (dispatch, getState, extra) => {
  return ''
}

// // 手写同步 thunk , 可以有第三个 extra 参数
// export const fooAction = (): AppThunk<string> => (dispatch, getState, extra) => {
//   console.log(extra)
//   dispatch({
//     type: 'fooAction',
//     payload: { extra },
//   })
//   return 'yes!'
// }

// export const fooActionAsync = (): AppThunk<Promise<string>> => async (dispatch, getState, extra) => {
//   console.log(extra)
//   dispatch({
//     type: 'fooAction',
//     payload: { extra },
//   })
//   return 'yes!'
// }
