import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { isAuthenticated } from '../../features/global/globalSlice'
import { defaultAuthenticationPath } from '../../constants'

export const RequireAuth: React.FC<{
  redirectTo?: string
}> = ({ redirectTo = defaultAuthenticationPath, children }) => {
  const { pathname } = useLocation()
  const authenticated = useAppSelector(isAuthenticated)

  if (authenticated) {
    return <>{children}</>
  }

  return (
    <Redirect
      to={{
        pathname: redirectTo,
        search: `?from=${pathname}`,
      }}
    />
  )
}
