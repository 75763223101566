import { toast } from 'react-toastify'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import React, { useCallback, useState } from 'react'
import { WorkspaceRolePermission } from '@dmp/api-sdk'
import { useRecoilState, useRecoilValue } from 'recoil'
import authEmpty from '../../../assets/empty/auth_empty.png'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { dialog, Button, Table, TableCellParams } from '@dmp/components'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import PermissionPickerModal from '../../../components/PermissionPickerModal'
import { findWorkspaceRole, rolePermissionsAtom } from '../../../recoil/role'

const localContext = React.createContext<{
  roleId: string | number | undefined
  permissions: WorkspaceRolePermission[]
}>({ permissions: [], roleId: undefined })

interface PermissionMutationParams {
  workspaceId: string
  roleId: number | string
  permissions: Array<{
    permissionName: string
    resourceIdentifier: string
  }>
}

type PermissionMutation =
  | {
      type: 'grant'
      hide?: () => void
      payload: PermissionMutationParams
    }
  | {
      type: 'revoke'
      hide?: () => void
      payload: PermissionMutationParams
    }

export const PermissionTab: React.FC<{ roleId: string | number }> = ({ roleId }) => {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const role = useRecoilValue(findWorkspaceRole({ roleId }))
  const [permissions, setPermissions] = useRecoilState(rolePermissionsAtom)
  const [showAdd, setShowAdd] = useState(false)
  const queryClient = useQueryClient()

  const { isLoading, refetch } = useQuery(
    ['rolePermissions', workspaceId, roleId],
    () => {
      if (!workspaceId || !roleId) {
        return Promise.reject()
      }
      return apiClient.listWorkspaceRolePermissions({ workspaceId, roleId })
    },
    {
      onSuccess: resp => {
        if (resp.success && resp.data) {
          setPermissions([...resp.data])
        } else {
          toast.error('读取权限列表出错')
        }
      },
    }
  )

  const mutation = useMutation(
    (mu: PermissionMutation) => {
      if (mu.type === 'grant') {
        return apiClient.assignRolePemissions({
          ...mu.payload,
        })
      }
      if (mu.type === 'revoke') {
        return apiClient.revokeRolePemissions({
          ...mu.payload,
        })
      }
      return Promise.reject()
    },
    {
      onSuccess: (resp, mu) => {
        if (!resp.success) {
          if (mu.type === 'grant') {
            toast.error('添加权限出错，请稍候重试')
          } else if (mu.type === 'revoke') {
            toast.error('撤销权限出错，请稍候重试')
          } else {
            toast.error('出错了，请稍候重试')
          }
        } else {
          mu?.hide?.()
          queryClient.invalidateQueries(['rolePermissions', mu.payload.workspaceId, mu.payload.roleId])
          refetch()
          setShowAdd(false)
        }
      },
    }
  )

  const handleDel = useCallback(
    (perm: WorkspaceRolePermission) => async () => {
      if (!workspaceId || !roleId) {
        return
      }
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: `确认要删除权限——${perm.description}吗？`,
        onConfirm({ hide }) {
          mutation.mutate({
            hide,
            type: 'revoke',
            payload: {
              workspaceId,
              roleId,
              permissions: [
                {
                  permissionName: perm.name,
                  resourceIdentifier: perm.resourceIdentifier,
                },
              ],
            },
          })
        },
      })
    },
    [mutation, workspaceId, roleId]
  )

  const handleAdd = useCallback(
    async (candidates?: WorkspaceRolePermission[]) => {
      if (!workspaceId || !role || !candidates) {
        return
      }

      const payload = {
        workspaceId,
        roleId: role.id,
        permissions: (candidates || []).map(c => ({
          permissionName: c.name,
          resourceIdentifier: c.resourceIdentifier,
        })),
      }

      mutation.mutate({
        type: 'grant',
        payload,
      })
    },
    [workspaceId, role, mutation]
  )

  const columns = React.useMemo(
    () => [
      { title: '权限', colKey: 'name' },
      { title: '描述', colKey: 'description', maxWidth: 200, ellipsis: true },
      { title: '关联资源', colKey: 'resourceIdentifier', maxWidth: 200, ellipsis: true },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        cell({ row }: TableCellParams<WorkspaceRolePermission>) {
          return (
            !role?.locked && (
              <Button variant='text' theme='danger' onClick={handleDel(row)}>
                删除
              </Button>
            )
          )
        },
      },
    ],
    [handleDel, role?.locked]
  )

  return (
    <localContext.Provider value={{ permissions, roleId }}>
      <div className='d-flex justify-content-between my-4'>
        <div className='text-tips'>添加或删除权限。授权了此角色的用户都将授予这些权限。</div>
        {/* 管理员角色已默认分配所有权限，无法更改或删除 */}
        {!role?.locked && (
          <div>
            <Button onClick={() => setShowAdd(v => !v)}>添加权限</Button>
          </div>
        )}
      </div>
      <Table
        columns={columns}
        rowKey='id'
        data={permissions}
        loading={isLoading}
        empty={
          <>
            <img className='mb-2' src={authEmpty} style={{ width: 100, height: 100 }} />
            <div>暂无任何权限</div>
          </>
        }
        emptyIcon={false}
      />

      <PermissionPickerModal
        show={showAdd}
        proceed={handleAdd}
        cancel={() => setShowAdd(false)}
        permissions={permissions}
        isAdminRole={role?.admin || false}
      />
    </localContext.Provider>
  )
}
