import React, { useState } from 'react'
import { copyToClipboard } from '../../utils/clipboard'
import { Button, Input, InputProps, InputAdornment } from '@dmp/components'

export interface CopyableTextProps extends InputProps {
  /** 在浏览器处于不安全的情况下 (http协议)，隐藏复制按钮 */
  hiddenCopyInUnsecureContext?: boolean
}

export const CopyableText: React.FC<CopyableTextProps> = React.forwardRef(
  ({ hiddenCopyInUnsecureContext = true, ...rest }) => {
    const textRef = React.createRef<any>()
    const [buttonVar, setButtonVar] = useState<'default' | 'success' | 'warning'>('default')

    // 非安全环境，clipboard API 不开放
    const diabledCopyButton = !window.isSecureContext

    const handleCopy = async () => {
      const input = textRef?.current?.inputElement
      if (!(input && input?.value)) return

      const doCopy = async () => {
        try {
          return await copyToClipboard(input.value)
        } catch (e) {
          // 不支持 clipboard 方法，退化为 execCommand
          input.setSelectionRange(0, input.value.length + 1)
          return document.execCommand('copy')
        }
      }
      const ok = await doCopy()
      setButtonVar(ok ? 'success' : 'warning')
      setTimeout(() => setButtonVar('default'), 1000)
    }

    const renderButton = () => {
      // 不安全，且要求隐藏
      if (diabledCopyButton && hiddenCopyInUnsecureContext) {
        return <></>
      }
      // 不安全，但显示
      if (diabledCopyButton) {
        return (
          <Button disabled variant='base' title='复制只有在浏览器处于 HTTPS 下才能使用'>
            复制
          </Button>
        )
      }
      // 可以正常工作的情况
      return (
        <span style={{ width: 45, cursor: 'default' }} className='text-primary d-inline-block' onClick={handleCopy}>
          {buttonVar === 'success' ? '已复制' : '复制'}
        </span>
      )
    }

    return (
      <InputAdornment append={renderButton()} className='w-100'>
        <Input ref={textRef} {...rest} />
      </InputAdornment>
    )
  }
)
