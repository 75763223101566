import { User, UserPermission, WorkspaceMember, WorkspaceRole } from '@dmp/api-sdk'
import { atom } from 'recoil'

// 工作空间成员在工作空间范围拥有的所有权限
export const userPermissionsAtom = atom<UserPermission[]>({
  key: 'userPermissions',
  default: [],
})

// 工作空间成员的在此工作空间中的角色集合
export const userRolesAtom = atom<WorkspaceRole[]>({
  key: 'userRoles',
  default: [],
})

/**
 * 用户实例
 */
export const userAtom = atom<User | undefined>({
  key: 'user',
  default: undefined,
})

// /**
//  * 工作空间所有成员
//  */
// export const workspaceMembersAtom = atom<WorkspaceMember[]>({
//   key: 'workspaceMembers',
//   default: [],
// })

/**
 * 工作空间所有成员
 */
export const workspaceMembersAtom = atom<WorkspaceMember[]>({
  key: 'workspaceMembers',
  default: [],
})
