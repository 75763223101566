import React from 'react'
import { toast } from 'react-toastify'
import { DataSource } from '@dmp/api-sdk'
import { useMutation, useQuery } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { Dialog, Form, FormRule, FormItem, Input, Select } from '@dmp/components'
import { useHistory, useRouteMatch } from 'react-router'
import { eventBus, EventNames } from '../../../utils/eventBus'

export interface PropTypes {
  show: boolean
  onHide: (refresh?: boolean) => void
  defaultValues?: DataSource
}

type FormValuesType = {
  name: string
  adminAccount: string
  dataSourceId: number
}

export default (props: PropTypes) => {
  const { show, onHide, defaultValues } = props

  const workspaceId = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const assignDataSourceMutation = useMutation(
    async (values: FormValuesType) => {
      if (!(workspaceId && defaultValues?.id)) return Promise.reject()
      return apiClient.assignDataSources({
        workspaceId,
        organizationId: defaultValues?.id,
        dataSourceId: values.dataSourceId,
      })
    },
    {
      onSuccess(res) {
        if (res.success) {
          toast.success('编辑成功')
          eventBus.emit(EventNames.InitWorkspace)
          onHide(true)
        } else {
          toast.error(res.message)
        }
      },
      onError() {
        toast.error('系统异常')
      },
    }
  )

  const { data: channels } = useQuery(['queryChannelList', workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryChannelList(workspaceId)
    return res?.success ? res.data : []
  })

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values: FormValuesType = formRef.current?.getFieldsValue(true)
      assignDataSourceMutation.mutate(values)
    },
    [assignDataSourceMutation]
  )

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入公司名称' }],
      adminAccount: [{ required: true, message: '请输入公司名称' }],
      dataSourceId: [{ required: true, message: '请选择数据源' }],
    }),
    []
  )

  return (
    <Dialog
      visible={show}
      header='编辑数据源'
      onClose={() => onHide()}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ loading: assignDataSourceMutation.isLoading }}
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='公司名称' name='name' initialData={defaultValues?.name}>
          <Input placeholder='请输入公司名称，最多32个字符' maxlength={32} disabled />
        </FormItem>
        <FormItem label='企业管理员账号' name='adminAccount' initialData={defaultValues?.adminUser?.username}>
          <Input placeholder='请输入企业管理员账号，最多32个字符' maxlength={32} disabled />
        </FormItem>
        <FormItem label='数据源' name='dataSourceId' initialData={defaultValues?.channel?.id}>
          <Select options={(channels || []) as any} keys={{ value: 'id', label: 'name' }} placeholder='请选择数据源' />
        </FormItem>
      </Form>
    </Dialog>
  )
}
