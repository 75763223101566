import { Icon, Popup } from '@dmp/components'
import clsx from 'clsx'

export default (props: {
  label: string
  color?: 'success' | 'warning' | 'danger' | 'pending' | 'processing' | 'default' | 'info'
  error?: string
}) => {
  const { label, color = 'default', error } = props
  if (!label) return null
  return (
    <>
      <span className={`badge bg-${color}`} style={{ fontSize: 0, padding: '.2rem', opacity: 0.8 }}>
        {' '}
      </span>
      <span className={`ms-2 text-${color}`}>{label}</span>
      <Popup content={error} className={clsx({ 'd-none': !error })}>
        <span>
          <Icon type='icon-help' className='text-danger ms-1 fs-6' />
        </span>
      </Popup>
    </>
  )
}
