import React from 'react'
import { NavLink } from 'react-router-dom'
import { ISubNavItem } from './types'

import './SubNav.scss'

export const SubNav: React.FC<{
  menuItems: ISubNavItem[]
  activeClassName?: string
}> = ({ menuItems, activeClassName = 'active' }) => {
  if (!menuItems.length) {
    return <div>NO MENU ITEMS!</div>
  }

  return (
    <>
      <div className='nav nav-pills subnav'>
        {menuItems.map(mit => (
          <div key={mit.linkTo} className='nav-item'>
            <NavLink className='nav-link' activeClassName={activeClassName} to={mit.linkTo}>
              {mit.label}
            </NavLink>
          </div>
        ))}
      </div>
    </>
  )
}
