import React from 'react'
import { useRBAC } from './hooks'
import { Redirect, Route, RouteProps } from 'react-router-dom'

export type Credentials = {
  email: string
  username: string
  roles: string[]
  permissions: string[]
}

const defaultCredentials: Credentials = { email: '', username: '', roles: [], permissions: [] }

const credentialContext = React.createContext<Credentials>({ ...defaultCredentials })

export const CredentialProvider: React.FC = ({ children }) => {
  return <credentialContext.Provider value={{ ...defaultCredentials }}>{children}</credentialContext.Provider>
}

export interface GuardProps {
  hasPermission?: string
  hasAnyPermission?: string[]
  hasAnyRole?: string[]
  authenticated?: boolean
  fallback?: React.ReactNode
}

export const Guard: React.FC<GuardProps> = ({
  hasPermission = '',
  hasAnyPermission = [],
  hasAnyRole = [],
  authenticated = false,
  fallback,
  children,
}) => {
  const { hasPermission: verifyPermission, hasSystemRole, authenticated: isAuthenticated } = useRBAC()
  const satisfy = (() => {
    if (!hasPermission && hasAnyRole.length === 0 && hasAnyPermission.length === 0) {
      return true
    }

    // 权限委托验证通过，成功，直接跳出
    if (hasPermission) {
      return verifyPermission({ permission: hasPermission })
    }
    // 如果条件为需要登录，且实际没有登录，则失败
    if (authenticated) {
      return isAuthenticated
    }

    // 如果设置了任意权限满足，则验证任意权限
    if (hasAnyPermission.length > 0) {
      return hasAnyPermission.some(p => verifyPermission({ permission: p }))
    }

    // 如果设置了任意角色，则验证任意权限
    if (hasAnyRole.length > 0) {
      return hasAnyRole.some(r => hasSystemRole({ role: r }))
    }

    return false
  })()
  if (satisfy) {
    return <>{children}</>
  }
  if (fallback) {
    return <>{fallback}</>
  }
  return <></>
}

export const SafeRoute: React.FC<{ Component: React.FC } & RouteProps & GuardProps> = ({
  Component,
  hasPermission,
  hasAnyPermission,
  hasAnyRole,
  ...rest
}) => {
  return (
    <Route {...rest}>
      <Guard
        hasPermission={hasPermission}
        hasAnyPermission={hasAnyPermission}
        hasAnyRole={hasAnyRole}
        fallback={<Redirect to='/error' />}
      >
        <Component />
      </Guard>
    </Route>
  )
}
