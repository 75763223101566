import { AppThunk, RootState } from '../../app/store'
import { apiClient } from '../../utils/apiClient'
import { Workspace } from '@dmp/api-sdk'
import { Resource, WorkspacePermission, WorkspaceRole, WorkspaceUser } from '../../shared/models'
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'

export interface WsQuota {
  [key: string]: Record<string, number>
}

const rolesAdapter = createEntityAdapter<WorkspaceRole>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => `${a.id}`.localeCompare(`${b.id}`),
})

const resourceAdapter = createEntityAdapter<Resource>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => `${a.id}`.localeCompare(`${b.id}`),
})

const permissionsAdapter = createEntityAdapter<WorkspacePermission>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => `${a.id}`.localeCompare(`${b.id}`),
})

const usersAdapter = createEntityAdapter<WorkspaceUser>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => `${a.id}`.localeCompare(`${b.id}`),
})

export interface WorkspaceState {
  active: string | undefined
  roles: EntityState<WorkspaceRole> // 工作空间的角色
  resources: EntityState<Resource> // 工作空间的资源
  permissions: EntityState<WorkspacePermission> // 工作空间的权限
  users: EntityState<WorkspaceUser> // 工作空间的用户
  workspace: Workspace | null // 工作空间本身的信息
  quota: WsQuota | null // 工作空间配额
}

const initialState: WorkspaceState = {
  active: undefined,
  roles: rolesAdapter.getInitialState(),
  resources: resourceAdapter.getInitialState(),
  permissions: permissionsAdapter.getInitialState(),
  users: usersAdapter.getInitialState(),
  workspace: null,
  quota: null,
}

/**  根据url上的workspace ID 查询当前所处工作空间的详细信息 */
export const loadActiveWorkspace = createAsyncThunk<Workspace | null, { workspaceId: string }>(
  'workspace/loadActiveWorkspace',
  async ({ workspaceId }) => {
    if (!workspaceId) return Promise.reject()
    const workspaceData = await apiClient.queryWorkspaceInfo(workspaceId)
    return workspaceData?.success ? workspaceData?.data : null
  }
)

/** 获取工作空间的配额 */
export const loadWorkspaceQuota = createAsyncThunk<
  WsQuota | null,
  undefined,
  {
    state: RootState
  }
>('workspace/loadWorkspaceQuota', async (_params, { getState }) => {
  const workspaceId = getState().workspace?.workspace?.id
  if (!workspaceId) return Promise.reject('workspaceId is required!')
  const res = await apiClient.queryWsQuota({ workspaceId })
  return res?.success ? res?.data?.result : null
})

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    resetWorkspace: state => {
      state.active = initialState.active
      state.permissions = initialState.permissions
      state.resources = initialState.resources
      state.roles = initialState.roles
      state.users = initialState.users
      state.workspace = initialState.workspace
      state.quota = initialState.quota
    },
  },
  extraReducers: builder => {
    builder.addCase(loadActiveWorkspace.fulfilled, (state, action) => {
      state.workspace = action.payload
    })
    builder.addCase(loadWorkspaceQuota.fulfilled, (state, action) => {
      state.quota = action.payload
    })
  },
})

// actions
export const { resetWorkspace } = workspaceSlice.actions

// reducer
export default workspaceSlice.reducer

export const selectWorkspaceChannel = (state: RootState) => state.workspace?.workspace?.channel
export const selectWorkspaceQuota = (state: RootState) => state.workspace?.quota
