import clsx from 'clsx'
import React from 'react'
import Slider from 'rc-slider'
import { noop } from '@dmp/utils'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { SegmentResource } from '@dmp/api-sdk'
import { SegmentDetailPane } from './SegmentDetailPane'
import { apiClient } from '../../../../utils/apiClient'
import { createSegment } from '../../../../features/segment'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import { WorkspacePageLayout, Module } from '../../../../layout/PageLayout'
import { Button, Form, FormItem, Input, Select, Textarea } from '@dmp/components'

const transformerBaseNum = 1.2 // 指数数轴变换的底数，数越大收敛的越快（大数部分越拥挤）。
const transformerForward = (n: number): number => Math.log(n) / Math.log(transformerBaseNum)
// eslint-disable-next-line no-restricted-properties
const transformerBackward = (n: number): number => Math.pow(transformerBaseNum, n)

const NoWrapStrong = styled.strong`
  white-space: nowrap;
`
const maxSlideValue = transformerForward(1000)

// 0～1000 (10万~1亿)
const marks = {
  0: '0',
  [transformerForward(1).toFixed(0)]: '10万',
  [transformerForward(2).toFixed(4)]: '20万',
  [transformerForward(10).toFixed(6)]: '100万',
  [transformerForward(50).toFixed(6)]: '500万',
  [transformerForward(100).toFixed(6)]: '1000万',
  [transformerForward(200).toFixed(8)]: '2000万',
  [transformerForward(500).toFixed(6)]: {
    style: {
      color: 'orange',
    },
    label: <NoWrapStrong>5000万</NoWrapStrong>,
  },
  [transformerForward(1000)]: {
    style: {
      color: 'red',
    },
    label: <NoWrapStrong>1亿</NoWrapStrong>,
  },
}

export const CreateLookalikeSegment: React.FC<{
  seedSegmentId?: string
  onSuccess?: (newId: number | string) => void
}> = ({ seedSegmentId, onSuccess = noop }) => {
  const his = useHistory()
  const formRef = React.createRef<HTMLFormElement>()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const [upScaleTarget, setUpScaleTarget] = React.useState(0)
  const [segmentId, setSegmentId] = React.useState(seedSegmentId)
  const [candidates, setCandidates] = React.useState<SegmentResource[]>([])
  const dispatch = useAppDispatch()

  const upscaleStr = React.useMemo(() => {
    if (upScaleTarget === 0) {
      return '无'
    }
    if (window.Intl) {
      return new Intl.NumberFormat('zh-CN', { notation: 'compact' }).format(transformerBackward(upScaleTarget) * 100000) // 最小粒度 10万
    }
    return `${transformerBackward(upScaleTarget) * 10}万`
  }, [upScaleTarget])

  const realUpScaleTarget = (n: number): number => {
    if (n === 0) {
      return 0
    }
    return transformerBackward(n) * 100000
  }

  // 提交
  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      const segment = candidates?.find(it => it.id == values.seedSegmentId) ?? { velocity: 0 }
      if (segment?.velocity > 5000000) {
        toast.error('人群包大于500W，暂时不支持拓展')
        return
      }
      const promise = await dispatch(
        createSegment({
          type: 'lookalike',
          name: values.name,
          description: values.description,
          seedSegmentId: values.seedSegmentId,
          upScaleTarget: realUpScaleTarget(values.upScaleTarget),
        })
      )

      if (createSegment.rejected.match(promise)) {
        toast.error(promise?.payload?.message || '拓展人群异常')
      }

      if (createSegment.fulfilled.match(promise)) {
        if (promise?.payload?.success) {
          toast.success('拓展人群成功，系统正在审核中，请耐心等待!')
          onSuccess(promise.payload.id)
        }
      }
    },
    [candidates, dispatch, formRef, onSuccess]
  )

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入名称，至多32个字符' }],
      description: [{ required: true, message: '请输入描述，至多256个字符' }],
      seedSegmentId: [{ required: true, message: '请选择已有人群' }],
      upScaleTarget: [{ required: true, message: '请设置拓展量级' }],
    }),
    []
  )

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    const load = async () => {
      const resp = await apiClient.querySegmentList({
        workspaceId,
        status: 'SUCCESS',
        type: 'SELF,LABEL,INDIVIDUATION',
      })
      if (resp.success && resp.data) {
        setCandidates(resp.data)
      }
    }
    load()
  }, [workspaceId])

  return (
    <WorkspacePageLayout title='新建相似受众' hasBack>
      <Form ref={formRef} rules={rules} labelAlign='left' onSubmit={onSubmit}>
        <Module title='基本信息' hasGap>
          <FormItem label='名称' name='name'>
            <Input placeholder='请输入名称，至多32个字符' maxlength={32} />
          </FormItem>
          <FormItem label='描述' name='description'>
            <Textarea placeholder='请输入描述，至多256个字符' maxlength={256} />
          </FormItem>
          <FormItem label='选择已有人群' name='seedSegmentId' initialData={segmentId}>
            <Select
              placeholder='请选择已有人群'
              onChange={val => setSegmentId(val as string)}
              options={(candidates ?? []) as any}
              keys={{ value: 'id', label: 'name' }}
            />
          </FormItem>
          <FormItem className={clsx({ 'd-none': !segmentId })}>
            <div className='w-100'>
              <SegmentDetailPane segmentId={Number(segmentId)} />
            </div>
          </FormItem>
        </Module>
        <Module title='人群拓展设置'>
          <FormItem label='拓展量级' className='mb-0 t-form__label--required'>
            <span className='text-muted'>目标拓展量级&nbsp;</span> <strong> {upscaleStr}</strong>
          </FormItem>
          <FormItem name='upScaleTarget' style={{ marginLeft: 100 }}>
            <Slider
              className='w-75 mb-4 ms-2'
              min={0}
              max={maxSlideValue}
              marks={marks}
              step={0.1}
              onChange={n => setUpScaleTarget(n)}
            />
          </FormItem>
          <div className='mt-5 d-flex justify-content-between'>
            <Button variant='outline' onClick={() => his.goBack()}>
              取消
            </Button>
            <Button type='submit'>创建并关闭</Button>
          </div>
        </Module>
      </Form>
    </WorkspacePageLayout>
  )
}
