import { atom } from 'recoil'

/**
 * 试用申请表单
 */
export interface TrialApplication {
  id: number
  companyName: string
  contacts: string
  industryType: string
  mobile: string
  remark: string
  city?: string
  position?: string
  createTime: string
}

/**
 * 试用申请表单集合
 */
export const trialApplicationsAtom = atom<TrialApplication[]>({
  key: 'trialApplications',
  default: [],
})
