import React from 'react'
import { noop } from '@dmp/utils'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { Dialog, Form, FormItem, Input, FormRule, Textarea } from '@dmp/components'

interface NewRoleModalProps {
  show?: boolean
  proceed?: () => void
  cancel?: () => void
  workspaceId?: string
}

interface IState {
  name: string
  description: string
}

const NewRoleModal: React.FC<NewRoleModalProps> = ({ workspaceId, show = false, proceed = noop, cancel = noop }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const mutation = useMutation(
    (state: IState) => {
      if (!workspaceId) return Promise.reject()
      return apiClient.createWorkspaceRole({ workspaceId, ...state })
    },
    {
      onSuccess: res => {
        if (res.success) {
          toast.success('新建角色成功')
          proceed()
        } else {
          toast.error(res.message || '新建角色出错')
        }
      },
      onError: () => {
        toast.error('系统异常')
      },
    }
  )

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入角色名称' }],
      description: [{ required: true, message: '请输入角色描述' }],
    }),
    []
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      mutation.mutate(values)
    },
    [mutation]
  )

  return (
    <Dialog
      visible={show}
      header='新建角色'
      onClose={cancel}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ loading: mutation.isLoading }}
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='名称' name='name'>
          <Input placeholder='请输入角色名称，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='描述' name='description'>
          <Textarea placeholder='请输入角色描述，最多256个字符' maxlength={256} />
        </FormItem>
      </Form>
    </Dialog>
  )
}

export default NewRoleModal
