import React from 'react'
import { noop } from '@dmp/utils'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import Upload from '../../../../components/Upload'
import { useAppSelector } from '../../../../app/hooks'
import { apiClient } from '../../../../utils/apiClient'
import { segmentDeviceTypeEnum } from '../../../../constants/enums'
import { WorkspacePageLayout, Module } from '../../../../layout/PageLayout'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import { Button, Alert, Form, FormItem, Input, Textarea, Select } from '@dmp/components'

interface FormValuesType {
  /**  人群包名称 */
  name: string
  /**  描述 */
  description: string
  /**  人群包设备类型 */
  selfDeviceType: string
  /**  人群包文件 */
  file: any
}

export const CreateSelfSegment: React.FC<{
  onSuccess?: (newId: number | string) => void
}> = ({ onSuccess = noop }) => {
  const his = useHistory()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [uploadedFile, setUploadedFile] = React.useState<any[]>([])

  const createMutation = useMutation(
    (values: FormValuesType) => {
      const data = {
        workspaceId,
        ...values,
        file: undefined,
        ...values.file[0]?.response,
      }
      return apiClient.createSelfSegment(data)
    },
    {
      onSuccess(res) {
        if (res?.success) {
          onSuccess(String(res.data.id))
        } else {
          toast.error(res.message)
        }
      },
      onError() {
        toast.error('系统异常')
      },
    }
  )

  const customRequest = React.useCallback(async options => {
    const { file, onError: reject, onSuccess: resolve } = options
    const formData = new FormData()
    formData.set('file', file)
    const res = await apiClient.selfSegmentFileUpload(formData)
    if (res.success) {
      resolve(res.data)
    } else {
      reject()
      toast.error(res.message)
    }
  }, [])

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入名称，至多32个字符' }],
      description: [{ required: true, message: '请输入描述，至多256个字符' }],
      selfDeviceType: [{ required: true, message: '请选择人群包设备类型' }],
      file: [
        {
          validator: (val: any[]) => ({
            result: val?.length > 0,
            message: '请上传人群包',
          }),
        },
      ],
    }),
    []
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      createMutation.mutate(values)
    },
    [createMutation]
  )

  return (
    <WorkspacePageLayout title='新建自有人群' hasBack>
      <Module title='基本信息'>
        <Form ref={formRef} labelAlign='left' labelWidth={120} rules={rules} onSubmit={onSubmit}>
          <FormItem label='名称' name='name'>
            <Input placeholder='请输入名称，至多32个字符' maxlength={32} />
          </FormItem>
          <FormItem label='描述' name='description'>
            <Textarea placeholder='请输入描述，至多256个字符' maxlength={256} />
          </FormItem>
          <FormItem label='人群包设备类型' name='selfDeviceType'>
            <Select options={segmentDeviceTypeEnum.list} placeholder='请选择人群包设备类型' />
          </FormItem>
          <FormItem label='人群包上传' name='file' className='t-form__label--required mb-2'>
            <Upload
              customRequest={customRequest}
              accept='application/zip'
              onChange={val => setUploadedFile(val)}
              maxSize={{ value: 100, message: '请上传100M大小以内的zip格式文件' }}
              minSize={{ value: 0.01, message: '请上传10kb以上的zip格式文件' }}
              tips='提示：支持txt和csv两种文件格式的zip包上传，文件大小需小于100M，每行列入一个设备号，注意不要留有空格'
            />
          </FormItem>
          <FormItem>
            {uploadedFile?.[0]?.response && (
              <Alert
                className='w-100'
                theme='info'
                message={`共识别出${uploadedFile?.[0]?.response?.count}个设备标识`}
              />
            )}
          </FormItem>
        </Form>
        <div className='mt-4 d-flex justify-content-between'>
          <Button variant='outline' onClick={() => his.goBack()}>
            取消
          </Button>
          <Button loading={createMutation.isLoading} onClick={() => formRef.current?.submit()}>
            创建并关闭
          </Button>
        </div>
      </Module>
    </WorkspacePageLayout>
  )
}
