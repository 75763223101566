import React from 'react'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { WorkspaceRole, UpdateRoleSettingsReq } from '@dmp/api-sdk'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import { findWorkspaceRole } from '../../../recoil/role'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { Button, Form, FormRule, FormItem, Input, Textarea } from '@dmp/components'

interface IRoleSettingsState {
  name: string
  description: string
}

const RoleSettings: React.FC<{ role: WorkspaceRole }> = ({ role }) => {
  const queryClient = useQueryClient()
  const activeWorkspace = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const mutation = useMutation(
    (arg: UpdateRoleSettingsReq) => {
      if (!arg?.workspaceId || !arg?.roleId) return Promise.reject()
      return apiClient.updateRoleSettings(arg)
    },
    {
      onSuccess: () => {
        toast.success('保存修改成功')
        queryClient.invalidateQueries(['workspaceRoles', activeWorkspace])
      },
      onError: (err: any) => {
        if (!err) return
        if (err?.errors?.length) {
          toast.error(err?.errors?.map((it: any) => it?.message)?.join(';'))
        } else {
          toast.error('系统异常')
        }
      },
    }
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      if (!activeWorkspace) return Promise.reject()
      const { id: roleId } = role
      const values: IRoleSettingsState = formRef.current?.getFieldsValue(true)
      mutation.mutate({ workspaceId: activeWorkspace, roleId, ...values })
    },
    [activeWorkspace, mutation, role]
  )

  const readOnly = role?.locked
  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入角色名称' }],
      description: [{ required: true, message: '请输入描述' }],
    }),
    []
  )

  return (
    <Form className='mt-2' ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
      <FormItem label='名称' name='name' initialData={role?.name}>
        <Input placeholder='请输入角色名称，最多32个字符' readonly={readOnly} maxlength={32} />
      </FormItem>
      <FormItem label='描述' name='description' initialData={role?.description}>
        <Textarea placeholder='请输入角色描述，最多256个字符' readonly={readOnly} maxlength={256} />
      </FormItem>
      {!readOnly && (
        <FormItem>
          <Button type='submit' loading={mutation.isLoading}>
            保存
          </Button>
        </FormItem>
      )}
    </Form>
  )
}

export const SettingsTab: React.FC = () => {
  const { roleId } = useParams<{ roleId: string }>()
  const role = useRecoilValue(findWorkspaceRole({ roleId }))

  if (!role) {
    return <>暂无角色，请创建角色</>
  }

  return <RoleSettings role={role} />
}
