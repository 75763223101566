/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import { AppDispatch, RootState } from '../../app/store'
import { apiClient } from '../../utils/apiClient'

export interface DeleteSegmentParams {
  id: number | string
}

export const deleteSegment = createAsyncThunk<
  boolean,
  DeleteSegmentParams,
  {
    state: RootState
    rejectValue: {
      message: string
    }
  }
>('segment/deleteOne', async ({ id }, { getState, rejectWithValue }) => {
  const workspaceId = getState().workspace?.workspace?.id
  if (!workspaceId) {
    return rejectWithValue({ message: 'no workspace ID' })
  }

  const { success } = await apiClient.deleteSegment({ segmentId: id, workspaceId })
  return success
  // return true
})
