import clsx from 'clsx'
import React from 'react'
import { noop } from '@dmp/utils'

import { ReactComponent as IconQuestion } from 'bootstrap-icons/icons/question-circle.svg'

class CheckboxIdGen {
  fromId = 1

  get nextId() {
    this.fromId += 1
    return `app-chkbox-${this.fromId}`
  }
}

const checkboxIdGen = new CheckboxIdGen()

interface IProps {
  name?: string
  inline?: boolean
  checked?: boolean
  label?: string
  hint?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<IProps> = ({
  name,
  inline = true,
  checked = false,
  label = 'Checkbox label',
  hint,
  onChange = noop,
}) => {
  const id = checkboxIdGen.nextId

  return (
    <div className={clsx('form-check', { 'form-check-inline': inline })}>
      <input
        className='form-check-input'
        type='checkbox'
        id={id}
        name={name}
        value='option1'
        checked={checked}
        onChange={onChange}
      />
      <label className='form-check-label' htmlFor={id}>
        {label}{' '}
        {hint ? (
          <span title={hint} style={{ color: '#999' }}>
            <IconQuestion />
          </span>
        ) : null}
      </label>
    </div>
  )
}

export default Checkbox
