import { EventEmitter } from 'eventemitter3'

// 全局事件触发器
export const eventBus = new EventEmitter()

export enum EventNames {
  InitWorkspace = 'initWorkspace',
  Http401 = 'http_401',
  Http5xx = 'http_5xx',
}
