import React, { useCallback } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { LoginWrapper } from '../../components/Wrapper'
import { bootstrapApplication, invalidateSession } from '../../features/global'
import { selectorSwitches } from '../../features/global/globalSlice'
import { useTracker } from '../../utils/tracker'
import BasicLoginForm from './components/BasicLoginForm'

import './LoginPage.scss'

export const LoginPage: React.FC = () => {
  const switches = useAppSelector(selectorSwitches)
  const { openLoginAuth0 } = switches
  const his = useHistory()
  const dispatch = useAppDispatch()
  const { track } = useTracker()

  const handleLogin = useCallback(async () => {
    // 执行 bootstrap 流程:
    // 1. 拉取登录用户的角色、权限明细
    // 2. 拉去登录用户可用 workspace

    const promise = await dispatch(bootstrapApplication())
    if (bootstrapApplication.fulfilled.match(promise)) {
      // NOTE: 暂不执行 identify，因为大家都用同一个账号，无法区分
      // const { user } = promise.payload || {}
      // if (user) {
      //   identify(user.email)
      // }
      track('Login', { bootstrap: true })
      toast.success('登录成功！', { autoClose: 1000 })
      const { organization, user } = promise.payload ?? {}
      // 若当前用户为Admin且组织资料未补全，跳转至组织资料补全页面
      if (user?.roles?.includes('ADMIN') && !organization) {
        his.push('/origination/profile?from=login')
        return
      }
      his.push('/?from=login')
    }
    if (bootstrapApplication.rejected.match(promise)) {
      toast.warning('出了点问题，请刷行页面重试', { autoClose: 1000 })
      track('Login', { bootstrap: false })
    }
  }, [his, dispatch, track])

  // 打开 Login 页面时，执行退出，暂时修复 Login 登录 401 问题。
  useEffect(() => {
    dispatch(invalidateSession(''))
  }, [dispatch])

  return (
    <>
      <LoginWrapper>
        <div className='d-flex'>
          <div className='login-page-aside d-flex justify-content-center align-items-center' />
          <div className='login-page-form d-flex flex-column align-items-center'>
            <h4 className='w-75 mt-5 text-center title'>登录DMP</h4>
            <BasicLoginForm onLoginSuccess={handleLogin} />

            {/* {openLoginAuth0 ? (
              <>
                <div>or</div>
                <p>TODO: 使用 Auth0 登录</p>
              </>
            ) : null} */}
            {/* <div className='d-flex justify-content-beteen'>
              <div>还没有账号？</div>
              <div>
                <Link to='/signup/new?from=login'>立即注册</Link>
              </div>
            </div> */}
          </div>
        </div>
      </LoginWrapper>
    </>
  )
}
