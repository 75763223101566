import React, { useCallback, useState, useEffect } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { noop } from '@dmp/utils'

// import { ReactComponent as IconArrowLeft } from 'bootstrap-icons/icons/arrow-left.svg'
// import { ReactComponent as IconArrowRight } from 'bootstrap-icons/icons/arrow-right.svg'
import { ReactComponent as IconChecked } from 'bootstrap-icons/icons/check-square-fill.svg'
import { ReactComponent as IconHalfChecked } from 'bootstrap-icons/icons/dash-square-fill.svg'
import { ReactComponent as IconUnChecked } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as IconCaretRight } from 'bootstrap-icons/icons/chevron-right.svg'

import './GeoEditor.scss'
import Checkbox from '../../../../components/Checkbox/Checkbox'

const GroupItem = styled.li`
  cursor: pointer;
  :hover:not(.active) {
    background-color: #f3f4f5;
  }
`
const GrouListWrap = styled.div`
  overflow-y: auto;
  height: 300px;
  background-color: white;
  /* border-right: 1px solid #aaa; */
`

const IconWrap = styled.span<{ selected: boolean }>`
  color: ${props => (props.selected ? '#0033cc' : '#aaa')};
  :hover {
    color: #3180ff;
  }
`

interface Tree {
  id: string
  text: string
  children?: Tree[]
}

// http://www.mca.gov.cn/article/sj/xzqh/1980/2019/202002281436.html
const bogusTree: Tree = {
  id: '000000',
  text: '-',
  children: [
    { id: '110000', text: '北京市' },
    { id: '120000', text: '天津市' },
    { id: '310000', text: '上海市' },
    { id: '500000', text: '重庆市' },
    {
      id: '130000',
      text: '河北省',
      children: [
        {
          id: '130100',
          text: '石家庄市',
          children: [
            {
              id: '130101',
              text: '第一个区',
              // children: [
              //   {
              //     id: '130101/1',
              //     text: '第一个区的第一个街道',
              //     children: [
              //       { id: '130101/1/1', text: '棉纺厂第二家属区' },
              //       { id: '130101/1/2', text: '王家胡同花园' },
              //     ],
              //   },
              //   { id: '130101/2', text: '第一个区的第二个街道' },
              // ],
            },
            { id: '130102', text: '第二个区' },
            { id: '130103', text: '第三个区' },
          ],
        },
        { id: '130200', text: '唐山市' },
        { id: '130300', text: '秦皇岛市' },
        { id: '130400', text: '邯郸市' },
        { id: '130500', text: '邢台市' },
        { id: '130600', text: '保定市' },
        { id: '130700', text: '张家口市' },
        { id: '130800', text: '承德市' },
        { id: '130900', text: '沧州市' },
        { id: '131000', text: '廊坊市' },
        { id: '131100', text: '衡水市' },
      ],
    },
    {
      id: '140000',
      text: '山西省',
      children: [
        { id: '140100', text: '太原市' },
        { id: '140200', text: '大同市' },
        { id: '140300', text: '阳泉市' },
        { id: '140400', text: '长治市' },
        { id: '140500', text: '晋城市' },
        { id: '140600', text: '朔州市' },
        { id: '140700', text: '晋中市' },
        { id: '140800', text: '运城市' },
        { id: '140900', text: '忻州市' },
        { id: '141000', text: '临汾市' },
        { id: '141100', text: '吕梁市' },
      ],
    },
    {
      id: '320000',
      text: '江苏省',
      children: [
        { id: '320100', text: '南京市' },
        { id: '320200', text: '无锡市' },
        { id: '320300', text: '徐州市' },
        { id: '320400', text: '常州市' },
        { id: '320500', text: '苏州市' },
        { id: '320600', text: '南通市' },
        { id: '320700', text: '连云港市' },
        { id: '320800', text: '淮安市' },
        { id: '320900', text: '盐城市' },
        { id: '321000', text: '扬州市' },
        { id: '321100', text: '镇江市' },
        { id: '321200', text: '泰州市' },
        { id: '321300', text: '宿迁市' },
      ],
    },
    { id: '710000', text: '台湾省' },
    { id: '810000', text: '香港特别行政区' },
    { id: '820000', text: '澳门特别行政区' },
  ],
}

const bogusTreeIdx: Record<string, Tree> = {}

function visitTree0(tree: Tree, path: Tree[], callback?: (tree: Tree, visitPath: Tree[]) => boolean) {
  bogusTreeIdx[tree.id] = tree
  if (callback) {
    const stop = callback(tree, path)
    if (stop) {
      return
    }
  }
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach(tree0 => visitTree0(tree0, [...path, tree], callback))
  }
}

function visitTree(tree: Tree, callback?: (cur: Tree, visitPath: Tree[]) => boolean) {
  const path: Tree[] = []
  visitTree0(tree, path, callback)
}
// 建立 ID -> Tree 扁平索引
visitTree(bogusTree)

const treeContext = React.createContext<{
  selects: Record<string, boolean> // 已经选择了所有 ID
  activeId: string | undefined
  setActiveId: (activeId: string) => void
}>({ activeId: undefined, setActiveId: () => {}, selects: {} })

// 三态选择状态
type TripleCheckingState = 'checked' | 'unchecked' | 'indeterminate'

const Item: React.FC<{
  tree: Tree
  activeInList: string | undefined
  onSelect: (id: string) => void
  onToggle: (id: string) => void
}> = ({ tree, activeInList, onSelect, onToggle }) => {
  const [tripleChecked, setTripleChecked] = React.useState<TripleCheckingState>('unchecked') // 默认未选中
  const { selects } = React.useContext(treeContext)

  useEffect(() => {
    if (!selects) {
      return
    }

    const selected = selects[tree.id]
    const haveChildren = tree.children && tree.children.length > 0

    // 没有选中，直接退出
    if (!selected) {
      setTripleChecked('unchecked')
      return
    }

    // 未选中当前节点，无需继续判断
    if (!selected && !haveChildren) {
      setTripleChecked('unchecked')
      return
    }

    // 选中，且无下一级
    if (selected && !haveChildren) {
      setTripleChecked('checked')
      return
    }

    // 选中，有下一级，最复杂
    if (selected && haveChildren) {
      // 选中当前节点，且有子节点，递归跑一次
      let allChecked = true
      visitTree(tree, curNode => {
        // 有一个不在
        if (!selects[curNode.id]) {
          allChecked = false
          return true // 停止递归
        }
        return false
      })
      if (allChecked) {
        setTripleChecked('checked')
      } else {
        setTripleChecked('indeterminate')
      }
    }
  }, [selects, tree])

  const handleSelect = useCallback(() => {
    // setActiveId(tree.id)
    onSelect(tree.id)
  }, [onSelect, tree])

  const handleToggle = useCallback(() => {
    // 传播到上级
    onToggle(tree.id)
  }, [onToggle, tree])

  // 当前高亮
  const isActive = tree.id === activeInList

  // 是否选择了
  const isSelected = selects[tree.id]

  const renderTripleCheckbox = () => {
    switch (tripleChecked) {
      case 'checked':
        return <IconChecked />
      case 'indeterminate':
        return <IconHalfChecked />
      case 'unchecked':
        return <IconUnChecked />
      default:
        return <>NO ICON</>
    }
  }

  return (
    <GroupItem className={clsx('list-group-item', { 'bg-light': isActive })} onClick={handleSelect} onKeyPress={noop}>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          {/* <div>
            {isSelected ? (
              <IconWrap selected onClick={handleToggle}>
                <IconChecked />
              </IconWrap>
            ) : (
              <IconWrap selected={false} onClick={handleToggle}>
                <IconUnChecked />
              </IconWrap>
            )}
          </div> */}
          <div>
            <IconWrap selected={isSelected} onClick={handleToggle}>
              {renderTripleCheckbox()}
            </IconWrap>
          </div>
          <div className='mx-2'>{tree.text}</div>
        </div>
        {tree.children && tree.children.length > 0 ? (
          <>
            <span style={{ color: '#999' }}>
              <IconCaretRight />
            </span>
          </>
        ) : null}
      </div>
    </GroupItem>
  )
}

const List: React.FC<{
  tree: Tree
  level: number
  onItemSelect: (id: string) => void
}> = ({ tree, level, onItemSelect }) => {
  // const { selects } = React.useContext(treeContext)
  const [active, setActive] = useState<Tree | undefined>(undefined) // 选中项
  if (!tree.children) {
    return <></>
  }

  const handleItemSelect = (id: string) => {
    const tree0 = bogusTreeIdx[id]
    if (tree0 && tree0.children) {
      setActive(tree0)
    } else {
      setActive(undefined) // 取消显示下一季列表
    }
  }

  const handleItemToggle = (id: string) => {
    const tree0 = bogusTreeIdx[id]
    if (tree0) {
      setActive(tree0)
    } else {
      setActive(undefined)
    }
    if (onItemSelect) {
      onItemSelect(id)
    }
  }

  return (
    <div className='d-flex justify-content-start'>
      <GrouListWrap>
        <ul className={`list-group level-${level}`} style={{ width: 220, borderRadius: 0 }}>
          {tree.children.map(tree0 => (
            <Item
              key={tree0.id}
              tree={tree0}
              activeInList={active ? active.id : undefined}
              onToggle={handleItemToggle}
              onSelect={handleItemSelect}
            />
          ))}
        </ul>
      </GrouListWrap>
      {active ? (
        <div>
          <List tree={active} level={level + 1} onItemSelect={onItemSelect} />
        </div>
      ) : null}
    </div>
  )
}

// const runFunction = (fn: () => void) => {
//   if (fn) {
//     fn()
//   }
// }

const GeoEditor: React.FC<{
  data: string[]
  onChange?: (value: string[], type1: boolean, type2: boolean) => void
}> = ({ data, onChange = noop }) => {
  if (!data) {
    // TODO 选中的 id 集合
  }

  const [ids, setIds] = useState<Record<string, boolean>>({})
  const [activeId, setActiveId] = useState<string | undefined>(undefined)

  const [locationType1, setLocationType1] = React.useState(true)
  const [locationType2, setLocationType2] = React.useState(false)

  // toggle id
  const handleItemSelect = (id: string) => {
    const selected = !!ids[id] // 上一个状态，选中 or not
    const idsCopy = { ...ids }

    // 省一级选择 / 市一级选择
    // if (/(\d\d0000|\d{4}00)/.test(id)) {}

    // 不需要基于 ID 规则判断，而是直接看是不是有 children
    const tree0 = bogusTreeIdx[id]

    if (!tree0) {
      return
    }

    // TODO: 如何判断一颗树，下面所有子树的叶子结点都选中了，暴力递归显得很不优雅
    // 向下递归，
    // - 当选中时，所有子节点都处于未选中状态时，全部跟随选中
    // - 当取消选中时，所有子节点都处于选中状态时，全部取消选中状态
    //
    // 这里只实现了暴力递归，全选或全部反选子节点
    if (tree0.children) {
      // downwards, 将下一级全部选中，注意这里是深度优先递归，如果有大于 2 层，则也会选择到最深一级，例如这里假设的街道。
      visitTree(tree0, curNode => {
        if (selected) {
          // 递归选中全部子节点
          idsCopy[curNode.id] = false
        } else {
          // 递归取消选中
          idsCopy[curNode.id] = true
        }
        return false
      })
    }

    // 向上回朔至根，没有选中的要选中
    const rootTreeId = bogusTree.id
    visitTree(bogusTree, (cur, path) => {
      if (cur.id === id) {
        path.forEach(nodeInPath => {
          const { id: id0 } = nodeInPath
          if (id0 !== rootTreeId && !idsCopy[id0]) {
            // path 中的 node 并没有选中，则自动选中
            idsCopy[id0] = true
          }
        })
        return true // stop
      }
      return false
    })

    delete idsCopy[rootTreeId]
    setIds({ ...idsCopy, [id]: !ids[id] })
  }

  // tree with children
  const renderList = (tree: Tree, level: number) => {
    return <List tree={tree} level={level} onItemSelect={handleItemSelect} />
  }

  const renderSelects = () => {
    const list = Object.keys(ids)
      .filter(id => ids[id])
      .map(id => bogusTreeIdx[id])
      .filter(item => !!item)
    return (
      <>
        <span className='text-muted'>已选择：</span>
        {list && list.length > 0 ? (
          list.map(itm => (
            <span
              key={itm.id}
              className='badge text-success bg-light mx-1'
              title={`${itm.id}-${itm.text}`}
            >{`${itm.text}`}</span>
          ))
        ) : (
          <span>无</span>
        )}
      </>
    )
  }

  // TODO 需要对 selects 剪枝，具体说，就是如果一个省份下面的地级市全部选中了，
  // 则在结果中，以省份替换全部的地级市，以简化结果集。

  useEffect(() => {
    if (onChange) {
      const selects = Object.keys(ids).filter(id => ids[id])
      onChange([...selects], locationType1, locationType2)
    }
  }, [onChange, ids, locationType1, locationType2])

  return (
    <>
      <treeContext.Provider value={{ activeId, setActiveId: aId => setActiveId(aId), selects: ids }}>
        {/* <div className='text-muted'>选择省份或者城市</div> */}
        <div className='geo-location-picker' style={{ width: 700 }}>
          <div
            className='d-flex justify-content-between'
            style={{
              border: '1px solid #eee',
              borderRadius: '5px',
              backgroundColor: '#f8f9fa',
              overflowX: 'auto',
            }}
          >
            <div className='d-flex flex-1'>
              <div className='flex-1'>{renderList(bogusTree, 1)}</div>
              {/* <div className='mx-3'>{activeTree ? renderList(activeTree, 2) : null}</div> */}
            </div>
          </div>
          <div className='my-2'>
            <Checkbox
              checked={locationType1}
              onChange={e => setLocationType1(e.target.checked)}
              label='号码归属地'
              hint='手机号码运营商归属地，携号转网通用'
            />
            <Checkbox
              checked={locationType2}
              onChange={e => setLocationType2(e.target.checked)}
              label='常住地'
              hint='常住地到底是怎么解释呢？请开始你的解释'
            />
          </div>
          <div className=''>
            <div className='my-2'>{renderSelects()}</div>
          </div>
        </div>
      </treeContext.Provider>
    </>
  )
}

export default GeoEditor
