import React from 'react'
import { SegmentResource } from '@dmp/api-sdk'
import { useAppSelector } from '../../../../app/hooks'
import { selectStatus } from '../../../../features/global/globalSlice'
import { Module } from '../../../../layout/PageLayout'
import { FormField } from '../../../../components/FormField/FormField'

export default ({ segment, readonly }: { segment: SegmentResource; readonly?: boolean }) => {
  const loadingStatus = useAppSelector(selectStatus)
  const upScaleTargetStr = React.useMemo(() => {
    if (!segment) {
      return '0'
    }
    if (segment.type === 'LOOKALIKE' && segment.spec) {
      const { upScaleTarget } = segment.spec

      if (window.Intl) {
        return new Intl.NumberFormat('zh-CN', { notation: 'compact' }).format(upScaleTarget)
      }
      return `${upScaleTarget}`
    }
    return '0'
  }, [segment])

  if (loadingStatus === 'loading') {
    return <>正在加载 ...</>
  }

  const { name, description } = segment

  return (
    <>
      <Module title='基本信息' hasGap>
        <FormField
          name='name'
          type='input'
          label='名称'
          placeholder='请输入名称，至多32个字符'
          defaultValue={name}
          disabled
        />

        <FormField name='description' type='textarea' label='描述' defaultValue={description} disabled />
      </Module>

      <Module title='人群拓展设置' hasGap={!readonly}>
        <FormField name='upScaleTarget' type='none' label='拓展量级' disabled>
          <div className='py-2'>{upScaleTargetStr}</div>
        </FormField>
      </Module>
    </>
  )
}
