import { WorkspaceRole, WorkspaceRolePermission, WorkspaceRoleUser } from '@dmp/api-sdk'
import { atom, selectorFamily } from 'recoil'

/**
 * 工作空间成员在工作空间范围拥有的所有权限
 */
export const roleUsersAtom = atom<WorkspaceRoleUser[]>({
  key: 'roleUsers',
  default: [],
})

/**
 * 当前工作空间角色列表
 */
export const workspaceRolesAtom = atom<WorkspaceRole[]>({
  key: 'workspaceRoles',
  default: [],
})

/**
 * 查找工作空间角色
 */
export const findWorkspaceRole = selectorFamily<WorkspaceRole | undefined, { roleId: string | number }>({
  key: 'findWorkspaceRole',
  get:
    ({ roleId }) =>
    ({ get }) => {
      const roles = get(workspaceRolesAtom)
      return roles.find(r => `${r.id}` === `${roleId}`)
    },
})

/**
 * 某工作空间角色的全部权限
 */
export const rolePermissionsAtom = atom<WorkspaceRolePermission[]>({
  key: 'rolePermissions',
  default: [],
})
