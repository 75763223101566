import React from 'react'
import qs from 'query-string'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router'
import { apiClient } from '../../utils/apiClient'
import { ReactComponent as IconXCircle } from 'bootstrap-icons/icons/x-circle.svg'
import { ReactComponent as IconCheckCircle } from 'bootstrap-icons/icons/check-circle.svg'

export default () => {
  const location = useLocation()
  const [status, setStatus] = React.useState<'failed' | 'success'>()
  // ?authorization_code=c702e5b24f84d234374034fcc29a2904&state=27
  const search = qs.parse(location.search)
  // http://localhost:30000/callback/auth?authorization_code=eedadaca0c53e9e66b64fb173f8ad447&state=4
  const { isLoading, mutate } = useMutation((params: any) => apiClient.queryAccessTokenByAuthCode(params), {
    onSuccess(res: any) {
      setStatus(res?.success ? 'success' : 'failed')
    },
    onError() {
      setStatus('failed')
    },
  })

  React.useEffect(() => {
    if (!search?.state) {
      setStatus('failed')
    } else {
      mutate(search)
    }
  }, [])

  React.useEffect(() => {
    if (status && window?.opener?.location?.href) {
      setTimeout(() => {
        const origin = window.opener.location.href
        window.opener.postMessage('SUCCESS', origin)
        window.close()
      }, 1000)
    }
  }, [status])

  const icon = 70

  return (
    <div className='vw-100 justify-content-center vh-100 d-flex align-items-center'>
      <div>
        <div>
          {isLoading && <div className='spinner-border' role='status' style={{ width: icon, height: icon }}></div>}
          {!isLoading && status === 'failed' && <IconXCircle style={{ width: icon, height: icon }} />}
          {!isLoading && status === 'success' && <IconCheckCircle style={{ width: icon, height: icon }} />}
        </div>
        <h5 className='h5 my-4'>
          {isLoading && '请稍等...'}
          {!isLoading && status === 'success' && '授权成功'}
          {!isLoading && status === 'failed' && '授权失败'}
        </h5>
        <>{window?.opener?.location?.href}</>
      </div>
    </div>
  )
}
