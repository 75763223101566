import React from 'react'
import { localToUTC } from '@dmp/utils'
import { QueryPushCrowdPackListReq } from '@dmp/api-sdk'
import { crowdPushStatusEnum, segmentSourceEnum } from '../../constants/enums'
import { Button, Form, FormItem, Input, DateRangePicker, Select } from '@dmp/components'

export default ({ onSearch }: { onSearch: (values?: QueryPushCrowdPackListReq) => void }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const handleSubmit = React.useCallback(
    ctx => {
      const values = formRef.current?.getFieldsValue(true) ?? {}
      onSearch({
        ...values,
        dateRange: undefined,
        startDate: localToUTC(values?.dateRange?.[0]),
        endDate: localToUTC(values?.dateRange?.[1]),
      })
    },
    [onSearch]
  )

  const handleReset = React.useCallback(
    ctx => {
      formRef.current?.reset()
      onSearch()
    },
    [onSearch]
  )

  const width = 290

  return (
    <div className='d-flex justify-content-between'>
      <Form ref={formRef} className='d-flex align-items-center flex-wrap' labelAlign='top' onSubmit={handleSubmit}>
        <FormItem label='人群包名称' name='segmentName' className='mb-2 me-2'>
          <Input style={{ width }} maxlength={32} placeholder='请输入人群包名称，最多32个字符' />
        </FormItem>
        <FormItem label='广告平台人群包命名' name='advertPlatformSegmentName' className='mb-2 me-2'>
          <Input style={{ width }} maxlength={32} placeholder='请输入广告平台人群包命名，最多32个字符' />
        </FormItem>
        <FormItem label='广告平台账户ID' name='advertiserAccountId' className='mb-2 me-2'>
          <Input style={{ width }} maxlength={32} placeholder='请输入广告平台账户ID，最多32个字符' />
        </FormItem>
        <FormItem label='人群包来源' name='source' className='mb-2 me-2'>
          <Select options={segmentSourceEnum.list} placeholder='不限' style={{ width }} />
        </FormItem>
        <FormItem label='状态' name='status' className='mb-2 me-2'>
          <Select placeholder='不限' options={crowdPushStatusEnum.filter('segment')} style={{ width }} />
        </FormItem>
        <FormItem label='创建时间' name='dateRange' className='mb-2 me-2'>
          <DateRangePicker style={{ width }} />
        </FormItem>
      </Form>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button onClick={() => formRef.current?.submit()}>搜索</Button>
        <Button className='ms-2' variant='outline' onClick={handleReset}>
          重置
        </Button>
      </div>
    </div>
  )
}
