import React from 'react'
import { useQuery } from 'react-query'
import { SegmentResource } from '@dmp/api-sdk'
import { useAppSelector } from '../../../../app/hooks'
import { apiClient } from '../../../../utils/apiClient'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import { Module } from '../../../../layout/PageLayout'
import { FormField } from '../../../../components/FormField/FormField'
import TargetingEditor from '../TargetingEditor/TargetingEditor'
import { SegmentEstimate } from './CreateNormalSegment'

export default ({ segment, readonly }: { segment: SegmentResource; readonly?: boolean }) => {
  const channelId = segment?.channel?.id
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const { data: tags } = useQuery(['queryTagsByChannel', workspaceId, channelId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryTagsByChannel({ workspaceId, dataSourceChannelId: channelId })
    return res?.success ? res.data || [] : []
  })

  const targeting = React.useMemo<Array<{ name: string; params: string[] }>>(() => {
    if (!segment || segment.type !== 'LABEL' || !segment.spec) {
      return []
    }

    const { targeting: pairs } = segment.spec

    return pairs.map(({ tagName: name, tagValues: params }) => ({ name, params }))
  }, [segment])

  if (segment.type !== 'LABEL') {
    return <>LABEL only!</>
  }

  if (!segment.spec) {
    return (
      <>
        标签没有包含 <code>spec</code>!
      </>
    )
  }

  const { name, description, estimateVelocity } = segment

  return (
    <>
      <Module title='基本信息' hasGap>
        <FormField
          name='name'
          type='input'
          label='名称'
          placeholder='请输入名称，至多32个字符'
          defaultValue={name}
          readOnly
        />

        <FormField name='description' type='textarea' label='描述' defaultValue={description} readOnly />
      </Module>

      <Module title='人群定向' hasGap={!readonly}>
        <div style={{ marginRight: 180 }}>
          <TargetingEditor largeMode data={tags || []} readOnly defaultValue={targeting} />
          <SegmentEstimate velocity={estimateVelocity || 0} />
        </div>
      </Module>
    </>
  )
}
