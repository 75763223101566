import React from 'react'
import { toast } from 'react-toastify'
import { dialog, Tag } from '@dmp/components'
import { segmentTypeEnum } from '../../constants'
import DangerZone from '../../components/DangerZone'
import { useRBAC } from '../../features/global/hooks'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { WorkspacePageLayout, Module } from '../../layout/PageLayout'
import NormalSegmentDetail from './components/NormalSegment/NormalSegmentDetail'
import SelfSegmentDetailPane from './components/SelfSegment/SelfSegmentDetailPane'
import { deleteSegment, readSegment, selectActiveSegment } from '../../features/segment'
import LooklikeSegmentDetail from './components/LookalikeSegment/LooklikeSegmentDetail'
import { PersonalSegmentEditor } from './components/PersonalSegment'

const DangerActionsZone: React.FC<{
  segmentId: number | string
}> = ({ segmentId }) => {
  const his = useHistory()
  const dispatch = useAppDispatch()
  const { hasPermission } = useRBAC()
  const [pending, setPending] = React.useState(false)

  const handleDelete = React.useCallback(() => {
    dialog({
      icon: <i className='iconfont icon-warning-fill text-warning' />,
      body: '人群包删除后不可恢复，确认要删除此人群吗？',
      async onConfirm({ hide }) {
        setPending(true)
        const result = await dispatch(deleteSegment({ id: segmentId }))
        if (deleteSegment.rejected.match(result)) {
          setPending(false)
          toast.error('删除失败')
        }
        if (deleteSegment.fulfilled.match(result)) {
          setPending(false)
          toast.success('已删除')
          hide?.()
          his.goBack()
        }
      },
    })
  }, [dispatch, segmentId, his])

  if (!hasPermission({ permission: 'delete:segments' })) return <></>

  return (
    <Module title='谨慎操作' icon={<i className='iconfont icon-warning-fill text-warning me-3' />}>
      <DangerZone
        title='删除人群包'
        subtitle='人群包删除后将导致关联的任何场景同时失效，此操作不可恢复。'
        label='删除'
        pending={pending}
        onConfirm={handleDelete}
      />
    </Module>
  )
}

const SegmentDetailPage: React.FC<{
  /** 只读，无任何修改权限 */
  readonly?: boolean
}> = props => {
  const { readonly } = props
  const dispatch = useAppDispatch()
  const { segmentId } = useParams<{ segmentId: string }>()
  const activeSegment = useAppSelector(selectActiveSegment)

  const renderContent = React.useCallback(() => {
    if (!activeSegment) return <>Loading ...</>
    if (activeSegment.type === 'LABEL') return <NormalSegmentDetail segment={activeSegment} readonly={readonly} />
    if (activeSegment.type === 'LOOKALIKE') return <LooklikeSegmentDetail segment={activeSegment} readonly={readonly} />
    if (activeSegment.type === 'SELF') return <SelfSegmentDetailPane segment={activeSegment} readonly={readonly} />
    if (activeSegment.type === 'INDIVIDUATION')
      return <PersonalSegmentEditor segment={activeSegment} readonly={readonly} />

    return (
      <>
        <div className='alert alert-warning'>
          抱歉！人群包类型 <code>{activeSegment.type}</code> 暂不支持查看。
        </div>
      </>
    )
  }, [activeSegment, readonly])

  React.useEffect(() => {
    dispatch(readSegment({ id: segmentId }))
  }, [dispatch, segmentId])

  if (!activeSegment) {
    return <>loading ...</>
  }

  const { type } = activeSegment

  return (
    <WorkspacePageLayout
      title='人群详情'
      headerLeft={
        <>
          {activeSegment.name}
          <small>（{activeSegment.id}）</small>
          <Tag theme='primary' variant='light' shape='round' className='border-brand-light'>
            {segmentTypeEnum.map.get(type)?.label}
          </Tag>
        </>
      }
      hasBack
    >
      {renderContent()}
      {readonly ? null : <DangerActionsZone segmentId={segmentId} />}
    </WorkspacePageLayout>
  )
}

export default SegmentDetailPage
