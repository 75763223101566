import './index.scss'
import React from 'react'
import logo from '../../assets/logo.png'
import { appName } from '../../constants'
import { Menu, Dropdown } from '@dmp/components'
import { useAppSelector } from '../../app/hooks'
import { sessionContext } from '../../sessionContext'
import { useRBAC } from '../../features/global/hooks'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { ReactComponent as IconPerson } from 'bootstrap-icons/icons/person-circle.svg'

enum OperationType {
  Logout, // 登出
}

const Operations: React.FC = () => {
  const history = useHistory()
  const session = React.useContext(sessionContext)
  const active = useAppSelector(store => store.workspace.workspace)
  const options = [{ value: OperationType.Logout, content: '退出登录' }]

  const handleOperationChange = React.useCallback(
    ({ value }) => {
      switch (value) {
        case OperationType.Logout:
          history.push('/logout?from=Nav')
          break
      }
    },
    [history]
  )

  return (
    <Dropdown options={options} onClick={handleOperationChange}>
      <div>
        <Menu.MenuItem>
          <div className='d-flex align-items-center lh-sm px-2'>
            <IconPerson />
            <div className='ms-2' style={{ width: 100 }}>
              <div className='text-truncate text-start'>{active?.name || session?.current?.user?.displayName}</div>
              <div className='text-truncate text-start'>{active?.organization?.displayName}</div>
            </div>
          </div>
        </Menu.MenuItem>
      </div>
    </Dropdown>
  )
}

export default withRouter(() => {
  const { hasSystemRole } = useRBAC()
  const history = useHistory()
  const location = useLocation()
  const showAdminMenus = hasSystemRole({ role: 'root' }) || hasSystemRole({ role: 'admin' })

  const menus = [
    { to: '/', label: '工作空间管理', visible: true },
    { to: '/origination/profile', label: '基础信息管理', visible: showAdminMenus },
    { to: '/origination/staffs', label: '人员管理', visible: showAdminMenus },
    { to: '/origination/launch_detail', label: '投放明细', visible: hasSystemRole({ role: 'root' }) },
  ]

  return (
    <Menu.HeadMenu
      className='px-4 shadow-sm dmp-custom__menu'
      value={location.pathname}
      onChange={val => history.push(val as string)}
      operations={<Operations />}
      logo={
        <a className='dmp-system-title' href='#'>
          <img src={logo} className='me-4' />
          {appName}
        </a>
      }
    >
      {menus.map(({ to, label, visible }) => {
        return (
          visible && (
            <Menu.MenuItem value={to} key={to}>
              {label}
            </Menu.MenuItem>
          )
        )
      })}
    </Menu.HeadMenu>
  )
})
