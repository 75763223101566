import React from 'react'
export * from './interface'
import { getUid } from './uid'
import { toast } from 'react-toastify'
import EllipsisTooltip from '../EllipsisTooltip'
import { UploadPropsTypes, FileType, UploadRequestOption } from './interface'
import clsx from 'clsx'

const Upload = React.forwardRef<HTMLElement, UploadPropsTypes>((props, ref) => {
  const {
    children,
    value,
    onChange,
    beforeUpload,
    customRequest,
    tips,
    multiple,
    maxSize,
    minSize,
    onRemove,
    isInvalid,
    ...rest
  } = props

  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const [fileList, setFileList] = React.useState<FileType[]>(value || [])
  const [selectedFiles, setSelectedFiles] = React.useState<FileType[]>([])

  const handleClick = React.useCallback(() => {
    if (!fileInputRef.current) return
    fileInputRef.current.click()
  }, [])

  const updateFileList = React.useCallback(
    files => {
      setFileList(files)
      if (onChange) onChange(files?.filter((f: { status: string }) => f.status === 'done'))
    },
    [onChange]
  )

  const updateFileItem = React.useCallback(
    file => {
      const index = fileList.findIndex(f => f.uid === file.uid)
      if (index > -1) {
        fileList[index] = file
      }
      return [...fileList]
    },
    [fileList]
  )

  const defaultUploadRequest = React.useCallback((options: UploadRequestOption) => {
    const { file, onSuccess } = options
    onSuccess(file)
    return Promise.resolve()
  }, [])

  const handleUploadFiles = React.useCallback(async () => {
    const uploadRequest = customRequest || defaultUploadRequest
    const queue = selectedFiles.map((file: FileType) =>
      uploadRequest({
        file,
        onProgress: percent => {
          percent = Number(percent)
          if (isNaN(percent)) {
            console.error('onProgress: percent is not a number')
            return
          }
          if (percent > 100) percent = 100
          if (percent < 0) percent = 0
          file.percent = percent
          updateFileList(updateFileItem(file))
        },
        onSuccess: response => {
          file.percent = 100
          file.status = 'done'
          file.response = response
          updateFileList(updateFileItem(file))
        },
        onError: error => {
          file.percent = 0
          file.status = 'failed'
          if (error) console.error(error)
          updateFileList(updateFileItem(file))
        },
      })
    )
    await Promise.all(queue)
  }, [customRequest, defaultUploadRequest, selectedFiles, updateFileItem, updateFileList])

  const mergedBeforeUpload = React.useCallback(
    file => {
      if (minSize?.value && file.size < minSize?.value * 1024 * 1024) {
        if (minSize?.message) toast.error(minSize.message)
        return false
      }
      if (maxSize?.value && file.size > maxSize?.value * 1024 * 1024) {
        if (maxSize?.message) toast.error(maxSize.message)
        return false
      }
      if (beforeUpload && !beforeUpload(file)) return false
      return true
    },
    [beforeUpload, maxSize, minSize]
  )

  const handleChange = React.useCallback(
    event => {
      const files: FileType[] = [...event.target.files]
      if (files.some(f => mergedBeforeUpload(f) === false)) return
      files.forEach(file => {
        file.percent = 0
        file.uid = getUid()
        file.status = 'uploading'
      })
      updateFileList(multiple ? fileList.concat(files) : files)
      setSelectedFiles(files)
    },
    [fileList, mergedBeforeUpload, multiple, updateFileList]
  )

  const handleRemove = React.useCallback(
    (file, index) => {
      const callback = () => {
        fileList.splice(index, 1)
        updateFileList(fileList)
      }
      if (onRemove) onRemove(file, callback)
      else callback()
    },
    [fileList, onRemove]
  )

  React.useEffect(() => {
    handleUploadFiles()
  }, [selectedFiles])

  const defaultChildren = (
    <button type='button' className={clsx('btn btn-outline-primary btn-sm', { 'border-danger': isInvalid })}>
      <i className='bi bi-upload me-2'></i>
      上传文件
    </button>
  )

  return (
    <div>
      <span onClick={handleClick} ref={ref}>
        {children || defaultChildren}
      </span>
      <input
        {...rest}
        multiple={multiple}
        ref={fileInputRef}
        className='d-none'
        value=''
        type='file'
        onChange={handleChange}
      />
      <div className='mt-2 text-muted'>{tips}</div>
      <div className='mt-2'>
        {fileList.map((file: FileType, index) => (
          <div key={file.uid} className='d-flex align-items-center'>
            <EllipsisTooltip text={file.name} className={file.status === 'failed' ? 'text-danger' : ''} />
            <span className='ms-2'>
              {file.status === 'uploading' && (
                <>
                  <span className='spinner-border spinner-border-sm me-1 text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                  {file.percent + '%'}
                </>
              )}
              {['failed', 'done'].includes(file.status) && (
                <i
                  className={`bi bi-x-circle-fill ${file.status === 'failed' ? 'text-danger' : ''}`}
                  onClick={() => handleRemove(file, index)}
                ></i>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
})

export default Upload
