import React, { useState } from 'react'
import { Session } from './shared/models'

export interface SessionContext {
  current: Session | undefined
  login: (username: string, password: string) => void
  logout: () => void
}

export const sessionContext = React.createContext<SessionContext>({
  current: undefined,
  login: () => {},
  logout: () => {},
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSession(isLoggedIn = false) {
  const [authorized, setAuthorized] = useState(isLoggedIn)
  const invalidate = () => setAuthorized(false)
  return { invalidate, authorized }
}
