import './index.scss'
import clsx from 'clsx'
import React from 'react'
import { Layout } from '@dmp/components'
import { useTranslation } from 'react-i18next'
import { useRBAC } from '../../features/global/hooks'
import { loadMemberships } from '../../features/global'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Link, Redirect, Route, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import {
  loadActiveWorkspace,
  loadWorkspaceQuota,
  resetWorkspace,
  selectWorkspaceChannel,
} from '../../features/workspace/workspaceSlice'
import { refetchInterval, systemAbilityEnum } from '../../constants'
import { eventBus, EventNames } from '../../utils/eventBus'
import UserPage from '../../pages/UserPage'
import RolePage from '../../pages/RolePage'
import SegmentPage from '../../pages/SegmentPage'
import BidUrlsPage from '../../pages/rta/BidUrlsPage'
import StrategiesPage from '../../pages/rta/StrategiesPage'
import DataSourcePage from '../../pages/crm/DataSourcePage'
import CrowdPackPushPage from '../../pages/CrowdPackPushPage'
import AdvAccountAuthPage from '../../pages/AdvAccountAuthPage'
import TrialApplicationPage from '../../pages/crm/TrialApplicationPage'
import PersonalSegmentPage from '../../pages/crm/PersonalSegmentPage'
import PushRecordPage from '../../pages/crm/PushRecordPage'
import CallSummaryPage from '../../pages/crm/CallSummaryPage'
import QuotaManagementPage from '../../pages/crm/QuotaManagementPage'
import LaunchDetailPage from '../../pages/crm/LaunchDetailPage'
import ApprovalPage from '../../pages/crm/ApprovalPage'
import { Switch } from 'react-router-dom'
import { SafeRoute } from '../../features/global'
import { useQuery } from 'react-query'

interface NavMenuItem {
  name: string // 唯一 ID
  label: string
  icon?: React.ReactNode
  linkTo?: string
  exact?: boolean
  children?: NavMenuItem[]
  visible?: boolean // 是否可见
}

const ScrollToTop: React.FC<{ className?: string }> = ({ children, className }) => {
  const location = useLocation()
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (ref.current?.scrollTo) ref.current.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className={clsx('w-100 h-100 overflow-auto', className)} ref={ref}>
      {children}
    </div>
  )
}

const Menus: React.FC<{ list: NavMenuItem[] }> = ({ list }) => {
  const location = useLocation()
  const [foldKeys, setFoldKeys] = React.useState<string[]>([])

  const handleTriggerFold = React.useCallback(
    (item, fold) => {
      if (fold) {
        setFoldKeys([...foldKeys, item.name])
      } else {
        setFoldKeys(foldKeys.filter(it => it !== item.name))
      }
    },
    [foldKeys]
  )

  const renderMenuItem = React.useCallback(
    (item: NavMenuItem) => {
      const curPath = location.pathname
      const hasChild = item?.children?.length
      const isActive = hasChild ? false : curPath.indexOf(item.linkTo || '') > -1
      const isFold = !foldKeys.includes(item.name)
      return hasChild ? (
        <div key={item.label}>
          <div
            className='px-2 mb-2 d-flex align-items-center justify-content-between'
            onClick={() => handleTriggerFold(item, isFold)}
          >
            <div>
              {item.icon}
              <span className='me-1' />
              {item.label}
            </div>
            <i className={`arrow me-1 ${isFold ? '' : 'down'}`}></i>
          </div>
          {isFold && item.children?.map(it => renderMenuItem(it))}
        </div>
      ) : (
        <Link
          className={`d-block text-decoration-none px-4 py-2 ms-1 mb-2 menu-item ${isActive ? 'active' : ''}`}
          to={item.linkTo || ''}
          aria-current='page'
          key={item.label}
        >
          {item.label}
        </Link>
      )
    },
    [foldKeys, handleTriggerFold, location.pathname]
  )

  return (
    <div className='dmp-workspace-layout__menus flex-shrink-0 flex-grow-0 py-3 h-100 overflow-auto'>
      {list.map(renderMenuItem)}
    </div>
  )
}

const WorkspaceLayout: React.FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const match = useRouteMatch()
  const { hasPermission, hasAnyPermission } = useRBAC()
  const [loading, setLoading] = React.useState(true)
  const [defaultPath, setDefaultPath] = React.useState('')
  const workspaceChannel = useAppSelector(selectWorkspaceChannel)
  const [permissionIsEmpty, setPermissionIsEmpty] = React.useState(false)
  const [menus, setMenus] = React.useState<NavMenuItem[]>([])
  const { t } = useTranslation()

  const setMenusList = React.useCallback(() => {
    const dmpMenus = [
      {
        name: 'segments',
        label: '我的人群',
        linkTo: `${match.url}/segment`,
        visible: hasPermission({ permission: 'read:segments' }),
      },
      {
        name: 'auth',
        label: '授权管理',
        linkTo: `${match.url}/auth`,
        visible: hasPermission({ permission: 'read:oauth' }),
      },
      {
        name: 'crowd_push_records',
        label: '人群包推送',
        linkTo: `${match.url}/crowd_push`,
        visible: hasPermission({ permission: 'read:segment_push' }),
      },
    ].filter(it => !!it.visible)
    const rtaMenus = [
      {
        name: 'bid_urls',
        label: 'RTA投放管理',
        linkTo: `${match.url}/bid_urls`,
        visible: hasPermission({ permission: 'read:RTA_launch_bind' }),
      },
      {
        name: 'strategies',
        label: 'RTA策略管理',
        linkTo: `${match.url}/strategies`,
        visible: hasPermission({ permission: 'read:RTA_strategy' }),
      },
    ].filter(it => !!it.visible)

    const crmMenus = [
      {
        name: 'trial_applications',
        label: '试用申请',
        linkTo: `${match.url}/trial_applications`,
        visible: hasPermission({ permission: 'read:trial_applications' }),
      },
      {
        name: 'data_source',
        label: '数据源管理',
        linkTo: `${match.url}/data_source`,
        visible: hasPermission({ permission: 'read:data_source_channel' }),
      },
      {
        name: 'personal_segment',
        label: '个性化人群',
        linkTo: `${match.url}/personal_segments`,
        visible: hasPermission({ permission: 'read:individuation_segments' }),
      },
      {
        name: 'push_record',
        label: '推送记录',
        linkTo: `${match.url}/push_record`,
        visible: hasPermission({ permission: 'read:segment_push_log' }),
      },
      {
        name: 'call_summary',
        label: '调用汇总',
        linkTo: `${match.url}/call_summary`,
        visible: hasPermission({ permission: 'read:call_summary' }),
      },
      {
        name: 'quota',
        label: '配额管理',
        linkTo: `${match.url}/quota`,
        visible: hasAnyPermission(['read:organ_quota', 'read:workspace_quota']),
      },
      {
        name: 'launch_detail',
        label: '投放明细',
        linkTo: `${match.url}/launch_detail`,
        visible: hasAnyPermission([
          'read:advertiser_daily_report',
          'read:advert_group_daily_report',
          'read:advert_segment_daily_report',
        ]),
      },
      {
        name: 'approval',
        label: '人群包审核',
        linkTo: `${match.url}/approval`,
        visible: hasAnyPermission(['read:segment_create_approve', 'read:segment_push_approve']),
      },
    ].filter(it => !!it.visible)

    const list = [
      {
        name: 'DMP',
        label: 'DMP管理',
        visible: dmpMenus.length > 0,
        children: dmpMenus,
        icon: <i className='iconfont icon-dmp'></i>,
      },
      {
        name: 'RTA',
        label: 'RTA管理',
        visible: systemAbilityEnum.hasAbility('RTA', workspaceChannel?.name) && rtaMenus.length > 0,
        children: rtaMenus,
        icon: <i className='iconfont icon-rta'></i>,
      },
      {
        name: 'CRM',
        label: 'CRM管理',
        visible: crmMenus.length > 0,
        children: crmMenus,
        icon: <i className='iconfont icon-crm'></i>,
      },
      {
        name: 'SETTING',
        label: '设置',
        visible: hasPermission({ permission: 'manager:workspace_user_role' }),
        icon: <i className='iconfont icon-setting'></i>,
        children: [
          {
            name: 'setting_user',
            label: '用户管理',
            linkTo: `${match.url}/users`,
          },
          {
            name: 'setting_role',
            label: '角色管理',
            linkTo: `${match.url}/roles`,
          },
        ],
      },
    ].filter(it => !!it.visible)
    const path = list[0]?.children?.[0]?.linkTo
    setPermissionIsEmpty(!path)
    setDefaultPath(path)
    setMenus(list)
  }, [hasAnyPermission, hasPermission, match.url, workspaceChannel?.name])

  const init = React.useCallback(async () => {
    if (!workspaceId) return
    setLoading(true)
    await dispatch(loadActiveWorkspace({ workspaceId }))
    await dispatch(loadMemberships({ workspaceId }))
    setLoading(false)
  }, [dispatch, workspaceId])

  useQuery(['loadWorkspaceQuota', workspaceId], () => dispatch(loadWorkspaceQuota()), {
    refetchInterval,
  })

  React.useEffect(() => {
    eventBus.on(EventNames.InitWorkspace, init)
    eventBus.emit(EventNames.InitWorkspace)

    // 为了能在两个浏览器tab场景下适配数据源切换后刷新的情况
    window.addEventListener('focus', () => {
      eventBus.emit(EventNames.InitWorkspace)
    })

    return () => {
      eventBus.removeAllListeners()
      window.removeEventListener('focus', () => {})
      dispatch(resetWorkspace())
    }
  }, [])

  React.useEffect(() => {
    if (loading) return
    setMenusList()
  }, [loading])

  if (permissionIsEmpty) {
    return (
      <div className='container my-4'>
        <div className='alert alert-warning' role='alert'>
          {t('抱歉，暂没有权限访问该工作空间，请联系该工作空间的管理员获取帮助。')}
        </div>
      </div>
    )
  }

  if (defaultPath) {
    return (
      <Layout className='dmp-workspace__layout'>
        <Layout.Aside>
          <Menus list={menus} />
        </Layout.Aside>
        <Layout.Content className={clsx('dmp-workspace-layout__content')}>
          <Route path={`${match.path}`} exact>
            <Redirect to={`${defaultPath}`} />
          </Route>
          <ScrollToTop className='dmp-workspace__content'>
            <Switch>
              <SafeRoute path={`${match.path}/segment`} hasAnyPermission={['read:segments']} Component={SegmentPage} />
              <SafeRoute path={`${match.path}/auth`} hasAnyPermission={['read:oauth']} Component={AdvAccountAuthPage} />
              <SafeRoute
                path={`${match.path}/crowd_push`}
                hasAnyPermission={['read:segment_push']}
                Component={CrowdPackPushPage}
              />
              <SafeRoute
                path={`${match.path}/roles`}
                hasAnyPermission={['manager:workspace_user_role']}
                Component={RolePage}
              />
              <SafeRoute
                path={`${match.path}/users`}
                hasAnyPermission={['manager:workspace_user_role']}
                Component={UserPage}
              />
              <SafeRoute
                path={`${match.path}/bid_urls`}
                hasAnyPermission={['read:RTA_launch_bind']}
                Component={BidUrlsPage}
              />
              <SafeRoute
                path={`${match.path}/strategies`}
                hasAnyPermission={['read:RTA_strategy']}
                Component={StrategiesPage}
              />
              <SafeRoute
                path={`${match.path}/trial_applications`}
                hasAnyPermission={['read:trial_applications']}
                Component={TrialApplicationPage}
              />
              <SafeRoute
                path={`${match.path}/data_source`}
                hasAnyPermission={['read:data_source_channel']}
                Component={DataSourcePage}
              />
              <SafeRoute
                path={`${match.path}/personal_segments`}
                hasAnyPermission={['read:individuation_segments']}
                Component={PersonalSegmentPage}
              />
              <SafeRoute
                path={`${match.path}/push_record`}
                hasAnyPermission={['read:segment_push_log']}
                Component={PushRecordPage}
              />
              <SafeRoute
                path={`${match.path}/call_summary`}
                // hasAnyPermission={['read:individuation_segments']}
                Component={CallSummaryPage}
              />
              <SafeRoute
                path={`${match.path}/quota`}
                hasAnyPermission={['read:organ_quota', 'read:workspace_quota']}
                Component={QuotaManagementPage}
              />
              <SafeRoute
                path={`${match.path}/launch_detail`}
                hasAnyPermission={[
                  'read:advertiser_daily_report',
                  'read:advert_group_daily_report',
                  'read:advert_segment_daily_report',
                ]}
                Component={LaunchDetailPage}
              />
              <SafeRoute
                path={`${match.path}/approval`}
                hasAnyPermission={['read:segment_create_approve', 'read:segment_push_approve']}
                Component={ApprovalPage}
              />
            </Switch>
          </ScrollToTop>
        </Layout.Content>
      </Layout>
    )
  }

  return (
    <div className='container my-4'>
      <div className='alert alert-primary' role='alert'>
        {t('正在加载')} ...
      </div>
    </div>
  )
}

export default WorkspaceLayout
