import React from 'react'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { toast } from 'react-toastify'
import qs from 'query-string'
import { platformEnum } from '../../../constants/enums'
import { useHistory, useLocation } from 'react-router-dom'
import NewCrowdPushDrawer from './BindingBidUrlDrawer'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { UTCToLocal } from '@dmp/utils'
import { BidUrl, PlatformEnum } from '@dmp/api-sdk'
import { useRBAC } from '../../../features/global/hooks'
import { Table, TableCellParams, Button, dialog } from '@dmp/components'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { usePlatform, useThrottledEffect } from '../../../hooks'
import { composeBidUrl } from '../../../utils'
import { selectWorkspaceQuota } from '../../../features/workspace/workspaceSlice'
import WsQuotaLine from '../../../components/WsQuotaLine'

const Page: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const search = qs.parse(location.search)
  const [show, setShow] = React.useState(false)
  const { platform, platformRadioGroup } = usePlatform({ exclude: [PlatformEnum.KuaiShou] })
  const [editInfo, setEditInfo] = React.useState<BidUrl>()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const wsQuotaResult = useAppSelector(selectWorkspaceQuota)

  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const {
    mutate,
    isLoading,
    data: boundBidUrlData,
  } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryBoundBidUrlsPage({
      page: Number(page) - 1,
      size: Number(size),
      workspaceId,
      sort: 'createdAt,desc',
      advertPlatform: platform,
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const deleteMutation = useMutation(
    ({ id }: { id: number; hide?: () => void }) =>
      workspaceId ? apiClient.deleteBoundBidUrl({ id, workspaceId }) : Promise.reject(),
    {
      onSuccess(res, mu) {
        if (res.success) {
          mu?.hide?.()
          toast.success('删除成功')
          const shouldQueryPrev = boundBidUrlData?.items?.length === 1 && Number(page) > 1
          if (shouldQueryPrev) onPaginationChange({ current: Number(page) - 1, pageSize: Number(size) })
          else mutate()
        } else {
          toast.error(res.message)
        }
      },
      onError() {
        toast.error('系统异常')
      },
    }
  )

  const handleHideModal = React.useCallback(
    (refresh, current) => {
      if (refresh) {
        if (current) mutate()
        else onPaginationChange({ current: 0, pageSize: Number(size) })
      }
      setShow(false)
      setEditInfo(undefined)
    },
    [onPaginationChange, mutate, size]
  )

  const handleEdit = React.useCallback(auth => {
    setEditInfo(auth)
    setShow(true)
  }, [])

  const handleDelete = React.useCallback(
    item => {
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: '删除后对应绑定在该bidurl上的RTA策略id会一并删除，且该bidurl将不再接受请求，是否删除？',
        onConfirm({ hide }) {
          deleteMutation.mutate({ id: item.id, hide })
        },
      })
    },
    [deleteMutation]
  )

  const columns = React.useMemo(
    () => [
      { title: '名称', colKey: 'name', width: 200, ellipsis: true },
      {
        title: '广告平台',
        width: 150,
        colKey: 'advertPlatform',
        cell({ row }: TableCellParams<BidUrl>) {
          return platformEnum.map.get(row.advertPlatform)?.label
        },
      },
      { title: '广告平台RTA名称', colKey: 'advertPlatformRtaName', width: 150, ellipsis: true },
      {
        title: '广告平台RTA ID',
        colKey: 'advertPlatformRtaId',
        width: 150,
        ellipsis: true,
        hidden: platform !== PlatformEnum.Tencent,
      },
      {
        title: '广告平台Token',
        colKey: 'advertPlatformRtaToken',
        width: 150,
        ellipsis: true,
        hidden: platform !== PlatformEnum.Tencent,
      },
      {
        title: '广告平台客户ID',
        colKey: 'advertPlatformCustomId',
        width: 150,
        ellipsis: true,
        hidden: platform !== PlatformEnum.Ocean,
      },
      {
        title: 'BidUrl',
        colKey: 'bidUrl',
        ellipsis: true,
        width: 250,
        cell({ row }: TableCellParams<BidUrl>) {
          return composeBidUrl(row)
        },
      },
      {
        title: '创建时间',
        colKey: 'createdAt',
        width: 200,
        cell({ row }: TableCellParams<BidUrl>) {
          return UTCToLocal(row.createdAt)
        },
      },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        fixed: 'right' as const,
        cell({ row }: TableCellParams<BidUrl>) {
          return (
            <>
              {hasPermission({ permission: 'update:RTA_launch_bind' }) && (
                <Button variant='text' theme='primary' className='me-4' onClick={() => handleEdit(row)}>
                  编辑
                </Button>
              )}
              {hasPermission({ permission: 'delete:RTA_launch_bind' }) && (
                <Button variant='text' theme='danger' className='me-4' onClick={() => handleDelete(row)}>
                  删除
                </Button>
              )}
            </>
          )
        },
      },
    ],
    [platform, hasPermission, handleEdit, handleDelete]
  )

  const handleCreateClick = React.useCallback(() => {
    setShow(true)
    setEditInfo({ advertPlatform: platform } as any)
  }, [platform])

  const canBindBidUrl = hasPermission({ permission: 'create:RTA_launch_bind' })

  useThrottledEffect(mutate, [page, size, platform])

  return (
    <WorkspacePageLayout
      title='RTA投放管理'
      headerTop={
        <WsQuotaLine
          list={[
            { label: '广点通已有投放', value: wsQuotaResult?.RTA_LAUNCH_BIND_QUOTA_TENCENT?.useCount },
            { label: '广点通剩余投放', value: wsQuotaResult?.RTA_LAUNCH_BIND_QUOTA_TENCENT?.surplusCount },
            { label: '巨量引擎已有投放', value: wsQuotaResult?.RTA_LAUNCH_BIND_QUOTA_OCEANENGINE?.useCount },
            { label: '巨量引擎剩余投放', value: wsQuotaResult?.RTA_LAUNCH_BIND_QUOTA_OCEANENGINE?.surplusCount },
          ]}
        />
      }
      headerRight={
        <>
          {canBindBidUrl && (
            <Button icon={<i className='iconfont icon-add-select' />} onClick={handleCreateClick}>
              新建RTA投放绑定
            </Button>
          )}
        </>
      }
      headerChildren={platformRadioGroup}
    >
      <Module>
        <Table
          columns={columns}
          data={boundBidUrlData?.items || []}
          empty='暂无RTA投放管理绑定'
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: boundBidUrlData?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
      <NewCrowdPushDrawer id={editInfo?.id} show={show} onHide={handleHideModal} defaultValues={editInfo} />
    </WorkspacePageLayout>
  )
}

export default Page
