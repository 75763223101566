import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { useThrottledEffect } from '../../../hooks'
import { OrgQuota, QueryOrgQuotaListReg, UpdateOrgQuotaReg } from '@dmp/api-sdk'
import { Table, TableCellParams, Button, Drawer, Form, FormItem, Input, InputNumber } from '@dmp/components'
import { Module } from '../../../layout/PageLayout'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { useRBAC } from '../../../features/global/hooks'

const Filters: React.FC<{
  onSearch: (values?: any) => void
}> = ({ onSearch }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const handleSubmit = React.useCallback(
    ctx => {
      const values = formRef.current?.getFieldsValue(true)
      onSearch(values)
    },
    [onSearch]
  )

  const handleReset = React.useCallback(
    ctx => {
      formRef.current?.reset()
      onSearch()
    },
    [onSearch]
  )

  const width = 290
  return (
    <Form ref={formRef} className='d-flex' labelAlign='top' onSubmit={handleSubmit} onReset={handleReset}>
      <div className='flex-fill d-flex align-items-center flex-wrap'>
        <FormItem label='企业名称' name='name' className='mb-2 me-2'>
          <Input placeholder='请输入企业名称' style={{ width }} maxlength={32} />
        </FormItem>
      </div>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button type='submit'>搜索</Button>
        <Button className='ms-2' variant='outline' type='reset'>
          重置
        </Button>
      </div>
    </Form>
  )
}

const Update: React.FC<{
  visible: boolean
  onClose: (refresh?: boolean) => void
  defaultValues?: any
}> = ({ defaultValues, onClose, visible }) => {
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const formRef = React.useRef<HTMLFormElement | null>(null)

  const updateMutation = useMutation(
    async (values: UpdateOrgQuotaReg) => {
      if (!(workspaceId && defaultValues?.organizationId)) return Promise.reject()
      const data = { ...values, organizationId: defaultValues.organizationId }
      return apiClient.updateOrgQuota(data, workspaceId)
    },
    {
      onSuccess(res) {
        if (res.success) {
          toast.success('编辑成功')
          onClose(true)
        } else {
          toast.error(res.message)
        }
      },
      onError(err) {
        console.error(err)
        toast.error('系统异常')
      },
    }
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      updateMutation.mutate(values)
    },
    [updateMutation]
  )

  const rules = React.useMemo(
    () => ({
      workspaceQuotaCount: [{ required: true, message: '请输入工作空间数量上限' }],
      userQuotaCount: [{ required: true, message: '请输入企业人员管理上限' }],
    }),
    []
  )

  return (
    <Drawer
      visible={visible}
      header='编辑配额'
      onClose={() => onClose()}
      confirmBtn={
        <Button loading={updateMutation.isLoading} onClick={() => formRef.current?.submit()}>
          确认
        </Button>
      }
    >
      <Form ref={formRef} labelAlign='left' labelWidth={150} rules={rules} onSubmit={onSubmit}>
        <div className='flex-fill'>
          <FormItem label='企业名称' initialData={defaultValues?.organizationResource?.displayName}>
            <Input placeholder='请输入企业名称，最多32个字符' maxlength={32} disabled />
          </FormItem>
          <FormItem label='企业管理员帐号' initialData={defaultValues?.organizationResource?.adminUser?.username}>
            <Input placeholder='请输入企业管理员账号，最多32个字符' maxlength={32} disabled />
          </FormItem>
          <FormItem
            label='工作空间数量上限'
            name='workspaceQuotaCount'
            initialData={defaultValues?.workspaceQuotaCount}
          >
            <InputNumber theme='normal' placeholder='请输入工作空间数量上限' className='w-100' min={0} max={1024} />
          </FormItem>
          <FormItem label='企业人员管理上限' name='userQuotaCount' initialData={defaultValues?.userQuotaCount}>
            <InputNumber theme='normal' placeholder='请输入企业人员管理上限' className='w-100' min={0} max={1024} />
          </FormItem>
        </div>
      </Form>
    </Drawer>
  )
}

export default (props: { expand: boolean }) => {
  const { expand } = props
  const history = useHistory()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const [visible, setVisible] = React.useState(false)
  const [editInfo, setEditInfo] = React.useState<OrgQuota | null>()
  const [filters, setFilters] = React.useState<QueryOrgQuotaListReg>()

  const search = qs.parse(location.search)
  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const {
    isLoading,
    mutate,
    data: dataSource,
  } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryOrgQuotaListPage(
      {
        page: Number(page) - 1,
        size: Number(size),
        sort: 'createdAt,desc',
        ...(filters || {}),
      },
      workspaceId
    )
    if (res.success) {
      return res.data
    }
    toast.error(res.message)
    return Promise.reject()
  })

  const handleSearch = React.useCallback(
    values => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const handleEdit = React.useCallback(row => {
    setVisible(true)
    setEditInfo(row)
  }, [])

  const columns = React.useMemo(
    () => [
      {
        title: '企业名称',
        colKey: 'displayName',
        width: 200,
        ellipsis: true,
        cell: ({ row }: TableCellParams<OrgQuota>) => row?.organizationResource?.displayName,
      },
      {
        title: '企业管理员账号',
        ellipsis: true,
        colKey: 'username',
        width: 160,
        cell: ({ row }: TableCellParams<OrgQuota>) => row?.organizationResource?.adminUser?.username,
      },
      { title: '工作空间数量上限', colKey: 'workspaceQuotaCount', width: 150 },
      { title: '企业人员管理上限', colKey: 'userQuotaCount', width: 150 },
      {
        title: '操作',
        width: 120,
        colKey: 'action',
        cell({ row }: TableCellParams<OrgQuota>) {
          return hasPermission({ permission: 'update:organ_quota' }) ? (
            <Button variant='text' theme='primary' onClick={() => handleEdit(row)}>
              编辑
            </Button>
          ) : null
        },
      },
    ],
    [handleEdit, hasPermission]
  )

  const handleDrawerHide = React.useCallback(
    (refresh?: boolean) => {
      if (refresh) mutate()
      setVisible(false)
    },
    [mutate]
  )

  useThrottledEffect(mutate, [page, size, filters])

  return (
    <>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          columns={columns}
          data={dataSource?.items || []}
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: dataSource?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
      <Update visible={visible} onClose={handleDrawerHide} defaultValues={editInfo} />
    </>
  )
}
