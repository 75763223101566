import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { LoginWrapper } from '../../components/Wrapper'
import { signupNew } from '../../features/global'
import { selectorSwitches } from '../../features/global/globalSlice'
import { BasicForm } from './components/BasicForm/BasicForm'

import './SignupPage.scss'

export const SignupPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const switches = useAppSelector(selectorSwitches)
  const handleSubmit = useCallback(
    async ({ email, password }) => {
      const promise = await dispatch(signupNew({ email, password }))

      // 出错或失败
      if (signupNew.rejected.match(promise)) {
        // TODO
      }

      // 没有出错，检查 code
      if (signupNew.fulfilled.match(promise)) {
        // TODO
      }
    },
    [dispatch]
  )
  const { openSignupSideImage } = switches
  useEffect(() => {}, [])

  return (
    <>
      <LoginWrapper closed={!openSignupSideImage}>
        <div className='d-flex'>
          <div className='login-page-container d-flex justify-content-center align-items-center flex-fill'>
            <div>
              <div className='h4 text-center text-muted my-3'>LOGO goes here</div>
              <div className='login-form bg-white p-4'>
                <div className='h5'>Signup</div>
                <p className='text-muted'>注册新账号</p>
                <BasicForm onSubmit={handleSubmit} />
                <hr />
                <div className='d-flex justify-content-beteen'>
                  <div>已经有账号？</div>
                  <div>
                    <Link to='/session/new?from=signup'>立即登录</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoginWrapper>
    </>
  )
}
