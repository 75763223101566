import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { useThrottledEffect } from '../../../hooks'
import { SegmentPushApprovalItem, QueryAdGroupLaunchDetailsReq, SegmentPushStatusEnum } from '@dmp/api-sdk'
import {
  Table,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  DateRangePicker,
  TableColumnType,
  Popup,
  dialog,
  Icon,
  Textarea,
} from '@dmp/components'
import { Module } from '../../../layout/PageLayout'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { crowdPushStatusEnum, deviceTypeEnum, platformEnum } from '../../../constants'
import { localToUTC, UTCToLocal } from '@dmp/utils'
import { defaultFilters } from './MainPage'
import { useRBAC } from '../../../features/global/hooks'
import StatusLabel from '../../../components/StatusLabel'

const Filters: React.FC<{
  onSearch: (values?: QueryAdGroupLaunchDetailsReq) => void
}> = ({ onSearch }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const handleSubmit = React.useCallback(() => {
    const values = formRef.current?.getFieldsValue(true)
    onSearch({
      ...values,
      dateRange: undefined,
      startTime: localToUTC(values?.dateRange?.[0]),
      endTime: localToUTC(values?.dateRange?.[1]),
    })
  }, [onSearch])

  const handleReset = React.useCallback(() => {
    formRef.current?.reset()
    setTimeout(handleSubmit, 100)
  }, [handleSubmit])

  React.useEffect(() => {
    handleSubmit()
  }, [])

  const width = 290
  return (
    <Form ref={formRef} className='d-flex' labelAlign='top' onSubmit={handleSubmit} onReset={handleReset}>
      <div className='flex-fill d-flex align-items-center flex-wrap'>
        <FormItem label='企业名称' name='organizationName' className='mb-2 me-2'>
          <Input placeholder='请输入企业名称' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='工作空间' name='workspaceName' className='mb-2 me-2'>
          <Input placeholder='请输入工作空间名称' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='人群包名称' name='segmentName' className='mb-2 me-2'>
          <Input placeholder='请输入人群包名称' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告平台' name='advertPlatform' className='mb-2 me-2'>
          <Select placeholder='不限' style={{ width }} options={platformEnum.list} />
        </FormItem>
        <FormItem label='状态' name='approveResult' className='mb-2 me-2'>
          <Select placeholder='不限' options={crowdPushStatusEnum.filter('approval')} style={{ width: width }} />
        </FormItem>
        <FormItem label='更新时间' name='dateRange' className='mb-2 me-2' initialData={defaultFilters.dateRange}>
          <DateRangePicker style={{ width }} endDisableDate={{ after: defaultFilters.dateRange[1] }} />
        </FormItem>
      </div>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button type='submit'>搜索</Button>
        <Button className='ms-2' variant='outline' type='reset'>
          重置
        </Button>
      </div>
    </Form>
  )
}

export default (props: { expand: boolean }) => {
  const { expand } = props
  const history = useHistory()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const [filters, setFilters] = React.useState()

  const search = qs.parse(location.search)
  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const { isLoading, mutate, data } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.querySegmentPushApprovalListPage(
      {
        page: Number(page) - 1,
        size: Number(size),
        ...(filters || {}),
        sort: 'advertSegmentPushEntity.pushAt,desc',
      },
      workspaceId
    )
    if (res.success) {
      return res.data
    }
    toast.error(res.message)
    return Promise.reject()
  })

  const handleSearch = React.useCallback(
    values => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const handleAgree = React.useCallback(
    row => {
      if (!workspaceId) return Promise.reject()
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: '确认该人群包信息无误，审核通过？',
        onConfirm: async ({ hide }) => {
          const res = await apiClient.approvalSegmentPush(
            { id: row.approveId, result: SegmentPushStatusEnum.Approved },
            workspaceId
          )
          if (res.success) {
            toast.success('操作成功')
            mutate()
            hide?.()
            return
          }
          toast.error(res.message || '操作失败')
        },
      })
    },
    [mutate, workspaceId]
  )

  const handleRefuse = React.useCallback(
    row => {
      if (!workspaceId) return Promise.reject()
      let message = ''
      dialog({
        body: (
          <Form labelAlign='top' rules={{ message: [{ required: true, message: '请填写拒绝原因' }] }}>
            <FormItem label='请填写拒绝原因' name='message'>
              <Textarea
                placeholder='请填写拒绝原因，最多128个字符'
                value={message}
                onChange={val => (message = val as string)}
                maxlength={128}
              />
            </FormItem>
          </Form>
        ),
        onConfirm: async ({ hide }) => {
          if (!message) {
            toast.error('请填写拒绝原因')
            return
          }
          const res = await apiClient.approvalSegmentPush(
            { id: row.approveId, result: SegmentPushStatusEnum.Refused, reason: message },
            workspaceId
          )
          if (res.success) {
            toast.success('操作成功')
            mutate()
            hide?.()
            return
          }
          toast.error(res.message || '操作失败')
          return true
        },
      })
    },
    [mutate, workspaceId]
  )

  const columns = React.useMemo<TableColumnType<SegmentPushApprovalItem>[]>(() => {
    return [
      { title: '企业名称', colKey: 'organizationName', width: 150, ellipsis: true },
      { title: '所属工作空间', colKey: 'workspaceName', width: 180, ellipsis: true },
      {
        title: '人群包名称',
        colKey: 'segmentName',
        width: 180,
        ellipsis: true,
        cell: ({ row }) => <Link to={`${location.pathname}/read/${row.segmentId}`}>{row.segmentName}</Link>,
      },
      {
        title: '广告平台',
        colKey: 'advertPlatform',
        width: 120,
        cell: ({ row }) => platformEnum.map.get(row?.advertPlatform)?.label,
      },
      { title: '广告账号ID', colKey: 'advertiserAccountId', width: 180, ellipsis: true },
      { title: '广告平台人群包命名', colKey: 'advertPlatformSegmentName', width: 180, ellipsis: true },
      {
        title: '设备类型',
        colKey: 'deviceType',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => deviceTypeEnum.map.get(row.deviceType)?.label,
      },
      {
        title: '状态',
        colKey: 'approveResult',
        width: 150,
        cell: ({ row }) => {
          const obj = crowdPushStatusEnum.map.get(row.approveResult)
          const error = row.approveResult === SegmentPushStatusEnum.Refused ? row.approveReason : ''
          return <StatusLabel color={obj?.color} label={obj?.label} error={error} />
        },
      },
      { title: '更新时间', colKey: 'pushAt', width: 200, cell: ({ row }) => UTCToLocal(row.pushAt) },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        fixed: 'right',
        cell: ({ row }) => {
          if (
            hasPermission({ permission: 'operate:segment_push_approve' }) &&
            row.approveResult === SegmentPushStatusEnum.Approving
          )
            return (
              <>
                <Button variant='text' theme='primary' content='同意' onClick={handleAgree.bind(null, row)} />
                <Button variant='text' theme='danger' content='拒绝' onClick={handleRefuse.bind(null, row)} />
              </>
            )
        },
      },
    ]
  }, [handleAgree, handleRefuse, hasPermission, location.pathname])

  useThrottledEffect(mutate, [page, size, filters])

  return (
    <>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          data={data?.items || []}
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: data?.total || 0,
            onChange: onPaginationChange,
          }}
          footData={data?.statistics ? [data?.statistics] : []}
          columns={columns}
        />
      </Module>
    </>
  )
}
