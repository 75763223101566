import clsx from 'clsx'
import qs from 'query-string'
import Filters from './Filters'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { useAppSelector } from '../../app/hooks'
import { apiClient } from '../../utils/apiClient'
import { useMutation, useQuery } from 'react-query'
import { useRBAC } from '../../features/global/hooks'
import React, { useState, useMemo, useCallback } from 'react'
import { WorkspacePageLayout, Module } from '../../layout/PageLayout'
import { useExpand, usePlatform, useThrottledEffect } from '../../hooks'
import { PushedCrowdOPack, QueryPushCrowdPackListReq, SegmentPushStatusEnum } from '@dmp/api-sdk'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { Link, useHistory, useLocation } from 'react-router-dom'
import NewCrowdPushDrawer, { NewCrowdPushFormValuesType } from './NewCrowdPushDrawer'
import { Table, Button, dialog, TableColumnType } from '@dmp/components'
import { platformEnum, crowdPushStatusEnum, segmentSourceEnum, deviceTypeEnum } from '../../constants'
import { selectWorkspaceQuota } from '../../features/workspace/workspaceSlice'
import WsQuotaLine from '../../components/WsQuotaLine'
import StatusLabel from '../../components/StatusLabel'

const Page: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const [show, setShow] = useState(false)
  const { expand, expandIcon } = useExpand()
  const { platform, platformRadioGroup } = usePlatform()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const wsQuotaResult = useAppSelector(selectWorkspaceQuota)

  const [filters, setFilters] = useState<QueryPushCrowdPackListReq>()
  const [editInfo, setEditInfo] = useState<NewCrowdPushFormValuesType>()
  const workspaceChannel = useAppSelector(store => store.workspace?.workspace?.channel)

  const search = qs.parse(location.search)
  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const handleEdit = useCallback(info => {
    setEditInfo(info)
    setShow(true)
  }, [])

  const {
    isLoading,
    data: crowdPackData,
    mutate,
  } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryPushCrowdPackListPage({
      workspaceId,
      page: Number(page) - 1,
      size: Number(size),
      sort: 'createdAt,desc',
      advertPlatform: platform,
      ...filters,
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return
    }
  })

  const segmentsQuery = useQuery(['query/all/segment', workspaceId], async () => {
    if (!workspaceId) {
      throw Error('Workspace_id is Required!')
    }
    const res = await apiClient.querySegmentList({ workspaceId: workspaceId, status: 'SUCCESS' })
    return res.success ? res.data || [] : []
  })

  const handleSearch = (values?: QueryPushCrowdPackListReq) => {
    onPaginationChange({ current: 1, pageSize: Number(size) })
    setFilters(values)
  }

  const handleHideModal = useCallback(
    (refresh, current) => {
      if (refresh) {
        if (current) mutate()
        else onPaginationChange({ current: 1, pageSize: Number(size) })
      }
      setShow(false)
      setEditInfo(undefined)
    },
    [mutate, onPaginationChange, size]
  )

  const handlePushCrowd = useCallback(
    async item => {
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: `确定要重新推送人群包——${item.segmentName}吗？`,
        async onConfirm({ hide }) {
          const res = await apiClient.pushCrowdPack({ id: item.id })
          if (res.success) {
            toast.success('操作成功，系统正在审核中，请耐心等待!')
            mutate()
            hide?.()
          } else {
            toast.error(res.message)
          }
        },
      })
    },
    [mutate]
  )

  const columns = useMemo<TableColumnType<PushedCrowdOPack>[]>(
    () => [
      {
        title: '人群包名称',
        colKey: 'segmentName',
        width: 200,
        ellipsis: true,
        cell: ({ row }) => {
          // 人群包数据源与当前工作空间的数据源不一致，不可进入详情
          if (row?.segmentChannel?.id !== workspaceChannel?.id) return row.segmentName
          return <Link to={`${location.pathname}/read/${row.segmentId}`}>{row.segmentName}</Link>
        },
      },
      {
        title: '人群包来源',
        colKey: 'source',
        width: 150,
        cell: ({ row }) => segmentSourceEnum.map.get(row.source)?.label,
      },
      {
        title: '所属广告平台',
        colKey: 'advertPlatform',
        width: 150,
        cell: ({ row }) => platformEnum.map.get(row.advertPlatform)?.label,
      },
      { title: '广告平台账户ID', colKey: 'advertiserAccountId', width: 180, ellipsis: true },
      { title: '广告平台人群包命名', colKey: 'advertPlatformSegmentName', width: 180, ellipsis: true },
      {
        title: '设备类型',
        colKey: 'deviceType',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => deviceTypeEnum.map.get(row.deviceType)?.label,
      },
      {
        title: '状态',
        colKey: 'status',
        width: 150,
        cell: ({ row }) => {
          const obj = crowdPushStatusEnum.map.get(row.status)
          let err = ''
          if (row.status === SegmentPushStatusEnum.Failed) err = row.errMsg
          if (row.status === SegmentPushStatusEnum.Refused) err = row.approveReason
          return <StatusLabel color={obj?.color} label={obj?.label} error={err} />
        },
      },
      {
        title: '创建时间',
        colKey: 'createAt',
        width: 200,
        cell: ({ row }) => UTCToLocal(row.createAt),
      },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        fixed: 'right',
        cell: ({ row }) => {
          // 人群包数据源与当前工作空间的数据源不一致，所有操作按钮隐藏
          if (row?.segmentChannel?.id !== workspaceChannel?.id) return
          if (![SegmentPushStatusEnum.Failed, SegmentPushStatusEnum.Refused].includes(row.status)) return null
          return (
            <>
              {hasPermission({ permission: 'update:segment_push' }) && (
                <Button
                  variant='text'
                  theme='primary'
                  className='me-4'
                  content='编辑'
                  onClick={() => handleEdit(row)}
                />
              )}
              {hasPermission({ permission: 'operate:segment_push' }) && (
                <Button variant='text' theme='primary' content='推送' onClick={() => handlePushCrowd(row)} />
              )}
            </>
          )
        },
      },
    ],
    [handleEdit, handlePushCrowd, hasPermission, location.pathname, workspaceChannel?.id]
  )

  const canCreate = hasPermission({ permission: 'create:segment_push' })

  const headerTop = (
    <WsQuotaLine
      list={[
        { label: '广点通已推送人群包数量', value: wsQuotaResult?.SEGMENT_PUSH_SUCCESS_QUOTA_TENCENT?.useCount },
        { label: '广点通剩余可推送数量', value: wsQuotaResult?.SEGMENT_PUSH_SUCCESS_QUOTA_TENCENT?.surplusCount },
        { label: '巨量引擎已推送人群包数量', value: wsQuotaResult?.SEGMENT_PUSH_SUCCESS_QUOTA_OCEANENGINE?.useCount },
        {
          label: '巨量引擎剩余可推送数量',
          value: wsQuotaResult?.SEGMENT_PUSH_SUCCESS_QUOTA_OCEANENGINE?.surplusCount,
        },
      ]}
    />
  )

  const headerRight = (
    <>
      {canCreate && (
        <Button
          icon={<i className='iconfont icon-add-select' />}
          onClick={() => handleEdit({ advertPlatform: platform })}
        >
          新建人群包推送
        </Button>
      )}
      <span className='ms-3'>{expandIcon}</span>
    </>
  )

  useThrottledEffect(mutate, [page, size, platform, filters])

  return (
    <WorkspacePageLayout
      title='人群包推送'
      headerRight={headerRight}
      headerChildren={platformRadioGroup}
      headerTop={headerTop}
    >
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          columns={columns}
          data={crowdPackData?.items || []}
          empty='暂无人群包推送记录，请推送人群包'
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: crowdPackData?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
      {show && (
        <NewCrowdPushDrawer
          segments={segmentsQuery?.data || []}
          show={show}
          onHide={handleHideModal}
          defaultValues={editInfo}
        />
      )}
    </WorkspacePageLayout>
  )
}

export default Page
