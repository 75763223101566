import React from 'react'
import Avatar from 'react-avatar'
import { useRecoilState } from 'recoil'
import { membersAtom } from './shared'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { WorkspaceMember } from '@dmp/api-sdk'
import { useAppSelector } from '../../app/hooks'
import { apiClient } from '../../utils/apiClient'
import { Link, useHistory } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import EllipsisTooltip from '../../components/EllipsisTooltip'
import { useWorkspaceAwareUrl } from '../../features/global/hooks'
import AddUserToSpaceModal from './components/AddUserToSpaceModal'
import { Module, WorkspacePageLayout } from '../../layout/PageLayout'
import { Table, TableCellParams, Button, dialog, Tag } from '@dmp/components'
import { selectActiveWorkspace, selectSession } from '../../features/global/globalSlice'
import { selectWorkspaceQuota } from '../../features/workspace/workspaceSlice'
import WsQuotaLine from '../../components/WsQuotaLine'

const LiteView = () => {
  const his = useHistory()
  const fixUrl = useWorkspaceAwareUrl()
  const session = useAppSelector(selectSession)
  const [members, setMembers] = useRecoilState(membersAtom)
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const { isLoading, refetch } = useQuery(
    ['workspaceMembers', workspaceId],
    () => {
      if (!workspaceId) return Promise.reject()
      return apiClient.listWorkspaceMembers({ workspaceId: workspaceId as string })
    },
    {
      onSuccess: resp => {
        if (resp.success && resp.data) {
          setMembers(resp.data)
        }
      },
    }
  )

  const handleDelete = React.useCallback(
    async (user: WorkspaceMember) => {
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: `是否将"${user?.displayName}"移出工作空间，此操作不可撤销，请谨慎操作`,
        async onConfirm({ hide }) {
          try {
            const res = await apiClient.moveUserOutOfSpace(String(user.id))
            if (res.success) {
              toast.success('操作成功')
              hide?.()
              refetch()
            } else {
              toast.error(res.message)
            }
          } catch (err) {
            console.error(err)
            toast.error('系统异常')
          }
        },
      })
    },
    [refetch]
  )

  const columns = React.useMemo(() => {
    return [
      {
        title: '名称',
        width: 220,
        colKey: 'displayName',
        cell({ row }: TableCellParams<WorkspaceMember>) {
          const detailLink = fixUrl(`users/${row.userId}`)
          const isYou = session && session.user.id === row.userId
          return (
            <div className='d-flex align-items-center'>
              <Avatar size='40' textSizeRatio={1.5} name={row.displayName} round='50%' />
              <div className='ms-2'>
                <div>
                  <Link className='link-action' to={detailLink}>
                    <EllipsisTooltip text={row.displayName} maxWidth={150} />
                    {isYou && (
                      <span className='ms-2 align-top'>
                        <Tag theme='primary'>你自己</Tag>
                      </span>
                    )}
                  </Link>
                </div>
                <div>
                  <EllipsisTooltip text={row.email} maxWidth={150} />
                </div>
              </div>
            </div>
          )
        },
      },
      {
        title: '角色',
        width: 300,
        colKey: 'role',
        cell({ row }: TableCellParams<WorkspaceMember>) {
          return (
            <>
              {row?.roleNames?.slice(0, 3)?.map((role: string) => (
                <Tag key={role} className='me-1' maxWidth={80}>
                  {role}
                </Tag>
              ))}
            </>
          )
        },
      },
      {
        title: '创建时间',
        width: 200,
        colKey: 'createdAt',
        cell({ row }: TableCellParams<WorkspaceMember>) {
          return UTCToLocal(row.createdAt)
        },
      },
      {
        title: '操作',
        width: 200,
        colKey: 'action',
        fixed: 'right' as const,
        cell({ row }: TableCellParams<WorkspaceMember>) {
          const detailLink = fixUrl(`users/${row.userId}`)
          const isYou = session && session.user.id === row.userId
          return (
            <>
              <Button variant='text' theme='primary' onClick={() => his.push(detailLink)}>
                详情
              </Button>
              {!(isYou || row.admin) && (
                <Button variant='text' theme='danger' className='ms-4' onClick={() => handleDelete(row)}>
                  移出工作区
                </Button>
              )}
            </>
          )
        },
      },
    ]
  }, [fixUrl, handleDelete, his, session])

  return <Table columns={columns} empty='没有任何成员' data={members} rowKey='id' loading={isLoading} />
}

const UserListPage: React.FC = () => {
  const queryClient = useQueryClient()
  const wsQuotaResult = useAppSelector(selectWorkspaceQuota)
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const [show, setShow] = React.useState(false)

  const handleHideModal = React.useCallback(
    (isRefresh?: boolean) => {
      if (isRefresh) queryClient.invalidateQueries(['workspaceMembers', workspaceId])
      setShow(false)
    },
    [setShow, queryClient, workspaceId]
  )

  return (
    <WorkspacePageLayout
      title='用户管理'
      remark='管理已经加入和受邀加入工作空间的成员，如删除、冻结、修改权限等操作。'
      headerTop={
        <WsQuotaLine
          list={[
            { label: '已有用户数量', value: wsQuotaResult?.MEMBER_QUOTA?.useCount },
            { label: '剩余用户数量', value: wsQuotaResult?.MEMBER_QUOTA?.surplusCount },
          ]}
        />
      }
      headerRight={
        <>
          <Button icon={<i className='iconfont icon-add-select' />} onClick={() => setShow(true)}>
            添加成员
          </Button>
        </>
      }
    >
      <Module>
        <LiteView />
      </Module>
      <AddUserToSpaceModal show={show} onHide={handleHideModal} />
    </WorkspacePageLayout>
  )
}

export default UserListPage
