import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot } from 'recoil'
import { createTracker } from '@dmp/tracker'
import './i18n'
import './index.scss'
import App from './App'
import { store } from './app/store'
import * as serviceWorker from './serviceWorker'
import { isProduction, mixpanelToken } from './constants'
import { TrackerProvier } from './utils/tracker'

const queryClient = new QueryClient()
const tracker = mixpanelToken ? createTracker({ type: 'mixpanel', token: mixpanelToken }) : undefined
ReactDOM.render(
  <React.StrictMode>
    <TrackerProvier tracker={tracker}>
      <Provider store={store}>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <App />
            {!isProduction && <ReactQueryDevtools />}
          </QueryClientProvider>
        </RecoilRoot>
      </Provider>
    </TrackerProvier>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
