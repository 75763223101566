import React from 'react'
import { User } from '@dmp/api-sdk'

export interface IDetailContext {
  user: User | undefined
}

/**
 * @deprecated
 */
export const localDetailContext = React.createContext<IDetailContext>({ user: undefined })
