import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { apiClient } from '../../utils/apiClient'
import { useThrottledEffect } from '../../hooks'
import { AdvertisingDetail, QueryAdvertisingDetailsReq } from '@dmp/api-sdk'
import { Table, Button, Form, FormItem, Input, Select, DateRangePicker, TableColumnType } from '@dmp/components'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectOrganization } from '../../features/global/globalSlice'
import { dateFmt, platformEnum } from '../../constants'
import { digitization } from '@dmp/utils'
import { format, sub } from 'date-fns'

export const defaultFilters = {
  dateRange: [format(sub(new Date(), { days: 7 }), dateFmt), format(sub(new Date(), { days: 1 }), dateFmt)], // 近七天，不包含当天
}

const Filters: React.FC<{
  onSearch: (values?: QueryAdvertisingDetailsReq) => void
  className?: string
}> = ({ onSearch, className }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const handleSubmit = React.useCallback(() => {
    const values = formRef.current?.getFieldsValue(true)
    onSearch({
      ...values,
      dateRange: undefined,
      startDate: values?.dateRange?.[0],
      endDate: values?.dateRange?.[1],
    })
  }, [onSearch])

  const handleReset = React.useCallback(() => {
    formRef.current?.reset()
    setTimeout(handleSubmit, 100)
  }, [handleSubmit])

  React.useEffect(() => {
    handleSubmit()
  }, [])

  const width = 290

  return (
    <Form
      ref={formRef}
      className={clsx('d-flex mb-4', className)}
      labelAlign='top'
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div className='flex-fill d-flex align-items-center flex-wrap'>
        <FormItem label='广告平台' name='advertPlatform' className='mb-2 me-2'>
          <Select placeholder='不限' style={{ width }} options={platformEnum.list} />
        </FormItem>
        <FormItem label='已授权帐号' name='accountId' className='mb-2 me-2'>
          <Input placeholder='请输入帐号ID' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告主ID' name='advertiserId' className='mb-2 me-2'>
          <Input placeholder='请输入广告主ID' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='创建时间' name='dateRange' className='mb-2 me-2' initialData={defaultFilters.dateRange}>
          <DateRangePicker
            style={{ width }}
            format='YYYY-MM-DD'
            endDisableDate={{ after: defaultFilters.dateRange[1] }}
          />
        </FormItem>
      </div>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button type='submit'>搜索</Button>
        <Button className='ms-2' variant='outline' type='reset'>
          重置
        </Button>
      </div>
    </Form>
  )
}

export default (props: { expand: boolean }) => {
  const { expand } = props
  const history = useHistory()
  const location = useLocation()
  const organization = useAppSelector(selectOrganization)

  const [filters, setFilters] = React.useState()

  const search = qs.parse(location.search)
  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const { isLoading, mutate, data } = useMutation(async () => {
    if (!organization?.id) return Promise.reject()
    const res = await apiClient.queryAdvertisingDetailsByPage({
      page: Number(page) - 1,
      size: Number(size),
      ...(filters || {}),
      organizationId: organization?.id,
    })
    if (res.success) return res.data
    toast.error(res.message)
    return Promise.reject()
  })

  const handleSearch = React.useCallback(
    values => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const columns = React.useMemo<TableColumnType<AdvertisingDetail>[]>(() => {
    return [
      { title: '时间', colKey: 'date', width: 140, fixed: 'left', foot: '合计' },
      {
        title: '广告平台',
        colKey: 'advert_platform',
        width: 120,
        cell: ({ row }) => platformEnum.map.get(row?.advert_platform)?.label,
      },
      { title: '已授权账号', colKey: 'account_id', width: 180, ellipsis: true },
      { title: '广告主ID', colKey: 'advertiser_id', width: 180, ellipsis: true },
      {
        title: '消耗/元',
        colKey: 'cost',
        width: 150,
        cell: ({ row }) => digitization(row.cost),
        foot: ({ row }) => digitization(row.cost),
      },
    ]
  }, [])

  useThrottledEffect(mutate, [page, size, filters])

  return (
    <>
      <Filters className={clsx({ 'd-none': !expand })} onSearch={handleSearch} />
      <Table
        rowKey='id'
        data={data?.items}
        loading={isLoading}
        pagination={{
          current: Number(page),
          pageSize: Number(size),
          total: data?.total || 0,
          onChange: onPaginationChange,
        }}
        footData={data?.statistics ? [data?.statistics] : []}
        columns={columns}
      />
    </>
  )
}
