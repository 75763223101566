import React from 'react'
import qs from 'query-string'
import { Radio } from '@dmp/components'
import { useExpand } from '../../../hooks'
import SegmentApproval from './SegmentApproval'
import SegmentPushApproval from './SegmentPushApproval'
import { WorkspacePageLayout } from '../../../layout/PageLayout'
import { useHistory, useLocation } from 'react-router'
import { useRBAC } from '../../../features/global/hooks'
import { dateFmt } from '../../../constants'
import { format, sub } from 'date-fns'

export const defaultFilters = {
  dateRange: [`${format(sub(new Date(), { days: 6 }), dateFmt)} 00:00:00`, `${format(new Date(), dateFmt)} 23:59:59`], // 近七天，不包含当天
}

export enum TabsEnum {
  Tab1 = '1',
  Tab2 = '2',
  Tab3 = '3',
}

export const useRadioTabs = () => {
  const history = useHistory()
  const location = useLocation()
  const search = qs.parse(location.search)
  const { hasPermission } = useRBAC()

  const list = [
    {
      value: TabsEnum.Tab1,
      label: '我的人群审核',
      visible: hasPermission({ permission: 'read:segment_create_approve' }),
      Component: SegmentApproval,
    },
    {
      value: TabsEnum.Tab2,
      label: '人群包推送审核',
      visible: hasPermission({ permission: 'read:segment_push_approve' }),
      Component: SegmentPushApproval,
    },
  ].filter(it => it.visible)

  const handleChange = React.useCallback(
    val => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: 1, dimension: val })}`)
    },
    [history, location.pathname, search]
  )

  const value = (search?.dimension ?? list[0].value) as TabsEnum

  return {
    value,
    Component: list.find(it => it.value === value)?.Component,
    radioTabs: (
      <Radio.Group value={value} onChange={handleChange}>
        {list.map(it =>
          it.visible ? (
            <Radio.Button value={it.value} key={it.value}>
              {it.label}
            </Radio.Button>
          ) : null
        )}
      </Radio.Group>
    ),
  }
}

export default () => {
  const { radioTabs, Component } = useRadioTabs()
  const { expand, expandIcon } = useExpand()
  return (
    <WorkspacePageLayout title='人群包审核' headerRight={expandIcon} headerChildren={radioTabs}>
      {Component && <Component expand={expand} />}
    </WorkspacePageLayout>
  )
}
