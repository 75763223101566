import React from 'react'
import { EstimateAction, IEstimateState, ITargetingLine } from './types'

export interface ISegmentContext {
  estimationState: IEstimateState | undefined
  estimateSize: number

  setEstimateSize: (size: number) => void

  setTagParams: (tag: string, params: string[]) => void

  getTagParams: (tag: string) => string[]

  reset: () => void

  // 定向条件
  targeting: Array<ITargetingLine>
  dispatch: (action: EstimateAction) => void
  isDoingEstimate: boolean
}

export const defaultSegmentContext: ISegmentContext = {
  estimationState: undefined,
  estimateSize: 0,
  setEstimateSize: () => {},
  setTagParams: () => {},
  getTagParams: () => [] as string[],

  reset: () => {},

  targeting: [],
  dispatch: () => {},
  isDoingEstimate: false,
}

export const segmentContext = React.createContext<ISegmentContext>(defaultSegmentContext)
