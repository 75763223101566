import React, { useCallback, useState } from 'react'
import { noop } from '@dmp/utils'
import './BasicLoginForm.scss'
import { newSession } from '../../../../features/global'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectStatus } from '../../../../features/global/globalSlice'
import { Button, Alert, Form, FormItem, Input } from '@dmp/components'

export const BasicLoginForm: React.FC<{
  onLoginSuccess?: () => void
}> = ({ onLoginSuccess = noop }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [errMsg, setErrMsg] = useState<{ field?: string; msg: string } | undefined>(undefined)
  const dispatch = useAppDispatch()
  const globalStatus = useAppSelector(selectStatus)

  const handleSubmit = useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      const p = await dispatch(newSession(values))

      // 先考虑成功，概率上会高一些
      if (newSession.fulfilled.match(p)) {
        // TODO
        setErrMsg(undefined)
        onLoginSuccess() // 成功。大成功
        return
      }

      if (newSession.rejected.match(p)) {
        // TODO
        // eslint-disable-next-line no-console
        console.log('p.error', p.error)
        if (p.error && p.error.message) {
          const { message } = p.error
          if (message === 'Bad credentials') {
            setErrMsg({ msg: '用户名或者密码错误，请修改后重试' })
            return
          }
          setErrMsg({ msg: `错误 ${message}` })
          return
        }
        setErrMsg({ msg: `未知错误` })
      }
    },
    [dispatch, onLoginSuccess]
  )

  const rules = React.useMemo(
    () => ({
      username: [{ required: true, message: '请输入Email 或用户名' }],
      password: [{ required: true, message: '请输入密码' }],
    }),
    []
  )

  return (
    <div className='basic-login-form w-75'>
      {errMsg && !errMsg.field && <Alert theme='error' message={errMsg.msg} />}

      <Form ref={formRef} labelAlign='top' onSubmit={handleSubmit} rules={rules}>
        <FormItem label='Email或用户名' name='username'>
          <Input placeholder='请输入Email或用户名' />
        </FormItem>
        <FormItem label='密码' name='password'>
          <Input type='password' placeholder='请输入密码' onEnter={formRef?.current?.submit} />
        </FormItem>
        <FormItem className='mt-5'>
          <Button type='submit' className='w-100' loading={globalStatus === 'loading'}>
            登录
          </Button>
        </FormItem>
      </Form>
    </div>
  )
}
