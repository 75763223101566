import { ApiClient, Client, ApiResponseWrapper } from '@dmp/api-sdk'
import { apiBaseUrl } from '../../constants'
import { eventBus } from '../eventBus'

// aka `http`
export const client = new Client({
  basePath: apiBaseUrl,
  eventBus,
})

// DMP 业务后台 API Client Instance
export const apiClient = new ApiClient(client)

export type { ApiResponseWrapper }
