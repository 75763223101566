import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import UserDetailPage from './UserDetailPage'
import UserListPage from './UserListPage'

export default () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/:userId`} render={() => <UserDetailPage />} />
      <Route path='' exact render={() => <UserListPage />} />
    </Switch>
  )
}
