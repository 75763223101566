import React, { useCallback, useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { WorkspaceRole } from '@dmp/api-sdk'
import { apiClient } from '../../../utils/apiClient'
import { localDetailContext } from '../context'
import { userRolesAtom } from '../../../recoil/user'
import AssignRoleModal, { showModalAtom } from './AssignRoleModal'
import { Table, TableCellParams, dialog, Button } from '@dmp/components'

interface RoleMutationParams {
  workspaceId: string
  userId: number
  roles: Array<number | string>
}

type RoleMutation =
  | {
      type: 'assign'
      hide?: () => void
      payload: RoleMutationParams
    }
  | {
      type: 'revoke'
      hide?: () => void
      payload: RoleMutationParams
    }

export const RolesTab: React.FC = () => {
  const queryClient = useQueryClient()
  const { user } = useContext(localDetailContext)
  const setShowAdd = useSetRecoilState(showModalAtom)
  const [roles, setRoles] = useRecoilState(userRolesAtom)
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const { isLoading } = useQuery(
    ['userRoles', workspaceId, user?.id || ''],
    () => {
      if (!workspaceId || !user) {
        return Promise.reject()
      }
      const { id: userId } = user
      return apiClient.listMemberRoles({ workspaceId, userId })
    },
    {
      staleTime: 10 * 1000,
      onSuccess: resp => {
        if (resp.success && resp.data) {
          setRoles([...resp.data])
        }
      },
    }
  )

  const mutation = useMutation(
    (rm: RoleMutation) => {
      // 追加 role
      if (rm.type === 'assign') {
        return apiClient.assignRolesToMember(rm.payload)
      }
      // 撤销 role
      if (rm.type === 'revoke') {
        return apiClient.revokeRolesFromMember(rm.payload)
      }
      return Promise.reject()
    },
    {
      onSuccess: (resp, rm) => {
        queryClient.invalidateQueries(['userRoles', workspaceId, rm.payload.userId])
        rm?.hide?.()
        if (rm.type === 'revoke') {
          toast.success(`撤销角色成功`)
        }
        if (rm.type === 'assign') {
          toast.success(`授权角色成功`)
        }
      },
      onError: err => {
        toast.error(`出错了 ${err}`)
      },
    }
  )

  const handleDelete = useCallback(
    (role: WorkspaceRole) => async () => {
      if (!workspaceId || !user) {
        return
      }
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: `确认不再分配此 "${role.name}" 角色吗？`,
        onConfirm({ hide }) {
          const { id: userId } = user
          const payload = {
            workspaceId,
            userId,
            roles: [role.id],
          }

          mutation.mutate({
            hide,
            type: 'revoke',
            payload,
          })
        },
      })
    },
    [mutation, user, workspaceId]
  )

  const handleAdd = useCallback(
    (newRoles: number[]) => {
      if (!workspaceId || !user) {
        return
      }
      const { id: userId } = user
      const payload = {
        workspaceId,
        userId,
        roles: [...newRoles],
      }

      mutation.mutate(
        {
          type: 'assign',
          payload,
        },
        {
          onSuccess: () => {
            setShowAdd(false)
          },
        }
      )
    },
    [mutation, user, workspaceId, setShowAdd]
  )

  const columns = React.useMemo(
    () => [
      { title: '名称', colKey: 'name', width: 200, ellipsis: true },
      { title: '描述', colKey: 'description', width: 200, ellipsis: true },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        cell({ row }: TableCellParams<WorkspaceRole>) {
          return (
            !(user?.admin && row.admin) && (
              <Button variant='text' theme='danger' onClick={handleDelete(row)}>
                删除
              </Button>
            )
          )
        },
      },
    ],
    [handleDelete, user?.admin]
  )

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between my-4'>
        <div className='text-tips'>所有已经分配给此用户的角色</div>
        <div>
          <Button onClick={() => setShowAdd(true)}>分配角色</Button>
        </div>
      </div>
      <Table columns={columns} rowKey='id' data={roles} loading={isLoading} empty='暂无数据' />
      {workspaceId && (
        <AssignRoleModal roles={roles} workspaceId={workspaceId} cancel={() => setShowAdd(false)} proceed={handleAdd} />
      )}
    </React.Fragment>
  )
}
