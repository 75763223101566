import React from 'react'
import sub from 'date-fns/sub'
import { localToUTC } from '@dmp/utils'
import { QueryPushRecordsReg } from '@dmp/api-sdk'
import { crowdPushStatusEnum } from '../../../constants/enums'
import { Button, Form, FormItem, Input, DateRangePicker, Select } from '@dmp/components'
import { useQuery } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { useAppSelector } from '../../../app/hooks'

export default ({ onSearch }: { onSearch: (values?: QueryPushRecordsReg) => void }) => {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const [organizationId, setOrganizationId] = React.useState<string>()

  const { data: channels } = useQuery(['queryChannelList', workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryChannelList(workspaceId)
    return res?.success ? res.data : []
  })

  const { data: organizations } = useQuery(['queryOrganizations'], async () => {
    const res = await apiClient.queryOrganizations()
    return res?.success ? res.data : []
  })

  const { data: workspaces } = useQuery(['queryWorkspaces', organizationId], async () => {
    const res = await apiClient.queryWorkspaces({ organizationId })
    return res?.success ? res.data : []
  })

  const handleSubmit = React.useCallback(
    ctx => {
      const values = formRef.current?.getFieldsValue(true) ?? {}
      onSearch({
        ...values,
        dateRange: undefined,
        startDate: localToUTC(values?.dateRange?.[0]),
        endDate: localToUTC(values?.dateRange?.[1]),
      })
    },
    [onSearch]
  )

  const handleReset = React.useCallback(
    ctx => {
      formRef.current?.reset()
      onSearch()
    },
    [onSearch]
  )

  const handleValuesChange = React.useCallback(field => {
    if (Object.prototype.hasOwnProperty.call(field, 'organizationId')) {
      setOrganizationId(field.organizationId)
      formRef.current?.setFieldsValue({ workspaceId: '' })
    }
  }, [])

  React.useEffect(() => {
    handleSubmit(null)
  }, [])

  const width = 290

  return (
    <div className='d-flex justify-content-between'>
      <Form
        ref={formRef}
        className='d-flex align-items-center flex-wrap'
        labelAlign='top'
        onSubmit={handleSubmit}
        onValuesChange={handleValuesChange}
      >
        <FormItem label='人群包ID' name='remoteSegmentId' className='mb-2 me-2'>
          <Input style={{ width }} maxlength={32} placeholder='请输入人群包ID，最多32个字符' />
        </FormItem>
        <FormItem label='人群包名称' name='segmentName' className='mb-2 me-2'>
          <Input style={{ width }} maxlength={32} placeholder='请输入人群包名称，最多32个字符' />
        </FormItem>
        <FormItem label='所属企业' name='organizationId' className='mb-2 me-2'>
          <Select
            placeholder='不限'
            options={(organizations || []) as any}
            keys={{ value: 'id', label: 'displayName' }}
            style={{ width }}
          />
        </FormItem>
        <FormItem label='所属工作空间' name='workspaceId' className='mb-2 me-2'>
          <Select
            placeholder='不限'
            options={workspaces || []}
            keys={{ value: 'id', label: 'name' }}
            style={{ width }}
          />
        </FormItem>
        <FormItem label='状态' name='status' className='mb-2 me-2'>
          <Select placeholder='不限' options={crowdPushStatusEnum.filter('record')} style={{ width }} />
        </FormItem>
        <FormItem label='所属数据源' name='channelId' className='mb-2 me-2'>
          <Select
            placeholder='不限'
            options={(channels || []) as any}
            keys={{ value: 'id', label: 'name' }}
            style={{ width }}
          />
        </FormItem>
        <FormItem
          label='推送时间'
          name='dateRange'
          className='mb-2 me-2'
          initialData={[sub(new Date(), { days: 6 }), new Date()]}
        >
          <DateRangePicker style={{ width }} />
        </FormItem>
      </Form>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button onClick={() => formRef.current?.submit()}>搜索</Button>
        <Button className='ms-2' variant='outline' onClick={handleReset}>
          重置
        </Button>
      </div>
    </div>
  )
}
