import React from 'react'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { PlatformEnum } from '@dmp/api-sdk'
import { platformEnum } from '../../../constants'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import { BidUrl } from '@dmp/api-sdk'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { Button, Drawer, Form, FormItem, Input, Select } from '@dmp/components'
import { composeBidUrl } from '../../../utils'

interface PropTypes {
  show: boolean
  onHide: (refresh?: boolean, current?: boolean) => void
  id?: number
  defaultValues?: BidUrl
}

export default (props: PropTypes) => {
  const { show, onHide, defaultValues, id } = props
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [platform, setPlatform] = React.useState<PlatformEnum | undefined>()
  const [bidUrlDomain, setBidUrlDomain] = React.useState<string>('')
  const [bidUrlPath, setBidUrlPath] = React.useState('')
  const workspaceId = useAppSelector(selectActiveWorkspace) || ''

  const createOrUpdateMutation = useMutation(
    (params: any) => {
      if (!workspaceId) return Promise.reject()
      if (id) params.id = id
      delete params.bidUrl
      params.workspaceId = workspaceId
      params.bidUrlPath = bidUrlPath
      params.bidUrlDomain = bidUrlDomain
      return apiClient.createOrUpdateBidUrlBinding(params)
    },
    {
      onSuccess: (res: any) => {
        if (res.success) {
          toast.success(id ? '编辑成功' : '新建成功')
          onHide(true, true)
        } else {
          toast.error(res.message)
        }
      },
      onError: () => {
        toast.error('系统异常')
      },
    }
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      createOrUpdateMutation.mutate(values)
    },
    [createOrUpdateMutation]
  )

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入名称' }],
      advertPlatform: [{ required: true, message: '请选择广告平台' }],
      advertPlatformRtaName: [{ required: true, message: '请输入广告平台RTA名称' }],
      advertPlatformRtaId: [{ required: true, message: '请输入广告平台RTA ID' }],
      advertPlatformRtaToken: [{ required: true, message: '请输入广告平台token' }],
      advertPlatformCustomId: [{ required: true, message: '请输入广告平台客户ID' }],
    }),
    []
  )

  const setBidUrl = React.useCallback(
    values => {
      const { advertPlatform, advertPlatformCustomId, advertPlatformRtaId } = values
      if (!bidUrlDomain) return
      let path = ''
      if (advertPlatform === PlatformEnum.Ocean && advertPlatformCustomId) {
        path = `/api/v1/rtaCallBack/json/oceanengine/${advertPlatformCustomId}`
      }
      if (advertPlatform === PlatformEnum.Tencent) {
        path = `/api/v1/rtaCallBack/json/tencent/${advertPlatformRtaId}`
      }
      setBidUrlPath(path)
      formRef.current?.setFieldsValue({ bidUrl: path ? bidUrlDomain + path : '' })
    },
    [bidUrlDomain]
  )

  const handleValuesChange = React.useCallback(
    (changeValue, allValues) => {
      const [[key, value]] = Object.entries(changeValue)
      if (key === 'advertPlatform') {
        setPlatform(value as PlatformEnum)
      }
      if (['advertPlatform', 'advertPlatformRtaId', 'advertPlatformCustomId'].includes(key)) {
        setBidUrl(allValues)
      }
    },
    [setBidUrl]
  )

  const getBidUrlDomain = React.useCallback(async () => {
    if (!workspaceId) return
    const res = await apiClient.queryBidUrlConfig({ workspaceId, type: 'domain' })
    if (!res.success) return
    setBidUrlDomain(res.data?.domain)
  }, [workspaceId])

  React.useEffect(() => {
    getBidUrlDomain()
  }, [workspaceId])

  React.useEffect(() => {
    setBidUrlPath(defaultValues?.bidUrlPath || '')
    setPlatform(defaultValues?.advertPlatform as PlatformEnum)
  }, [defaultValues])

  return (
    <Drawer
      visible={show}
      header={`${id ? '编辑' : '新建'}RTA投放绑定`}
      onClose={() => onHide()}
      confirmBtn={
        <Button onClick={() => formRef.current?.submit()} loading={createOrUpdateMutation.isLoading}>
          确定
        </Button>
      }
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit} onValuesChange={handleValuesChange}>
        <FormItem label='名称' name='name' initialData={defaultValues?.name}>
          <Input placeholder='请输入名称，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='广告平台' name='advertPlatform' initialData={defaultValues?.advertPlatform}>
          <Select options={platformEnum.list} placeholder='请选择广告平台' />
        </FormItem>
        <FormItem
          label='广告平台RTA名称'
          name='advertPlatformRtaName'
          initialData={defaultValues?.advertPlatformRtaName}
        >
          <Input maxlength={32} placeholder='请输入广告平台RTA名称，最多32个字符' />
        </FormItem>
        {platform === platformEnum.enum.Tencent && (
          <>
            <FormItem
              label='广告平台RTA ID'
              name='advertPlatformRtaId'
              initialData={defaultValues?.advertPlatformRtaId}
            >
              <Input maxlength={64} placeholder='请输入广告平台RTA ID，最多64个字符' />
            </FormItem>
            <FormItem
              label='广告平台token'
              name='advertPlatformRtaToken'
              initialData={defaultValues?.advertPlatformRtaToken}
            >
              <Input maxlength={64} placeholder='请输入广告平台token，最多64个字符' />
            </FormItem>
          </>
        )}
        {platform === platformEnum.enum.Ocean && (
          <FormItem
            label='广告平台客户ID'
            name='advertPlatformCustomId'
            initialData={defaultValues?.advertPlatformCustomId}
          >
            <Input maxlength={64} placeholder='请输入广告平台客户ID，最多64个字符' />
          </FormItem>
        )}
        <FormItem label='BidUrl' name='bidUrl' initialData={composeBidUrl(defaultValues)}>
          <Input maxlength={256} placeholder='请输入BidUrl，最多256个字符' readonly />
        </FormItem>
      </Form>
    </Drawer>
  )
}
