import React from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { TrialApplicationDetailPage } from './TrialApplicationDetailPage'
import { TrialApplicationListPage } from './TrialApplicationListPage'

export const TrialApplicationPage: React.FC = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const match = useRouteMatch()

  if (workspaceId) {
    return (
      <>
        <Switch>
          {/* <Route path={`${match.path}/create`} exact render={() => <SegmentPageCreate />} /> */}
          <Route path={`${match.path}/list`} render={() => <TrialApplicationListPage workspaceId={workspaceId} />} />
          <Route path={`${match.path}/read/:segmentId`} render={() => <TrialApplicationDetailPage />} />
          <Route path={`${match.path}`} render={() => <Redirect to={`${match.url}/list`} />} />
        </Switch>
      </>
    )
  }

  return (
    <>
      <div className='layout-content'>
        <p className='text-danger'>
          路由错误，请在路径中提供 <code>workspaceId</code> 参数。
        </p>
      </div>
    </>
  )
}
