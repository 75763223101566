import React from 'react'
import { useMutation } from 'react-query'
import { apiClient } from '../../utils/apiClient'
import { platformEnum, deviceTypeEnum } from '../../constants/enums'
import { toast } from 'react-toastify'
import { CreatePushCrowdPackReq, PlatformEnum } from '@dmp/api-sdk'
import { useAppSelector } from '../../app/hooks'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { Button, Drawer, Form, FormItem, Icon, Input, Select, Textarea } from '@dmp/components'

export interface NewCrowdPushFormValuesType extends Omit<CreatePushCrowdPackReq, 'workspaceId'> {
  id?: number
}

interface PropTypes {
  show: boolean
  onHide: (refresh?: boolean, current?: boolean) => void
  segments: any[]
  defaultValues?: NewCrowdPushFormValuesType
}

export default (props: PropTypes) => {
  const { show, onHide, defaultValues, segments } = props

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const adAccountMutation = useMutation(async (oauthId: number) => {
    if (!oauthId) return []
    const res = await apiClient.queryAdAccountList({ oauthId })
    return res?.success ? res?.data?.map(it => ({ value: it, label: it })) : []
  })

  const pushMutation = useMutation(
    (params: NewCrowdPushFormValuesType): any => {
      if (!workspaceId) return
      const { segmentId, oauthId, ...rest } = params
      const reqData = {
        workspaceId,
        segmentId: Number(segmentId),
        oauthId: Number(oauthId),
        ...rest,
      }
      return defaultValues?.id
        ? apiClient.updatePushCrowdPack({
            id: defaultValues.id,
            ...reqData,
          })
        : apiClient.createPushCrowdPack(reqData)
    },
    {
      onSuccess: (res: any) => {
        if (!res.success) {
          toast.error(res.message)
          return
        } else {
          toast.success('操作成功，系统正在审核中，请耐心等待!')
          onHide(true, true)
        }
      },
      onError: () => {
        toast.error('系统异常')
      },
    }
  )

  const authAcctsMutation = useMutation(async (platform: PlatformEnum) => {
    if (!(workspaceId && platform)) return
    const res = await apiClient.queryAdvAuthAccountList({
      workspaceId,
      status: 'SUCCESS',
      advertPlatform: platform,
    })
    return res.success ? res.data || [] : []
  })

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      pushMutation.mutate(values)
    },
    [pushMutation]
  )

  const handlePlatformChange = React.useCallback(
    val => {
      formRef.current?.setFieldsValue({ oauthId: '', advertiserAccountId: '' })
      authAcctsMutation.mutate(val)
    },
    [authAcctsMutation]
  )

  const handleOauthIdChange = React.useCallback(
    val => {
      formRef.current?.setFieldsValue({ advertiserAccountId: '' })
      adAccountMutation.mutate(val)
    },
    [adAccountMutation]
  )

  const rules = React.useMemo(
    () => ({
      segmentId: [{ required: true, message: '请选择人群包' }],
      advertPlatform: [{ required: true, message: '请选择推送广告平台' }],
      oauthId: [{ required: true, message: '请选择已授权账号' }],
      deviceType: [{ required: true, message: '请选择推送设备类型' }],
      advertiserAccountId: [{ required: true, message: '请填写需要推送的广告主账号ID' }],
      advertPlatformSegmentName: [{ required: true, message: '请填写广告平台人群包命名' }],
      advertPlatformSegmentRemark: [{ required: true, message: '请填写广告平台人群包描述' }],
    }),
    []
  )

  React.useEffect(() => {
    if (defaultValues) {
      adAccountMutation.mutate(defaultValues?.oauthId)
      authAcctsMutation.mutate(defaultValues?.advertPlatform)
    }
  }, [])

  return (
    <Drawer
      header={`${defaultValues?.id ? '编辑' : '新建'}人群包推送`}
      visible={show}
      onClose={() => onHide()}
      confirmBtn={
        <Button loading={pushMutation.isLoading} onClick={() => formRef.current?.submit()}>
          开始推送
        </Button>
      }
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='选择人群包' name='segmentId' initialData={defaultValues?.segmentId}>
          <Select placeholder='请选择人群包' options={segments} keys={{ value: 'id', label: 'name' }} />
        </FormItem>
        <FormItem label='选择推送广告平台' name='advertPlatform' initialData={defaultValues?.advertPlatform}>
          <Select onChange={handlePlatformChange} options={platformEnum.list} placeholder='选择推送广告平台' />
        </FormItem>
        <FormItem label='推送设备类型' name='deviceType' initialData={defaultValues?.deviceType}>
          <Select options={deviceTypeEnum.list} placeholder='请选择推送设备类型' />
        </FormItem>
        <FormItem label='选择已授权账号' name='oauthId' initialData={defaultValues?.oauthId}>
          <Select placeholder='请选择已授权账号' onChange={handleOauthIdChange}>
            {(authAcctsMutation?.data || []).map((it: any) => (
              <Select.Option key={it.id} value={it.id} label={it.accountName}>
                {it.accountName}({it.advertPlatformAccountId})
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label='选择需要推送的广告主账号'
          name='advertiserAccountId'
          initialData={defaultValues?.advertiserAccountId}
        >
          <Select
            filterable
            creatable
            placeholder='请选择需要推送的广告主账号'
            options={adAccountMutation?.data || []}
          />
        </FormItem>
        <FormItem
          label='填写广告平台人群包命名'
          name='advertPlatformSegmentName'
          initialData={defaultValues?.advertPlatformSegmentName}
        >
          <Input placeholder='请填写广告平台人群包命名，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem
          label='填写广告平台人群包描述'
          name='advertPlatformSegmentRemark'
          initialData={defaultValues?.advertPlatformSegmentRemark}
        >
          <Textarea maxlength={256} placeholder='请填写广告平台人群包描述，最多256个字符' />
        </FormItem>
      </Form>
    </Drawer>
  )
}
