import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useExpand, useThrottledEffect } from '../../hooks'
import { apiClient } from '../../utils/apiClient'
import { useRBAC } from '../../features/global/hooks'
import { loadSegments } from '../../features/segment'
import ListFilterPane from './components/ListFilterPane'
import { selectPage } from '../../features/segment/segmentSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { WorkspacePageLayout, Module } from '../../layout/PageLayout'
import { FilterParam } from './components/ListFilterPane/ListFilterPane'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { UTCToLocal, localToUTC, digitization } from '@dmp/utils'
import {
  segmentStatusEnum,
  segmentRealTimeStatusEnum,
  segmentSourceEnum,
  segmentTypeEnum,
  systemAbilityEnum,
} from '../../constants'
import { Table, Button, dialog, TableColumnType } from '@dmp/components'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import CreateSegmentDialog from './components/CreateSegmentDialog/CreateSegmentDialog'
import { SegmentResource, SegmentCallStatusType, UpdateSegmentCallStatusReq, SegmentStatusEnum } from '@dmp/api-sdk'
import { selectWorkspaceChannel, selectWorkspaceQuota } from '../../features/workspace/workspaceSlice'
import WsQuotaLine from '../../components/WsQuotaLine'
import StatusLabel from '../../components/StatusLabel'

const renderStatus = (c: SegmentResource) => {
  const obj = segmentStatusEnum.map.get(c.status)
  const error = c.status === SegmentStatusEnum.Refused ? c.approveReason : ''
  return obj ? (
    <StatusLabel color={obj.color} label={obj.label} error={error} />
  ) : (
    <StatusLabel color='warning' label='未知状态' />
  )
}

const renderRealTimeStatus = (c: SegmentResource) => {
  const obj = segmentRealTimeStatusEnum.map.get(c.callStatus)
  return obj ? <StatusLabel color={obj.color} label={obj.label} /> : <StatusLabel color='warning' label='未知状态' />
}

enum Actions {
  Insight = 'insight',
  Expand = 'expand',
  Publish = 'publish',
  Revoke = 'revoke',
  Release = 'release',
}

const defaultSortStr = 'createdAt,DESC'

export const SegmentList: React.FC = () => {
  const his = useHistory()
  const match = useRouteMatch()
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { hasPermission } = useRBAC()
  const segments = useAppSelector(selectPage)
  const { expand, expandIcon } = useExpand()
  const wsQuotaResult = useAppSelector(selectWorkspaceQuota)
  const activeWorkspace = useAppSelector(selectActiveWorkspace)
  const workspaceChannel = useAppSelector(selectWorkspaceChannel)

  const { status, segmentsTotal } = useAppSelector(state => state.segment)
  const { pSort = defaultSortStr, pSize = '10', pPage = '1' } = qs.parse(search)
  const [showCreationDialog, setCreationDialog] = React.useState(false)
  const [filter, setFilter] = React.useState<FilterParam | undefined>(undefined)

  const [realSegmentQuota, setRealSegmentQuota] = React.useState<{ total: number; rest: number }>({
    total: 0,
    rest: 0,
  })

  const loadSegmentList = React.useCallback(() => {
    if (!activeWorkspace) {
      return
    }

    const arg: any = {
      workspaceId: activeWorkspace,
      sorts: pSort ? [pSort] : undefined,
      page: Number(pPage),
      size: Number(pSize),
    }
    if (filter) {
      const { segmentName, segmentType, segmentStatus, createdRangeFrom, createdRangeTo, callStatus } = filter
      arg.search = {
        segmentName,
        segmentStatus,
        segmentType,
        callStatus,
        createdAtFrom: localToUTC(createdRangeFrom),
        createdAtTo: localToUTC(createdRangeTo),
      }
    }
    dispatch(loadSegments(arg))
  }, [activeWorkspace, filter, pPage, pSize, pSort, dispatch])

  const handlePaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      const searchParams = qs.parse(search)
      const newSearch = qs.stringify({
        ...searchParams,
        pPage: current,
        pSize: pageSize,
      })
      his.push(`${match.url}?${newSearch}`)
    },
    [his, match.url, search]
  )

  const handleReset = React.useCallback(() => {
    setFilter(undefined)
    handlePaginationChange({ current: 1, pageSize: 10 })
  }, [handlePaginationChange])

  const handleSearch = React.useCallback(
    (params: FilterParam) => {
      setFilter(params)
      handlePaginationChange({ current: 1, pageSize: 10 })
    },
    [handlePaginationChange]
  )

  const toggleCreateModal = React.useCallback(() => setCreationDialog(s => !s), [])

  const renderEmptyListBody = () => {
    if (filter) {
      return '无符合搜索条件的人群'
    }
    return (
      <div>
        还没有任何人群，
        <Button variant='text' theme='primary' onClick={toggleCreateModal}>
          点击开始新建
        </Button>
      </div>
    )
  }

  const getSegmentRealTimeQuota = React.useCallback(async () => {
    if (!activeWorkspace) return
    const res = await apiClient.querySegmentRealTimeQuota({ workspaceId: activeWorkspace })
    if (!res?.success) return
    const { totalQuotaCount: total, currentQuotaCount: current } = res.data || {}
    const rest = total - current
    setRealSegmentQuota({ total: total || 0, rest: isNaN(rest) || rest < 0 ? 0 : rest })
  }, [activeWorkspace])

  const updateSegmentCallStatus = React.useCallback(
    async (
      c: SegmentResource,
      type: Actions.Publish /* 发布 */ | Actions.Release /* 释放 */ | Actions.Revoke /* 撤销 */
    ) => {
      const { tip, action } = {
        publish: {
          tip: `一个工作空间内最多允许同时存在${realSegmentQuota?.total || 8}个可调用的人群包，确认发布吗?`,
          action: 'PROCESS_SUCCESS',
        },
        revoke: {
          tip: '确认撤销？',
          action: 'REVOKE',
        },
        release: {
          tip: '释放的人群包不可进行实时接口调用，确认释放？',
          action: 'NOT_AVAILABLE',
        },
      }[type]
      if (!(activeWorkspace && c.id && action && tip)) return
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: tip,
        async onConfirm({ hide }) {
          const res = await apiClient.updateSegmentCallStatus({
            workspaceId: activeWorkspace,
            segmentId: String(c.id),
            callStatus: action as UpdateSegmentCallStatusReq['callStatus'],
          })
          if (res?.success) {
            hide?.()
            toast.success('操作成功')
            loadSegmentList()
            getSegmentRealTimeQuota()
          } else {
            toast.error(res.message || '操作失败')
          }
        },
      })
    },
    [activeWorkspace, getSegmentRealTimeQuota, loadSegmentList, realSegmentQuota?.total]
  )

  const canExpand = React.useCallback(
    (c: SegmentResource) => {
      return (
        hasPermission({ permission: 'expand:segments' }) && // 有人群拓展权限
        ['LABEL', 'SELF', 'INDIVIDUATION'].includes(c.type) && // 人群类型为多维标签人群或者自有人群
        c.status === segmentStatusEnum.enum.Success // 人群包状态为处理完成
      )
    },
    [hasPermission]
  )

  const canPublish = React.useCallback(
    (c: SegmentResource) => {
      return (
        systemAbilityEnum.hasAbility('RealTimeCall', c?.channel?.name) && // 人群包数据源是否支持
        hasPermission({ permission: 'publishCallStatus:segments' }) && // 操作权限
        c.status === 'SUCCESS' && // 人群包处理完成
        realSegmentQuota?.rest > 0 && // 系统实时处理配额充足
        [SegmentCallStatusType.Unavailable, SegmentCallStatusType.ProcessingFailed].includes(c.callStatus) // 实时调用状态为不可用或发布失败
      )
    },
    [hasPermission, realSegmentQuota?.rest]
  )

  const canRevoke = React.useCallback(
    (c: SegmentResource) => {
      return (
        systemAbilityEnum.hasAbility('RealTimeCall', c?.channel?.name) && // 人群包数据源是否支持
        hasPermission({ permission: 'revokeCallStatus:segments' }) && // 操作权限
        c.callStatus === SegmentCallStatusType.ProcessingFailed // 实时调用状态为发布失败
      )
    },
    [hasPermission]
  )

  const canRelease = React.useCallback(
    (c: SegmentResource) => {
      return (
        systemAbilityEnum.hasAbility('RealTimeCall', c?.channel?.name) && // 人群包数据源是否支持
        hasPermission({ permission: 'releaseCallStatus:segments' }) && // 操作权限
        c.callStatus === SegmentCallStatusType.Available // 实时调用状态为可用
      )
    },
    [hasPermission]
  )

  const canInsight = React.useCallback(
    (c: SegmentResource) => {
      return (
        hasPermission({ permission: 'read:segment_portrait' }) && // 操作权限
        c.type === segmentTypeEnum.enum.Label && // 数据组仅支持多维标签人群的洞察
        c.status === segmentStatusEnum.enum.Success // 人群包状态为处理完成
      )
    },
    [hasPermission]
  )

  const parseSort = React.useCallback((str: string = defaultSortStr) => {
    const arr = str.split(',')
    const obj: any = {
      sortBy: arr[0],
    }
    if (arr[1] === 'DESC') obj.descending = true
    return obj
  }, [])

  const handleMoreAction = React.useCallback(
    (menu, row: SegmentResource) => {
      switch (menu?.value) {
        case Actions.Insight:
          his.push(`insight/${row.id}`)
          break
        case Actions.Expand:
          his.push(`create?type=lookalike&seed=${row.id}`)
          break
        case Actions.Publish:
          updateSegmentCallStatus(row, menu.value)
          break
        case Actions.Revoke:
          updateSegmentCallStatus(row, menu.value)
          break
        case Actions.Release:
          updateSegmentCallStatus(row, menu.value)
          break
      }
    },
    [his, updateSegmentCallStatus]
  )

  const renderActions = React.useCallback(
    row => {
      const actions: any[] = [
        {
          value: Actions.Insight,
          content: '洞察',
          visible: canInsight(row),
        },
        {
          value: Actions.Expand,
          content: '拓展',
          visible: canExpand(row),
        },
        {
          value: Actions.Publish,
          visible: canPublish(row),
          content: '发布',
        },
        {
          value: Actions.Revoke,
          visible: canRevoke(row),
          content: '撤销',
        },
        {
          value: Actions.Release,
          visible: canRelease(row),
          content: '释放',
        },
      ].filter(it => it.visible)
      return (
        <>
          {actions?.map(it => (
            <Button
              variant='text'
              theme='primary'
              className='me-3'
              key={it.content}
              content={it.content}
              onClick={() => handleMoreAction(it, row)}
            />
          ))}
        </>
      )
    },
    [canExpand, canInsight, canPublish, canRelease, canRevoke, handleMoreAction]
  )

  const handleSortChange = React.useCallback(
    sort => {
      const { sortBy, descending } = sort ?? parseSort(defaultSortStr)
      const sortStr = `${sortBy},${descending ? 'DESC' : 'ASC'}`
      const searchParams = qs.parse(search)
      const newSearch = qs.stringify({
        ...searchParams,
        pPage: 1,
        pSort: sortStr,
      })
      his.push(`${match.url}?${newSearch}`)
    },
    [his, match.url, parseSort, search]
  )

  const canCreate = hasPermission({ permission: 'create:segments' })

  const headerTop = (
    <WsQuotaLine
      list={[
        { label: '已有人群数量', value: wsQuotaResult?.SEGMENT_QUOTA?.useCount },
        { label: '剩余人群数量', value: wsQuotaResult?.SEGMENT_QUOTA?.surplusCount },
        {
          label: '实时已用数量',
          value: wsQuotaResult?.SEGMENT_CALL_STATUS_SUCCESS_QUOTA?.useCount,
          hidden: !systemAbilityEnum.hasAbility('RealTimeCall', workspaceChannel?.name),
        },
        {
          label: '剩余实时数量',
          value: wsQuotaResult?.SEGMENT_CALL_STATUS_SUCCESS_QUOTA?.surplusCount,
          hidden: !systemAbilityEnum.hasAbility('RealTimeCall', workspaceChannel?.name),
        },
      ]}
    />
  )
  const headerRight = (
    <>
      {canCreate && (
        <Button icon={<i className='iconfont icon-add-select' />} onClick={toggleCreateModal}>
          新建人群
        </Button>
      )}
      <span className='ms-3'>{expandIcon}</span>
    </>
  )

  const columns = React.useMemo<TableColumnType<SegmentResource>[]>(() => {
    return [
      {
        title: '人群名称',
        width: 200,
        colKey: 'name',
        sorter: true,
        ellipsis: true,
        cell: ({ row }) => {
          // 人群包数据源与当前工作空间的数据源不一致，不可进入详情
          if (row?.channel?.id !== workspaceChannel?.id) return row.name
          return <Link to={`read/${row.id}`}>{row.name}</Link>
        },
      },
      {
        title: '来源',
        colKey: 'source',
        width: 150,
        cell: ({ row }) => segmentSourceEnum.map.get(row.source)?.label,
      },
      {
        title: '类型',
        colKey: 'type',
        width: 150,
        cell: ({ row }) => segmentTypeEnum.map.get(row.type)?.label || '其他',
      },
      {
        title: '人群包状态',
        colKey: 'status',
        width: 150,
        cell: ({ row }) => renderStatus(row),
      },
      {
        title: '实时调用状态',
        colKey: 'callStatus',
        width: 150,
        hidden: !systemAbilityEnum.hasAbility('RealTimeCall', workspaceChannel?.name),
        cell: ({ row }) => renderRealTimeStatus(row),
      },
      {
        title: '可用人数',
        colKey: 'velocity',
        sorter: true,
        width: 150,
        cell: ({ row }) => digitization(row.velocity),
      },
      {
        title: '创建时间',
        colKey: 'createdAt',
        sorter: true,
        width: 200,
        cell: ({ row }) => {
          return UTCToLocal(row.createdAt)
        },
      },
      {
        title: '操作',
        width: 180,
        colKey: 'action',
        fixed: 'right',
        cell: ({ row }) => {
          // 人群包数据源与当前工作空间的数据源不一致，所有操作按钮隐藏
          if (row?.channel?.id !== workspaceChannel?.id) return
          return renderActions(row)
        },
      },
    ]
  }, [renderActions, workspaceChannel?.id, workspaceChannel?.name])

  React.useEffect(() => {
    getSegmentRealTimeQuota()
  }, [])

  useThrottledEffect(() => {
    loadSegmentList()
  }, [pPage, pSize, pSort, activeWorkspace, filter])

  return (
    <WorkspacePageLayout title='我的人群' headerRight={headerRight} headerTop={headerTop}>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <ListFilterPane onSearch={handleSearch} onReset={handleReset} />
      </Module>
      <Module>
        <Table
          data={segments || []}
          rowKey='id'
          empty={renderEmptyListBody()}
          onSortChange={handleSortChange}
          sort={parseSort(pSort as string)}
          loading={status === 'loading'}
          pagination={{
            total: segmentsTotal,
            current: Number(pPage),
            pageSize: Number(pSize),
            onChange: handlePaginationChange,
          }}
          columns={columns}
        />
      </Module>
      <CreateSegmentDialog show={showCreationDialog} onClose={() => setCreationDialog(false)} />
    </WorkspacePageLayout>
  )
}
