import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { CancelToken } from 'axios'
import { RegionResource } from '../../shared/models'

import { regions as regionsFallback } from '../../shared/mockdata'

// read from worker
const doLoadRegions = async (cancelToken: CancelToken) => {
  const url = 'https://dmp-mock-data.haibxz.workers.dev/api/regions'
  return axios
    .get<{ success: boolean; data: RegionResource[] }>(url, {
      withCredentials: false,
      timeout: 1000,
      cancelToken,
    })
    .then(resp => resp.data)
    .then(resp => {
      if (resp.success) {
        return resp.data
      }
      return []
    })
}

export const loadRegions = createAsyncThunk('segment/loadRegions', async (a_, { signal }) => {
  try {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    return await doLoadRegions(source.token)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return [...regionsFallback] // 兜底数据
})
