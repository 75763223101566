import React from 'react'
import { Table, TableCellParams } from '@dmp/components'
import { useRecoilState } from 'recoil'
import { useQuery } from 'react-query'
import authEmpty from '../../../assets/empty/auth_empty.png'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { UserPermission } from '@dmp/api-sdk'
import { apiClient } from '../../../utils/apiClient'
import { localDetailContext } from '../context'
import { userPermissionsAtom } from '../../../recoil/user'

export const PermissionTab: React.FC = () => {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const { user } = React.useContext(localDetailContext)
  const [permissions, setPermissions] = useRecoilState(userPermissionsAtom)

  const { isLoading } = useQuery(
    ['userPermissions', workspaceId, user?.id || ''],
    () => {
      const userId = user && user.id
      if (workspaceId && userId) {
        return apiClient.listMemberPermissions({ workspaceId, userId })
      }
      return Promise.reject()
    },
    {
      staleTime: 30000, // 缓存 30s
      onSuccess: resp => {
        if (resp?.success && resp.data) {
          setPermissions(resp.data)
        }
      },
    }
  )

  const assignment = (p: UserPermission) => {
    if (!p.sources) {
      return 'UNKNOWN'
    }
    // 优先直接分配
    const src = p.sources.find(s => s.sourceType === 'DIRECT')
    if (src) {
      return '直接分配'
    }

    const size = p.sources.filter(s => s.sourceType === 'ROLE').length
    return <span title={`来自 ${size} 个角色的授权`}>角色</span>
  }

  const columns = React.useMemo(
    () => [
      { title: '名称', colKey: 'name' },
      { title: '描述', colKey: 'description', maxWidth: 200, ellipsis: true },
      { title: '资源', colKey: 'resourceIdentifier' },
      {
        title: '来源',
        colKey: 'source',
        cell({ row }: TableCellParams<UserPermission>) {
          return assignment(row)
        },
      },
    ],
    []
  )
  return (
    <>
      <div className='d-flex justify-content-between my-4'>
        <div className='text-tips'>用户拥有的权限列表</div>
      </div>
      <Table
        columns={columns}
        rowKey='id'
        data={permissions}
        loading={isLoading}
        empty={
          <>
            <img className='mb-2' src={authEmpty} style={{ width: 100, height: 100 }} />
            <div>暂无任何权限</div>
          </>
        }
        emptyIcon={false}
      />
    </>
  )
}
