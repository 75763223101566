import { WorkspaceMember } from '@dmp/api-sdk'
import { atom } from 'recoil'

export enum EventNames {
  ShowDisplayNameEditor = 'showDisplayNameEditor',
  ShowEmailEditor = 'showEmailEditor',
  DeleteUserAction = 'deleteUserAction',
}

export const membersAtom = atom<WorkspaceMember[]>({
  key: 'membersAtom',
  default: [],
})

export const activeMemberAtom = atom<WorkspaceMember | undefined>({
  key: 'activeMemberAtom',
  default: undefined,
})
