import { noop } from '@dmp/utils'
import React, { useCallback, useContext } from 'react'
import { createTracker, Tracker, AttributeType } from '@dmp/tracker'
import { trackerDebug } from '../constants'

const emptyTracker = createTracker({ type: 'none', debug: trackerDebug })

const trackerContext = React.createContext<{
  track: (event: string, attributes?: Record<string, AttributeType>) => void
  identify: (uniqueId?: string) => void
}>({
  track: noop,
  identify: noop,
})

export const useTracker = () => {
  const { track, identify } = useContext(trackerContext)
  return { track, identify }
}

export const TrackerProvier: React.FC<{ tracker?: Tracker }> = ({ children, tracker = emptyTracker }) => {
  const handleTrack = useCallback(
    (event: string, attributes?: Record<string, AttributeType>) => {
      tracker?.track(event, attributes)
    },
    [tracker]
  )
  const handleIdentify = useCallback(
    (uniqueId?: string) => {
      tracker?.identify(uniqueId)
    },
    [tracker]
  )
  return (
    <trackerContext.Provider value={{ track: handleTrack, identify: handleIdentify }}>
      {children}
    </trackerContext.Provider>
  )
}
