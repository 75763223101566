import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { UTCToLocal } from '@dmp/utils'
import { DataSource } from '@dmp/api-sdk'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import AssignDataSourceModal from './AssignDataSourceModal'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { Table, Button, TableCellParams } from '@dmp/components'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { useRBAC } from '../../../features/global/hooks'

export default () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const [show, setShow] = React.useState(false)
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const [editInfo, setEditInfo] = React.useState<DataSource>()
  const { page = '1', size = '10' } = qs.parse(location.search)

  const { isLoading, data, refetch } = useQuery(['queryDataSourceList', page, size, workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryDataSourceListPage({
      workspaceId,
      page: Number(page) - 1,
      size: Number(size),
      sort: '',
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const handleAssignDataSource = React.useCallback(record => {
    setEditInfo(record)
    setShow(true)
  }, [])

  const handleHideModal = React.useCallback(
    (isRefresh?: boolean) => {
      if (isRefresh) refetch()
      setShow(false)
      setEditInfo(undefined)
    },
    [setShow, refetch]
  )

  const columns = React.useMemo(
    () => [
      { title: '公司名称', colKey: 'displayName', width: 200, ellipsis: true },
      {
        title: '企业管理员账号',
        ellipsis: true,
        colKey: 'username',
        width: 150,
        cell({ row }: TableCellParams<DataSource>) {
          return row?.adminUser?.username
        },
      },
      {
        title: '数据源',
        width: 150,
        colKey: 'channel',
        ellipsis: true,
        cell({ row }: TableCellParams<DataSource>) {
          return row?.channel?.name
        },
      },
      {
        title: '创建时间',
        colKey: 'createdAt',
        width: 200,
        cell({ row }: TableCellParams<DataSource>) {
          return UTCToLocal(row.createdAt)
        },
      },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        fixed: 'right' as const,
        cell: ({ row }: TableCellParams<DataSource>) => {
          return (
            hasPermission({ permission: 'change:organ_data_source_channel' }) && (
              <Button variant='text' theme='primary' onClick={() => handleAssignDataSource(row)}>
                编辑
              </Button>
            )
          )
        },
      },
    ],
    [handleAssignDataSource, hasPermission]
  )

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?page=${current}&size=${pageSize}`)
    },
    [match, history]
  )

  return (
    <WorkspacePageLayout title='数据源管理'>
      <Module>
        <Table
          columns={columns}
          data={data?.items || []}
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: data?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
      <AssignDataSourceModal show={show} onHide={handleHideModal} defaultValues={editInfo} />
    </WorkspacePageLayout>
  )
}
