import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router'
import AdvAccountAuthCallback from './AdvAccountAuthCallback'

export default () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/auth`} exact render={() => <AdvAccountAuthCallback />} />
    </Switch>
  )
}
