import React from 'react'
import Avatar from 'react-avatar'
import { noop } from '@dmp/utils'
import { toast } from 'react-toastify'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import { useRecoilState, useRecoilValue } from 'recoil'
import { stdRequestStaleTimeInMs } from '../../../constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { findWorkspaceRole, roleUsersAtom } from '../../../recoil/role'
import { WorkspaceMember, WorkspaceRole, WorkspaceRoleUser } from '@dmp/api-sdk'
import { selectActiveWorkspace, selectSession } from '../../../features/global/globalSlice'
import { dialog, Button, Table, TableCellParams, Dialog, Form, FormRule, FormItem, Select } from '@dmp/components'

const localContext = React.createContext<{
  role: WorkspaceRole | undefined
  users: WorkspaceRoleUser[]
  workspaceId: string | undefined
}>({ role: undefined, users: [], workspaceId: undefined })

const NewAssignmentModal: React.FC<{
  excludes?: Array<{ userId: number }>
  show?: boolean
  cancel?: () => void
  proceed?: (ids: number[]) => void
}> = ({ show = false, cancel = noop, proceed = noop, excludes = [] }) => {
  const { role, workspaceId } = React.useContext(localContext)
  const formRef = React.useRef<HTMLFormElement>(null)
  const [members, setMembers] = React.useState<WorkspaceMember[]>([])

  React.useEffect(() => {
    if (!show || !workspaceId) {
      return
    }
    const load = async () => {
      const resp = await apiClient.listWorkspaceMembers({ workspaceId })
      if (resp.success && resp.data) {
        setMembers([...resp.data])
      }
    }
    load()
  }, [show, workspaceId])

  const visibleMembers =
    excludes.length === 0 ? members : members.filter(m => !excludes.some(exc => exc.userId === m.userId))

  const rules = React.useMemo(
    () => ({
      userId: [{ required: true, message: '请选择用户' }],
    }),
    []
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      proceed([values.userId])
    },
    [proceed]
  )

  return (
    <Dialog
      visible={show}
      header={`分配角色 “${role?.name}” 给工作空间成员`}
      onClose={cancel}
      onConfirm={() => formRef.current?.submit()}
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='选择一个你想要分配的用户' name='userId'>
          <Select placeholder='请选择用户'>
            {(visibleMembers || []).map(m => (
              <Select.Option key={m.userId} value={m.userId} label={m.displayName}>
                {m.displayName} ({m.email})
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Dialog>
  )
}

interface UsersMutationParams {
  workspaceId: string
  userId: number
  roleId: number | string
}

type UsersMutation =
  | {
      type: 'add'
      hide?: () => void
      payload: UsersMutationParams
    }
  | {
      type: 'del'
      hide?: () => void
      payload: UsersMutationParams
    }

// TODO: finish it. 剩余添加 user
export const UsersTab: React.FC<{ roleId: string | number }> = ({ roleId }) => {
  const [showAssign, setShowAssign] = React.useState(false)
  const [users, setUsers] = useRecoilState(roleUsersAtom)
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const role = useRecoilValue(findWorkspaceRole({ roleId }))
  const session = useAppSelector(selectSession)

  const queryClient = useQueryClient()
  const { isLoading } = useQuery(
    ['roleUsers', workspaceId, roleId],
    () => {
      if (!workspaceId || !roleId) {
        return Promise.reject()
      }
      return apiClient.listWorkspaceRoleUsers({ workspaceId, roleId })
    },
    {
      staleTime: stdRequestStaleTimeInMs,
      onSuccess: resp => {
        if (resp.success && resp.data) {
          setUsers([...resp.data])
        } else {
          toast.error('读取被授权用户列表出错')
        }
      },
    }
  )

  const mutation = useMutation(
    (mu: UsersMutation) => {
      if (mu.type === 'del') {
        return apiClient.revokeRolesFromMember({
          workspaceId: mu.payload.workspaceId,
          userId: mu.payload.userId,
          roles: [mu.payload.roleId],
        })
      }
      if (mu.type === 'add') {
        return apiClient.assignRolesToMember({
          workspaceId: mu.payload.workspaceId,
          userId: mu.payload.userId,
          roles: [mu.payload.roleId],
        })
      }
      return Promise.reject()
    },
    {
      onSuccess: (_, mu) => {
        if (mu.type === 'del') {
          toast.success(`移除用户成功`)
          mu?.hide?.()
          queryClient.invalidateQueries(['roleUsers', workspaceId, mu.payload.roleId])
        }
        if (mu.type === 'add') {
          toast.success(`添加用户成功`)
          setShowAssign(false)
          queryClient.invalidateQueries(['roleUsers', workspaceId, mu.payload.roleId])
        }
      },
    }
  )

  const handleDeleteItem = React.useCallback(
    (userId: number, name: string) => {
      return () => {
        if (!workspaceId || !roleId) return
        dialog({
          icon: <i className='iconfont icon-warning-fill text-warning' />,
          body: `确认移除对 "${name}" 的 "${role?.name}" 角色的授权吗？`,
          onConfirm({ hide }) {
            mutation.mutate({
              hide,
              type: 'del',
              payload: {
                workspaceId,
                userId,
                roleId,
              },
            })
          },
        })
      }
    },
    [role, mutation, roleId, workspaceId]
  )

  const handleAddItems = React.useCallback(
    (userIds: number[]) => {
      if (!workspaceId || !roleId || userIds.length === 0) {
        return
      }
      const [userId] = userIds
      mutation.mutate({
        type: 'add',
        payload: {
          workspaceId,
          userId,
          roleId,
        },
      })
    },
    [mutation, roleId, workspaceId]
  )

  const columns = React.useMemo(
    () => [
      {
        title: '名称',
        colKey: 'name',
        cell({ row }: TableCellParams<WorkspaceRoleUser>) {
          return (
            <div className='d-flex justify-content-begin align-items-center'>
              <div>
                <Avatar size='36' textSizeRatio={1.5} name={row.displayName} round='50%' />
              </div>
              <div className='px-3'>
                <div className='h6 mb-0'>{row.displayName}</div>
                <div className='text-muted'>{row.email}</div>
              </div>
            </div>
          )
        },
      },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        cell({ row }: TableCellParams<WorkspaceRoleUser>) {
          return (
            session?.user?.id !== row.userId &&
            !row?.admin && (
              <Button variant='text' theme='danger' onClick={handleDeleteItem(row.userId, row.displayName)}>
                删除
              </Button>
            )
          )
        },
      },
    ],
    [handleDeleteItem, session?.user?.id]
  )

  return (
    <localContext.Provider value={{ role, users, workspaceId }}>
      <div className='d-flex justify-content-between my-4'>
        <div className='text-tips'>所有由此角色直接分配的用户</div>
        <div>
          <Button onClick={() => setShowAssign(true)}>添加用户</Button>
        </div>
      </div>
      <Table columns={columns} rowKey='id' data={users} loading={isLoading} empty='还没有任何用户被分配此角色' />

      <NewAssignmentModal
        excludes={users.map(u => ({ userId: u.userId }))}
        show={showAssign}
        cancel={() => setShowAssign(false)}
        proceed={handleAddItems}
      />
    </localContext.Provider>
  )
}
