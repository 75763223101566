import React from 'react'
import qs from 'query-string'
import { Radio } from '@dmp/components'
import { platformEnum } from '../constants'
import { useHistory, useLocation } from 'react-router'
import { PlatformEnum } from '@dmp/api-sdk'

export const usePlatform = (props?: { exclude: PlatformEnum[] }) => {
  const history = useHistory()
  const location = useLocation()
  const search = qs.parse(location.search)
  const handleChange = React.useCallback(
    val => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: 1, platform: val })}`)
    },
    [history, location.pathname, search]
  )
  const platforms = platformEnum.list?.filter(it => !props?.exclude?.includes(it.value))

  const platform: any = search?.platform ?? platforms?.[0].value

  return {
    platform,
    platformRadioGroup: (
      <Radio.Group value={platform} onChange={handleChange}>
        {platforms.map(it => (
          <Radio.Button value={it.value} key={it.value}>
            <img src={it.icon} className='me-2' style={{ width: 17, height: 15 }} />
            <span className='align-middle'>{it.label}</span>
          </Radio.Button>
        ))}
      </Radio.Group>
    ),
  }
}
