import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import Filters from './Filters'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { useExpand } from '../../../hooks'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import { useRBAC } from '../../../features/global/hooks'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { CrmPersonalSegmentResource, QueryPersonalSegmentListReg, SegmentStatusEnum } from '@dmp/api-sdk'
import { personalSegmentStatusEnum } from '../../../constants'
import { Table, Button, TableColumnType } from '@dmp/components'
import StatusLabel from '../../../components/StatusLabel'

const Page: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const { expand, expandIcon } = useExpand()
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const [filters, setFilters] = React.useState<QueryPersonalSegmentListReg>()
  const { page = '1', size = '10' } = qs.parse(location.search)

  const {
    isLoading,
    refetch,
    data: crowdPackData,
  } = useQuery(['queryPersonalSegmentListPage', filters, page, size, workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryPersonalSegmentListPage({
      page: Number(page) - 1,
      size: Number(size),
      sort: 'created_at,desc',
      ...(filters || {}),
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const handleComplete = React.useCallback(
    async row => {
      const res = await apiClient.finnishPersonalSegment(row.id)
      if (res.success) {
        refetch()
      } else {
        toast.error(res.message ?? '系统异常')
      }
    },
    [refetch]
  )

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?page=${current}&size=${pageSize}`)
    },
    [match, history]
  )

  const handleSearch = React.useCallback(
    (values?: QueryPersonalSegmentListReg) => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const columns = React.useMemo<TableColumnType<CrmPersonalSegmentResource>[]>(
    () => [
      {
        title: '人群名称',
        width: 200,
        colKey: 'name',
        ellipsis: true,
        cell: ({ row }) => <Link to={`${match.url}/read/${row.id}`}>{row.name}</Link>,
      },
      { title: '人群描述', colKey: 'description', width: 150, ellipsis: true },
      { title: '提交时间', colKey: 'createdAt', width: 200, cell: ({ row }) => UTCToLocal(row.createdAt) },
      { title: '人群规则', colKey: 'ruleDetail', width: 200, ellipsis: true, cell: ({ row }) => row?.spec?.ruleDetail },
      {
        title: '人群包期望规模',
        colKey: 'excectCount',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => {
          const num = Number(row?.spec?.excectCount || 0)
          return num > 0 ? `${num / 10000}万` : 0
        },
      },
      {
        title: '企业管理员',
        colKey: 'adminUser',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => row?.adminUser?.username,
      },
      {
        title: '所属公司',
        colKey: 'organization',
        width: 150,
        ellipsis: true,
        cell: ({ row }) => row?.organizationEntity?.name,
      },
      {
        title: '状态',
        colKey: 'status',
        width: 120,
        cell: ({ row }) => {
          const obj = personalSegmentStatusEnum.map.get(row.status)
          return <StatusLabel color={obj?.color} label={obj?.label} />
        },
      },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        fixed: 'right' as const,
        cell: ({ row }) => {
          return (
            row.status === SegmentStatusEnum.Processing && (
              <>
                {hasPermission({ permission: 'finish:individuation_segments' }) && (
                  <Button variant='text' theme='primary' className='me-4' onClick={() => handleComplete(row)}>
                    完成
                  </Button>
                )}
              </>
            )
          )
        },
      },
    ],
    [handleComplete, hasPermission, match.url]
  )

  return (
    <WorkspacePageLayout title='个性化人群' headerRight={expandIcon}>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          columns={columns}
          data={crowdPackData?.items || []}
          empty='暂无数据'
          loading={isLoading}
          rowKey='id'
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: crowdPackData?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
    </WorkspacePageLayout>
  )
}

export default Page
