import {
  SegmentCallStatusType,
  PlatformEnum,
  SegmentStatusEnum,
  SegmentPushStatusEnum,
  DeviceTypeEnum,
  SegmentSourceEnum,
} from '@dmp/api-sdk'
import ocean_logo from '../assets/platform/ocean_logo.png'
import tencent_logo from '../assets/platform/tencent_logo.png'
import kuaishou_logo from '../assets/platform/kuaishou_logo.png'

const generateEnumMap = (list: any[] = []) => {
  const map = new Map()
  list.forEach(item => map.set(item.value, item))
  return map
}

// 人群包状态枚举
export const segmentStatusEnum = (() => {
  const list = [
    { label: '已撤销', value: SegmentStatusEnum.Aborted, tags: ['segment'] },
    { label: '等待中', value: SegmentStatusEnum.Pending, color: 'pending', tags: ['segment'] },
    { label: '错误', value: SegmentStatusEnum.Error, color: 'danger', tags: ['segment'] },
    { label: '处理完成', value: SegmentStatusEnum.Success, color: 'success', tags: ['segment'] },
    { label: '处理中', value: SegmentStatusEnum.Processing, color: 'processing', tags: ['segment'] },
    { label: '审核失败', value: SegmentStatusEnum.Refused, color: 'danger', tags: ['approval', 'segment'] },
    { label: '审核中', value: SegmentStatusEnum.Approving, color: 'processing', tags: ['approval', 'segment'] },
    { label: '审核通过', value: SegmentStatusEnum.Approved, color: 'success', tags: ['approval'] },
  ]

  const filter = (tag: string) => list?.filter(it => it.tags?.includes(tag))

  return { list, map: generateEnumMap(list), enum: SegmentStatusEnum, filter }
})()

// 人群包实时调用状态枚举
export const segmentRealTimeStatusEnum = (() => {
  const list = [
    { label: '可用', value: SegmentCallStatusType.Available, color: 'success' },
    { label: '释放中', value: SegmentCallStatusType.Releasing, color: 'processing' },
    { label: '处理中', value: SegmentCallStatusType.Processing, color: 'processing' },
    { label: '不可用', value: SegmentCallStatusType.Unavailable, color: 'info' },
    { label: '发布失败', value: SegmentCallStatusType.ProcessingFailed, color: 'danger' },
  ]
  return { list, map: generateEnumMap(list) }
})()

// 广告投放平台枚举
export const platformEnum = (() => {
  const list = [
    { label: '广点通', value: PlatformEnum.Tencent, icon: tencent_logo },
    { label: '巨量引擎', value: PlatformEnum.Ocean, icon: ocean_logo },
    { label: '快手', value: PlatformEnum.KuaiShou, icon: kuaishou_logo },
  ]
  return { list, map: generateEnumMap(list), enum: PlatformEnum }
})()

// 广告投放账号授权状态枚举
export const authStatusEnum = (() => {
  const list = [
    { label: '未授权', value: 'INIT' },
    { label: '已过期', value: 'INVALID', color: 'warning' },
    { label: '授权失败', value: 'FAILED', color: 'danger' },
    { label: '授权成功', value: 'SUCCESS', color: 'success' },
  ]
  return { list, map: generateEnumMap(list) }
})()

// 人群包推送状态枚举
export const crowdPushStatusEnum = (() => {
  const list = [
    { label: '推送成功', value: SegmentPushStatusEnum.Success, color: 'success', tags: ['segment', 'record'] },
    { label: '推送失败', value: SegmentPushStatusEnum.Failed, color: 'danger', tags: ['segment', 'record'] },
    { label: '正在推送', value: SegmentPushStatusEnum.Processing, color: 'processing', tags: ['segment', 'record'] },
    { label: '审核失败', value: SegmentStatusEnum.Refused, color: 'danger', tags: ['approval', 'segment'] },
    { label: '审核中', value: SegmentStatusEnum.Approving, color: 'processing', tags: ['approval', 'segment'] },
    { label: '审核通过', value: SegmentStatusEnum.Approved, color: 'success', tags: ['approval'] },
  ]

  const filter = (tag: string) => list?.filter(it => it.tags?.includes(tag))

  return { list, map: generateEnumMap(list), enum: SegmentPushStatusEnum, filter }
})()

// rta策略运行状态枚举
export const rtaStrategyStatusEnum = (() => {
  const list = [
    { label: '生效', value: 'VALID', color: 'success' },
    { label: '未生效', value: 'INVALID', color: 'danger' },
  ]
  return { list, map: generateEnumMap(list) }
})()

// rta策略属性枚举
export const rtaStrategyAttributeEnum = (() => {
  const list = [
    { label: '命中竞价', value: 'HIT' },
    { label: '未命中竞价', value: 'MISS' },
  ]
  return { list, map: generateEnumMap(list) }
})()

// 人群包设备类型枚举
export const segmentDeviceTypeEnum = (() => {
  const list = [
    { label: 'imei-md5', value: 'imei-md5' },
    { label: 'oaid-md5', value: 'oaid-md5' },
    { label: 'idfa-md5', value: 'idfa-md5' },
    { label: '手机号-md5', value: 'mobile-md5' },
  ]
  return { list, map: generateEnumMap(list) }
})()

// 设备类型枚举-用于新建人群包推送页面
export const deviceTypeEnum = (() => {
  const list = [
    { label: 'imei-md5', value: DeviceTypeEnum.ImeiMd5 },
    { label: '手机号-md5', value: DeviceTypeEnum.MobileMd5 },
    { label: '手机号-sha256', value: DeviceTypeEnum.MobileSha256 },
    { label: 'oaid', value: DeviceTypeEnum.Oaid },
    { label: 'oaid-md5', value: DeviceTypeEnum.OaidMd5 },
    { label: 'idfa-md5', value: DeviceTypeEnum.IdfaMd5 },
  ]
  return { list, map: generateEnumMap(list), enum: DeviceTypeEnum }
})()

// 人群来源
export const segmentSourceEnum = (() => {
  const list = [
    { label: '一方人群', value: SegmentSourceEnum.Self },
    { label: '三方人群', value: SegmentSourceEnum.Tripartite },
  ]
  return { list, map: generateEnumMap(list) }
})()

export enum SegmentTypeEnum {
  Label = 'LABEL',
  LookLike = 'LOOKALIKE',
  Self = 'SELF',
  Individuation = 'INDIVIDUATION',
}

export const segmentTypeEnum = (() => {
  const list = [
    { label: '多维标签人群', value: SegmentTypeEnum.Label, tags: ['approval'] },
    { label: '拓展人群', value: SegmentTypeEnum.LookLike, tags: ['approval'] },
    { label: '自有人群', value: SegmentTypeEnum.Self },
    { label: '个性化人群', value: SegmentTypeEnum.Individuation },
  ]
  const filter = (tag: string) => list?.filter(it => it.tags?.includes(tag))

  return { list, map: generateEnumMap(list), enum: SegmentTypeEnum, filter }
})()

export const systemAbilityEnum = (() => {
  const abilities: any = {
    /**
     * RTA管理模块能力
     */
    RTA: ['BETA'],
    /**
     * 人群包实时调用能力
     */
    RealTimeCall: ['BETA'],
  }
  const hasAbility = (abilityKey = '', channel = '') => abilities?.[abilityKey]?.includes(channel)
  return { abilities, hasAbility }
})()

// 个性化人群包处理状态
export const personalSegmentStatusEnum = (() => {
  const list = [
    { label: '已完成', value: segmentStatusEnum.enum.Success, color: 'success' },
    { label: '未完成', value: segmentStatusEnum.enum.Processing, color: 'processing' },
  ]
  return { list, map: generateEnumMap(list) }
})()
