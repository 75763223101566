import React from 'react'
import MainPage from './MainPage'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
export default () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact render={() => <MainPage />} />
    </Switch>
  )
}
