import React, { useState } from 'react'
import { noop } from '@dmp/utils'
import { Button, Form, FormItem, Input } from '@dmp/components'

export const BasicForm: React.FC<{
  onSubmit?: (params: { email: string; password: string }) => void
}> = ({ onSubmit = noop }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  function validateForm() {
    return email.length > 3 && password.length > 1
  }

  const handleSubmit = () => {
    onSubmit({ email, password })
  }

  return (
    <div className='basic-login-form'>
      <Form labelAlign='top'>
        <FormItem label='Email' name='email'>
          <Input value={email} onChange={val => setEmail(val as string)} />
        </FormItem>
        <FormItem label='密码'>
          <Input autocomplete='new-password' value={password} onChange={val => setPassword(val as string)} />
        </FormItem>
        {/* <Form.Group controlId='password2' className='my-3'>
          <Form.Label>再次密码</Form.Label>
          <Form.Control type='password' value={password} onChange={e => setPassword(e.target.value)} />
        </Form.Group> */}
        <div className='my-2'>
          <Button disabled={!validateForm()} onClick={handleSubmit}>
            注册
          </Button>
        </div>
      </Form>
    </div>
  )
}
