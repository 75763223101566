import { map, max } from 'lodash'
import { percentage } from '@dmp/utils'
import {
  SegmentPortraitConfig,
  SegmentPortraitChartTypeEnum,
  QuerySegmentAttributePortraitRes,
  SegmentAttributePortrait,
} from '@dmp/api-sdk'

export const getChartsOptions = (config: SegmentPortraitConfig, source?: QuerySegmentAttributePortraitRes): any => {
  if (!source?.length) return
  switch (config.chartsType) {
    case SegmentPortraitChartTypeEnum.Bar: {
      const tgis = map(source, it => Math.round(it.tgi))
      const values = map(source, 'value')
      const tgiInterval = Math.round((max(tgis) ?? 0) / 5)
      return {
        legend: {
          bottom: 10,
          icon: 'square',
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
          type: 'category',
          data: map(source, 'name'),
        },
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 1,
            axisLabel: { formatter: (val: number) => `${val * 100}%` },
          },
          {
            type: 'value',
            min: 0,
            max: tgiInterval * 5,
            interval: tgiInterval,
            axisLine: { onZero: true, onZeroAxisIndex: 1 },
          },
        ],
        series: [
          {
            name: config.alias ?? config.labelCname,
            type: 'bar',
            barMaxWidth: 40,
            yAxisIndex: 0,
            data: values,
            tooltip: {
              valueFormatter: percentage,
            },
          },
          {
            name: 'TGI',
            type: 'bar',
            barMaxWidth: 40,
            yAxisIndex: 1,
            data: tgis,
          },
        ],
      }
    }
    case SegmentPortraitChartTypeEnum.Pie:
      return {
        legend: { bottom: 0, icon: 'circle' },
        tooltip: { trigger: 'item', valueFormatter: percentage },
        label: {
          alignTo: 'edge',
          minMargin: 5,
          lineHeight: 15,
          fontWeight: 400,
          formatter(params: any) {
            return `{name|${params.name}}\n{percent|占比: ${params.percent}%}\n{TGI|TGI: ${params.data.tgi}}`
          },
          rich: {
            name: {
              fontWeight: '700',
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            itemStyle: {
              borderColor: '#fff',
            },
            data: source?.map((it: SegmentAttributePortrait) => ({
              value: it.value,
              name: it.name,
              tgi: Math.round(it.tgi),
            })),
          },
        ],
      }
  }
}
