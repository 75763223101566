import React from 'react'
import MainPage from './MainPage'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
import SegmentDetailPage from '../../SegmentPage/SegmentDetailPage'

export default () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/read/:segmentId`} exact render={() => <SegmentDetailPage readonly />} />
      <Route exact render={() => <MainPage />} />
    </Switch>
  )
}
