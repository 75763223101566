import React from 'react'

export default ({ list }: { list: Array<{ label: string; value?: number; hidden?: boolean }> }) => {
  return (
    <span>
      {list?.map(item =>
        item.hidden ? null : (
          <span className='text-tips mx-2' key={item.label}>
            {item.label}: <span className='text-primary'>{item.value || 0}</span>
          </span>
        )
      )}
    </span>
  )
}
