import './index.scss'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { SegmentResource } from '@dmp/api-sdk'
import { apiClient } from '../../../../utils/apiClient'
import TargetingEditor from '../TargetingEditor/TargetingEditor'
import { useAppSelector } from '../../../../app/hooks'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import { useQuery } from 'react-query'

interface TagValue {
  name: string
  params: string[]
}

interface IState {
  loading: boolean
  selectedTags: TagValue[]
  segment?: SegmentResource
}

export const SegmentDetailPane: React.FC<{
  segmentId: number | undefined
}> = ({ segmentId }) => {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const [state, setState] = useState<IState>({
    loading: false,
    selectedTags: [],
    segment: undefined,
  })

  const channelId = state.segment?.channel?.id

  useEffect(() => {
    if (!segmentId || segmentId < 1) return
    const load = async () => {
      setState(ss => ({ ...ss, loading: true }))
      try {
        const resp = await apiClient.readSegment(`${segmentId}`)
        if (resp.success && resp.data) {
          const segment = resp.data ?? {}
          let selectedTags: TagValue[] = []
          if (segment.type === 'LABEL') {
            selectedTags = segment.spec?.targeting.map(t => ({ name: t.tagName, params: t.tagValues })) || []
          }
          setState(ss => ({ ...ss, segment, selectedTags }))
        }
      } catch (e) {
        console.error(e)
      } finally {
        setState(ss => ({ ...ss, loading: false }))
      }
    }
    load()
  }, [segmentId])

  const { data: tags } = useQuery(['queryTagsByChannel', workspaceId, channelId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryTagsByChannel({ workspaceId, dataSourceChannelId: channelId })
    return res?.success ? res.data || [] : []
  })

  // 没有人群包ID，则什么都不显示
  if (!segmentId || segmentId < 1) {
    return <></>
  }
  const selectedTagNames = state.selectedTags.map(val => val.name)
  const displayTags = tags?.filter(tag => selectedTagNames.some(s => s === tag.name))

  if (state.loading) {
    return (
      <div className='expand-segment-detail__layout'>
        <p>正在加载中 ...</p>
      </div>
    )
  }

  return (
    <div className='expand-segment-detail__layout'>
      <div>
        <label>人群包名称</label>
        {state.segment?.name}
      </div>
      <div>
        <label>预估规模</label>
        {state.segment?.velocity}
      </div>
      {state.segment?.type === 'LABEL' && (
        <>
          <div>标签详情（仅显示选中标签）</div>
          <TargetingEditor data={displayTags || []} defaultValue={state.selectedTags} readOnly size='small' />
        </>
      )}
    </div>
  )
}
