import React from 'react'
import { Alert } from '@dmp/components'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { invalidateSession } from '../../features/global'

export const LogoutPage: React.FC = () => {
  const his = useHistory()
  const dispatch = useAppDispatch()

  const doLogout = React.useCallback(async () => {
    const p = await dispatch(invalidateSession(undefined))
    if (invalidateSession.fulfilled.match(p)) {
      his.push('/session/new/?from=logout')
    }
  }, [dispatch, his])

  React.useEffect(() => {
    setTimeout(() => doLogout(), 1000)
  }, [doLogout])

  return (
    <>
      <div className='d-flex'>
        <div className='flex-fill'>
          <div className='container py-4'>
            <Alert theme='success' message='已成功安全登出!' />
          </div>
        </div>
      </div>
    </>
  )
}
