import React from 'react'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import Transfer from '../../components/Transfer'
import { apiClient } from '../../utils/apiClient'
import { Dialog, Form, FormItem, Input, Textarea } from '@dmp/components'
import clsx from 'clsx'
import CopyableText from '../../components/CopyableText'

interface FormValuesType {
  id?: string
  name: string
  description: string
  members: Array<number>
}

interface DefaultValuesType {
  id?: string
  name: string
  description: string
  members: Array<{ value: number }>
}

const CreateWorkSpaceModal: React.FC<{
  show: boolean
  onHide: (refresh?: boolean) => void
  defaultValues?: DefaultValuesType
}> = ({ show, onHide, defaultValues }) => {
  const [token, setToken] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const { data: members } = useQuery(['organization/staff/list'], async () => {
    const res = await apiClient.queryStaffs()
    return res.success ? res.data : []
  })

  React.useEffect(() => {
    const fn = async () => {
      if (!defaultValues?.id) return
      const res = await apiClient.queryWsToken(defaultValues?.id)
      if (res?.success) setToken(res.data?.[0]?.accessToken)
    }
    fn()
  }, [defaultValues?.id])

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values: FormValuesType = formRef.current?.getFieldsValue(true)
      const isEdit = Boolean(defaultValues?.id)
      const params = {
        ...values,
        members: values.members?.map((it: number) => ({ value: it })),
        id: defaultValues?.id || '',
      }
      setLoading(true)
      const api = isEdit ? apiClient.updateWorkspace : apiClient.createWorkspace
      const res = await api(params)
      setLoading(false)
      if (res.success) {
        toast.success(`${isEdit ? '编辑' : '新建'}工作空间成功!`)
        onHide(true)
      } else {
        toast.error(res.message)
      }
    },
    [onHide, defaultValues]
  )

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入名称' }],
      description: [{ required: true, message: '请输入描述' }],
    }),
    []
  )

  return (
    <Dialog
      visible={show}
      header={`${defaultValues?.id ? '编辑' : '新建'}工作空间`}
      onClose={() => onHide()}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ loading }}
    >
      <Form ref={formRef} labelAlign='left' labelWidth={80} rules={rules} onSubmit={onSubmit}>
        <FormItem label='名称' name='name' initialData={defaultValues?.name}>
          <Input maxlength={32} placeholder='请输入名称，最多32个字符' />
        </FormItem>
        <FormItem label='描述' name='description' initialData={defaultValues?.description}>
          <Textarea maxlength={256} placeholder='请输入描述，最多256个字符' />
        </FormItem>
        <div className={clsx('t-form__item d-flex', { 'd-none': !defaultValues?.id })}>
          <label className='t-form__label t-form__label--left' style={{ width: 94 }}>
            Token
          </label>
          <CopyableText maxlength={32} placeholder='工作空间Token' readonly value={token} />
        </div>
        <FormItem label='人员' name='members' initialData={defaultValues?.members?.map(it => it.value)}>
          <Transfer
            options={members?.map((item: any) => ({ label: item.username, value: item.id })) || []}
            title='员工列表'
          />
        </FormItem>
      </Form>
    </Dialog>
  )
}

export default CreateWorkSpaceModal
