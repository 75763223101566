import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import Filters from './Filters'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { composeBidUrl } from '../../../utils'
import { useExpand, usePlatform, useThrottledEffect } from '../../../hooks'
import { useAppSelector } from '../../../app/hooks'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { useRBAC } from '../../../features/global/hooks'
import { Strategy, QueryStrategyListReg, PlatformEnum } from '@dmp/api-sdk'
import { Table, Button, TableColumnType } from '@dmp/components'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { platformEnum, rtaStrategyStatusEnum, rtaStrategyAttributeEnum } from '../../../constants/enums'
import { selectWorkspaceChannel } from '../../../features/workspace/workspaceSlice'
import StatusLabel from '../../../components/StatusLabel'

export default () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { hasPermission } = useRBAC()
  const { expand, expandIcon } = useExpand()
  const { platform, platformRadioGroup } = usePlatform({ exclude: [PlatformEnum.KuaiShou] })
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const workspaceChannel = useAppSelector(selectWorkspaceChannel)

  const [filters, setFilters] = React.useState<QueryStrategyListReg>()

  const search = qs.parse(location.search)
  const { page = '1', size = '10' } = search

  const {
    isLoading,
    data: strategyData,
    mutate,
  } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryStrategyListPage({
      page: Number(page) - 1,
      size: Number(size),
      sort: 'createdAt,desc',
      workspaceId,
      advertPlatform: platform,
      ...filters,
    })
    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const updateStatusMutation = useMutation(
    (item: Strategy) =>
      workspaceId
        ? apiClient.updateStrategyStatus({
            id: item.id,
            workspaceId,
            status: item.status === 'INVALID' ? 'VALID' : 'INVALID',
          })
        : Promise.reject(),
    {
      onSuccess(res) {
        if (res.success) {
          toast.success('操作成功')
          mutate()
        } else {
          toast.error(res.message)
        }
      },
      onError() {
        toast.error('系统异常')
      },
    }
  )

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [match.url, history, search]
  )

  const handleSearch = (values?: QueryStrategyListReg) => {
    setFilters(values)
    onPaginationChange({ current: 1, pageSize: Number(size) })
  }

  const handleCreateClick = React.useCallback(() => {
    history.push(`${match.url}/create?platform=${platform}`)
  }, [history, match.url, platform])

  const headerRight = (
    <>
      {hasPermission({ permission: 'create:RTA_strategy' }) && (
        <Button icon={<i className='iconfont icon-add-select' />} onClick={handleCreateClick}>
          新建RTA策略
        </Button>
      )}
      <span className='ms-3'>{expandIcon}</span>
    </>
  )

  const columns: Array<TableColumnType<Strategy>> = [
    {
      title: '策略名称',
      width: 200,
      colKey: 'name',
      ellipsis: true,
      cell({ row }) {
        // 人群包数据源与当前工作空间的数据源不一致，不可进入详情
        if (row?.segmentChannel?.id !== workspaceChannel?.id) return row.name
        return <Link to={`${match.url}/read/${row.strategyId}`}>{row.name}</Link>
      },
    },
    {
      title: 'RTA名称',
      width: 150,
      colKey: 'advertPlatformRtaName',
      ellipsis: true,
      cell: ({ row }) => row?.advertRtaLaunchBind?.advertPlatformRtaName,
    },
    {
      title: '投放广告平台',
      colKey: 'advertPlatform',
      width: 150,
      cell: ({ row }) => platformEnum.map.get(row?.advertRtaLaunchBind?.advertPlatform)?.label,
    },
    {
      title: 'BidUrl',
      width: 200,
      colKey: 'bidUrl',
      ellipsis: true,
      cell: ({ row }) => composeBidUrl(row?.advertRtaLaunchBind),
    },
    { title: 'RTA策略ID', colKey: 'strategyId', width: 150, ellipsis: true },
    { title: '人群包名称', colKey: 'segmentName', width: 150, ellipsis: true },
    {
      title: '策略属性',
      colKey: 'strategyProperty',
      width: 120,
      cell: ({ row }) => rtaStrategyAttributeEnum.map.get(row.strategyProperty)?.label,
    },
    {
      title: '状态',
      colKey: 'status',
      width: 120,
      cell({ row }) {
        const obj = rtaStrategyStatusEnum.map.get(row.status)
        return <StatusLabel color={obj?.color} label={obj?.label} />
      },
    },
    {
      title: '创建时间',
      colKey: 'createdAt',
      width: 200,
      cell: ({ row }) => UTCToLocal(row.createdAt),
    },
    {
      title: '操作',
      colKey: 'action',
      fixed: 'right',
      width: 150,
      cell({ row }) {
        // 人群包数据源与当前工作空间的数据源不一致，所有操作按钮隐藏
        if (row?.segmentChannel?.id !== workspaceChannel?.id) return
        return (
          <>
            <Button
              variant='text'
              theme='primary'
              className='me-4'
              onClick={() => history.push(`${match.url}/read/${row.strategyId}`)}
            >
              详情
            </Button>
            {hasPermission({ permission: 'operate:RTA_strategy' }) && (
              <Button variant='text' theme='primary' className='me-4' onClick={() => updateStatusMutation.mutate(row)}>
                {row.status === 'INVALID' ? '生效' : '失效'}
              </Button>
            )}
          </>
        )
      },
    },
  ]

  useThrottledEffect(mutate, [page, size, filters, platform])

  return (
    <WorkspacePageLayout title='RTA策略管理' headerRight={headerRight} headerChildren={platformRadioGroup}>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          rowKey='id'
          data={strategyData?.items || []}
          empty='暂无RTA策略'
          loading={isLoading}
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: strategyData?.total || 0,
            onChange: onPaginationChange,
          }}
          columns={columns}
        />
      </Module>
    </WorkspacePageLayout>
  )
}
