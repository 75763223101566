import React from 'react'
import { noop } from '@dmp/utils'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { useAppSelector } from '../../../../app/hooks'
import { apiClient } from '../../../../utils/apiClient'
import { apiBaseUrl } from '../../../../constants'
import { SegmentResource, CreatePersonalSegmentReg, SegmentStatusEnum } from '@dmp/api-sdk'
import { WorkspacePageLayout, Module } from '../../../../layout/PageLayout'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import { Button, Alert, Form, FormItem, Input, Textarea, InputNumber, Upload } from '@dmp/components'

interface PropsType {
  /** 创建或修改人群包信息的回调函数 */
  onSuccess?: (newId: number | string) => void
  /** 人群包详细信息 */
  segment?: SegmentResource
  /** 只读，无任何修改权限 */
  readonly?: boolean
}

export const PersonalSegmentEditor: React.FC<PropsType> = props => {
  const { onSuccess = noop, segment, readonly } = props

  const his = useHistory()
  const isDetailPage = !!segment?.id
  const spec = segment?.type === 'INDIVIDUATION' ? segment?.spec : undefined
  const disabled = readonly || (isDetailPage && segment?.status !== SegmentStatusEnum.Processing)

  const workspaceId = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const createOrUpdateMutation = useMutation(
    (values: Omit<CreatePersonalSegmentReg, 'workspaceId'>) => {
      if (!workspaceId) return Promise.reject()
      const { expectCount, filePaths, ...rest } = values
      const data: any = {
        workspaceId,
        expectCount: values.expectCount * 10000,
        filePaths: filePaths?.map(({ response }: any) => ({
          actualFileName: response.actualFileName,
          orginFileName: response.orginFileName,
        })),
        ...rest,
      }
      if (segment?.id) {
        data.id = segment.id
        return apiClient.updatePersonalSegment(data)
      } else {
        return apiClient.createPersonalSegment(data)
      }
    },
    {
      onSuccess(res) {
        if (res?.success) {
          toast.success('操作成功')
          if (isDetailPage) his.goBack()
          else onSuccess(String(res.data.id))
        } else {
          toast.error(res.message)
        }
      },
      onError() {
        toast.error('系统异常')
      },
    }
  )

  const rules = {
    name: [{ required: true, message: '请输入名称，至多32个字符' }],
    description: [{ required: true, message: '请输入描述，至多256个字符' }],
    ruleDetail: [{ required: true, message: '请输入具体的人群包生成规则，最多1000个字符' }],
    expectCount: [{ required: true, message: '请输入人群包期望规模' }],
    filePaths: [
      {
        validator: (val: any[]) => !val?.length || val?.every?.(f => f.status === 'success'),
        message: '正在上传附件，请稍等',
      },
    ],
  }

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      createOrUpdateMutation.mutate(values)
    },
    [createOrUpdateMutation]
  )

  const generateDownloadUrl = React.useCallback((fileName: string, fileKey: string) => {
    if (!(fileName && fileKey)) return ''
    return encodeURI(
      `${apiBaseUrl}/segments/individuation/file/download?orginFileName=${fileName}&actualFileName=${fileKey}`
    )
  }, [])

  const requestMethod = React.useCallback(
    (file: any): Promise<any> => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async resolve => {
        try {
          const formData = new FormData()
          formData.set('files', file.raw)
          const res = await apiClient.personalSegmentUploadFile(formData)
          if (res?.success) {
            const { actualFileName, orginFileName } = res?.data?.filePaths?.[0] || {}
            const url = generateDownloadUrl(orginFileName, actualFileName)
            resolve({ status: 'success', response: { url, actualFileName, orginFileName } })
          } else {
            resolve({ status: 'fail', error: res.message || '上传失败' })
          }
        } catch (err) {
          console.error(err, 'requestMethod')
          resolve({ status: 'fail', error: '上传失败' })
        }
      })
    },
    [generateDownloadUrl]
  )

  const handleRemove = React.useCallback(async (file, callback) => {
    if (!file?.response?.actualFileName) {
      toast.error('fileKey有误')
      return
    }
    try {
      const res = await apiClient.personalSegmentRemoveFile(file?.response?.actualFileName)
      if (res.success) {
        toast.success('移除成功，点击保存后生效')
        callback()
      } else {
        toast.error(res?.message || '移除失败')
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  const FormEditor = (
    <Module title='基本信息' hasGap={isDetailPage && !readonly}>
      <Form ref={formRef} labelAlign='left' labelWidth={120} rules={rules} onSubmit={onSubmit} disabled={disabled}>
        <FormItem label='人群包名称' name='name' initialData={segment?.name}>
          <Input placeholder='请输入人群包名称，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='人群包描述' name='description' initialData={segment?.description}>
          <Textarea placeholder='请输入人群包描述，最多256个字符' maxlength={256} />
        </FormItem>
        <FormItem label='人群规则详述' name='ruleDetail' initialData={spec?.ruleDetail}>
          <Textarea placeholder='请输入具体的人群包生成规则，最多1000个字符' maxlength={1000} />
        </FormItem>
        <FormItem
          label='人群包期望规模'
          name='expectCount'
          initialData={spec?.excectCount ? spec?.excectCount / 10000 : undefined}
        >
          <InputNumber placeholder='以万为单位' theme='normal' max={99999} min={1} className='me-2' />万
        </FormItem>
        {segment?.velocity && (
          <FormItem>
            <Alert className='w-100' theme='info' message={`共计${segment.velocity}个设备标识`} />
          </FormItem>
        )}

        <FormItem
          label='附件上传'
          name='filePaths'
          initialData={spec?.filePaths?.map((f: any) => ({
            name: f.orginFileName,
            url: generateDownloadUrl(f.orginFileName, f.actualFileName),
            response: f,
            status: 'success',
          }))}
        >
          <Upload
            multiple
            theme='custom'
            onRemove={handleRemove}
            max={10}
            requestMethod={requestMethod}
            maxSize={{ value: 100, message: '请上传100M大小以内的文件' }}
            tips='提示：针对人群生成规则的详细补充说明可在附件中上传，单个文件体积小于100M'
          />
        </FormItem>
      </Form>
      {disabled ? null : (
        <>
          <div className='mt-4 d-flex justify-content-between'>
            <Button variant='outline' onClick={() => his.goBack()}>
              取消
            </Button>
            <Button loading={createOrUpdateMutation.isLoading} onClick={() => formRef.current?.submit()}>
              创建并关闭
            </Button>
          </div>
        </>
      )}
    </Module>
  )

  if (isDetailPage) return FormEditor
  return (
    <WorkspacePageLayout title='个性化人群需求提交' hasBack>
      {FormEditor}
    </WorkspacePageLayout>
  )
}
