/* eslint-disable */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import counterReducer from '../features/counter/counterSlice'
import segmentReducer from '../features/segment/segmentSlice'
import globalReducer from '../features/global/globalSlice'
import workspaceReducer from '../features/workspace/workspaceSlice'

export const store = configureStore({
  reducer: {
    global: globalReducer,
    counter: counterReducer,
    segment: segmentReducer,
    workspace: workspaceReducer,
  },

  // TODO: extra 貌似还没有起作用，还没明白怎么用
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(
      thunk.withExtraArgument<ExtraParams>({
        jwt: 'jwt-demo-123',
      })
    )
  },
})

export interface ExtraParams {
  jwt: string
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
