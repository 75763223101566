import React, { useCallback } from 'react'
import { ReactComponent as IconWarning } from 'bootstrap-icons/icons/exclamation-triangle-fill.svg'
import clsx from 'clsx'
import { noop } from '@dmp/utils'

type InputType = 'none' | 'input' | 'textarea'
type ChangeHandler = (value: string, field?: string) => void

const FormFieldInput: React.FC<{
  type: InputType
  name: string
  placeholder?: string
  isInvalid?: boolean
  defaultValue?: string
  readOnly?: boolean
  onChange?: ChangeHandler
  disabled?: boolean
  maxLength?: number
  autoComplete?: string
}> = ({
  type,
  name,
  placeholder = '',
  isInvalid = false,
  defaultValue,
  readOnly = false,
  onChange = noop,
  maxLength,
  disabled,
  autoComplete,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event.target?.value, name)
    },
    [onChange, name]
  )

  if (type === 'input') {
    return (
      <input
        id={`id_text_${name}`}
        autoComplete={autoComplete}
        type='text'
        className={clsx('form-control', { 'is-invalid': isInvalid })}
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        maxLength={maxLength}
      />
    )
  }

  if (type === 'textarea') {
    return (
      <textarea
        id={`id_textarea_${name}`}
        autoComplete={autoComplete}
        className={clsx('form-control', { 'is-invalid': isInvalid })}
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        maxLength={maxLength}
      />
    )
  }

  return <p>not support type: {type}</p>
}

export const FormField: React.FC<{
  type: InputType
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  isInvalid?: boolean
  validationMessage?: string
  onChange?: ChangeHandler
  defaultValue?: string
  readOnly?: boolean
  disabled?: boolean
  maxLength?: number
  autoComplete?: string
}> = ({
  type,
  name,
  placeholder,
  onChange,
  label = 'Label',
  required = false,
  children,
  isInvalid = false,
  validationMessage = 'validation message goes here',
  defaultValue,
  readOnly,
  maxLength,
  autoComplete,
  disabled,
}) => {
  const inputProps = {
    type,
    name,
    placeholder,
    onChange,
    isInvalid,
    defaultValue,
    readOnly,
    maxLength,
    autoComplete,
    disabled,
  }

  return (
    <>
      <div className='row mb-3'>
        <label htmlFor={name} className='col-sm-2 col-form-label'>
          {label} {required ? <span className='color-required'>*</span> : null}
        </label>
        <div className='col-sm-6'>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {type === 'none' ? children : <FormFieldInput {...inputProps} onChange={onChange} />}

          {isInvalid ? (
            <>
              <p className='text-danger py-1'>
                <span className='me-2'>
                  <IconWarning />
                </span>{' '}
                {validationMessage}
              </p>
              <div className='valid-feedback'>{validationMessage}</div>
            </>
          ) : null}
          {/* {errors.name ? (
            <ul>
              {errors.name.map(msg => (
                <li key={msg}>
                  <span className='text-danger'>{msg}</span>
                </li>
              ))}
            </ul>
          ) : null} */}
        </div>
      </div>
    </>
  )
}
