import React from 'react'
import { useRecoilValue } from 'recoil'
import { Tag } from '@dmp/components'
import { UsersTab } from './components/UsersTab'
import { findWorkspaceRole } from '../../recoil/role'
import { SettingsTab } from './components/SettingsTab'
import { PermissionTab } from './components/PermissionTab'
import SubNav, { ISubNavItem } from '../../components/SubNav'
import { Module, WorkspacePageLayout } from '../../layout/PageLayout'
import LockedAlert from './components/LockedAlert'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'

const subnavs: ISubNavItem[] = [
  {
    label: '设置',
    linkTo: 'settings',
  },
  {
    label: '权限',
    linkTo: 'permissions',
  },
  {
    label: '用户',
    linkTo: 'users',
  },
]

// 角色详情页
export const RoleDetailPage: React.FC = () => {
  const match = useRouteMatch()
  const { pathname } = useLocation()
  const { roleId } = useParams<{ roleId: string }>()
  const role = useRecoilValue(findWorkspaceRole({ roleId }))

  if (!roleId) {
    return <>no :roleId found in params</>
  }

  return (
    <WorkspacePageLayout title='角色详情' hasBack to='../../roles'>
      <Module>
        <h2>{role?.name}</h2>
        <div className='d-flex flex-row'>
          <div>
            <p className='text-muted'>
              Role ID <Tag>{roleId}</Tag>
            </p>
          </div>
        </div>

        <SubNav menuItems={subnavs} />
        <LockedAlert locked={role?.locked} />

        <Switch>
          <Route exact path={`${match.path}/settings`} render={() => <SettingsTab />} />
          <Route exact path={`${match.path}/permissions`} render={() => <PermissionTab roleId={roleId} />} />
          <Route exact path={`${match.path}/users`} render={() => <UsersTab roleId={roleId} />} />
          <Route exact path='' render={() => <Redirect to={`${pathname}/settings`} />} />
        </Switch>
      </Module>
    </WorkspacePageLayout>
  )
}
