/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { Workspace, User, Organization, QueryOrgQuotaRes } from '@dmp/api-sdk'
import { AppDispatch, RootState } from '../../app/store'
import { accessTokenName } from '../../constants'
import { MembershipResource } from '../../shared/models'
import { client as http, apiClient, ApiResponseWrapper } from '../../utils/apiClient'

// 加载企业配额信息
export const loadOrgQuota = createAsyncThunk<
  QueryOrgQuotaRes['result'] | null,
  undefined,
  {
    state: RootState
  }
>('global/loadOrgQuota', async (_params, { getState }) => {
  const organizationId = getState().global?.organization?.id
  if (!organizationId) return null
  const res = await apiClient.queryOrgQuota({ organizationId })
  if (!res.success) return null
  return res?.data?.result || null
})

// 启动启动，App Frame 全新加载时的启动入口
export const bootstrapApplication = createAsyncThunk('global/bootstrap', async () => {
  // 读取 user
  const { data: userData } = await http.get<unknown, AxiosResponse<ApiResponseWrapper<User>>>('/user')
  if (!userData.success) return undefined
  // 读取 组织信息
  const organization = await apiClient.queryOrganizationInfo()
  if (!organization.success) return

  return {
    user: userData.data,
    // memberships: membershipsData.data,
    quota: null,
    organization: organization.data,
  }
})

export const loadMemberships = createAsyncThunk(
  'global/memberships',
  async ({ workspaceId }: { workspaceId: string }) => {
    if (!workspaceId) return []
    const res = await apiClient.queryMemberships(workspaceId)
    if (!res.success) return []
    return res.data || []
  }
)

// 废弃
interface TokenResource {
  organizations: Organization[]
}

// 废弃
const fakeGetToken = () => {
  return new Promise<TokenResource>(resolve => {
    setTimeout(() => {
      resolve({
        organizations: [],
      })
    }, 200)
  })
}

// 从服务端读取 token
export const getToken = createAsyncThunk('global/getToken', async () => {
  const data = await fakeGetToken()
  return data
})

export type SignupNewResponse = ApiResponseWrapper<{
  userId: string
}>

interface SignupNewParams {
  email: string
  password: string
}

// TODO: 注册新账号
export const signupNew = createAsyncThunk<string, SignupNewParams>('global/signupNew', async ({ email, password }) => {
  return ''
})

// 执行退出当前登录
export const invalidateSession = createAsyncThunk<string, unknown>('global/logout', async (_, { dispatch }) => {
  // TODO: 还有其他与注销 session 相关流程
  window.localStorage.removeItem(accessTokenName)
  return 'OK'
})

// 使用用户名和密码登录
export const newSession = createAsyncThunk<
  boolean,
  {
    username: string
    password: string
  },
  {
    rejectValue: {
      message: string
    }
  }
>('global/login', async ({ username, password }, { rejectWithValue }) => {
  const result = await apiClient.login({
    username,
    password,
  })

  if (result.success) {
    if (result.data) {
      const { token } = result.data
      window.localStorage.setItem(accessTokenName, token)
      return true
    }
  }

  if (!result.success && result.message) {
    return rejectWithValue({
      message: result.message,
    })
  }

  // TODO:
  return false
})
