import React from 'react'
import qs from 'query-string'
import Avatar from 'react-avatar'
import { User } from '@dmp/api-sdk'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { emailReg } from '../../constants'
import { useAppSelector } from '../../app/hooks'
import { apiClient } from '../../utils/apiClient'
import { BasePageLayout } from '../../layout/PageLayout'
import EllipsisTooltip from '../../components/EllipsisTooltip'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { selectOrgQuota, selectSession } from '../../features/global/globalSlice'
import { Table, TableCellParams, Button, Dialog, dialog, Tag, Form, FormItem, Input } from '@dmp/components'
import WsQuotaLine from '../../components/WsQuotaLine'

type FormValuesType = {
  displayName: string
  confirmPassword: string
  email: string
  password: string
}

export interface FillInfoModalPropsType {
  show: boolean
  onHide: (refresh?: boolean) => void
  defaultValues?: User
}

export const NewStaffModal: React.FC<FillInfoModalPropsType> = props => {
  const { show, onHide, defaultValues } = props
  const [loading, setLoading] = React.useState(false)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const isEdit = Boolean(defaultValues?.id)

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      setLoading(true)
      const values: FormValuesType = formRef.current?.getFieldsValue(true)
      let res: any = { success: false }
      if (defaultValues?.id) {
        res = await apiClient.updateStaff({ ...values, id: defaultValues.id })
      } else {
        res = await apiClient.createStaff(values)
      }
      setLoading(false)
      if (res.success) {
        toast.success(`${isEdit ? '编辑' : '添加'}人员成功!`)
        onHide(true)
      } else {
        toast.error(res.message)
      }
    },
    [setLoading, defaultValues?.id, isEdit, onHide]
  )

  const rules = React.useMemo(
    () => ({
      displayName: [{ required: true, message: '请输入姓名' }],
      email: [
        { required: true, message: '请输入邮箱' },
        { pattern: emailReg, message: '邮箱格式错误，请重新输入' },
      ],
      password: [
        { required: !isEdit, message: '请输入登录密码' },
        {
          validator: (val: string) => {
            const obj = {
              result: true,
              message: '',
            }
            if (!val) {
              obj.result = !!isEdit
              obj.message = '请输入登录密码'
              return obj
            }
            if (!/^\w{6,64}$/g.test(val)) {
              obj.result = false
              obj.message = '密码6-64位，由数字、字母、下划线组成'
              return obj
            }
            if (!(/\d/.test(val) && /[a-zA-Z]/.test(val))) {
              obj.result = false
              obj.message = '至少包含数字和字母两种字符'
              return obj
            }
            return obj
          },
        },
      ],
      confirmPassword: [
        { required: !isEdit, message: '请输入与新密码相同的确认密码' },
        {
          validator: (val: string) => {
            const psd = formRef.current?.getFieldValue('password')
            const obj = {
              result: true,
              message: '',
            }
            if (!val) {
              obj.result = isEdit ? (psd ? false : true) : false
              obj.message = '请输入与新密码相同的确认密码'
              return obj
            }
            if (val !== psd) {
              obj.result = false
              obj.message = '两次输入的密码不一致，请重新输入'
              return obj
            }
            return obj
          },
        },
      ],
    }),
    [isEdit]
  )

  return (
    <Dialog
      visible={show}
      header={isEdit ? '编辑人员' : '添加人员'}
      onClose={() => onHide()}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ loading }}
    >
      <Form ref={formRef} labelAlign='top' rules={rules} onSubmit={onSubmit}>
        <FormItem label='姓名' name='displayName' initialData={defaultValues?.displayName}>
          <Input placeholder='请输入姓名，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='登录邮箱' name='email' initialData={defaultValues?.email}>
          <Input placeholder='请输入登录邮箱，最多32个字符' maxlength={32} />
        </FormItem>
        <FormItem label='登录密码' name='password'>
          <Input
            type='password'
            autocomplete='new-password'
            placeholder='密码6-64位，由数字、字母、下划线组成，且至少包含数字和字母'
            maxlength={64}
          />
        </FormItem>
        <FormItem label='重复密码' name='confirmPassword'>
          <Input
            autocomplete='new-password'
            placeholder='密码6-64位，由数字、字母、下划线组成，且至少包含数字和字母'
            type='password'
            maxlength={64}
          />
        </FormItem>
      </Form>
    </Dialog>
  )
}

const StaffsPage = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const session = useAppSelector(selectSession)
  const orgQuotaResult = useAppSelector(selectOrgQuota)

  const [show, setShow] = React.useState(false)
  const [editStaff, setEditStaff] = React.useState<User>()

  const { page = '1', size = '10' } = qs.parse(location.search)

  const {
    isLoading,
    data: staffData,
    refetch,
  } = useQuery(
    ['query/organization/staffs', page, size],
    async () => {
      const res = await apiClient.queryStaffsPage({
        page: Number(page) - 1,
        size: Number(size),
        sort: 'createdAt,desc',
      })
      if (res.success) {
        return res.data
      } else {
        toast.error(res.message)
      }
    },
    {
      keepPreviousData: true,
    }
  )

  const handleEdit = React.useCallback((staff: User) => {
    setEditStaff(staff)
    setShow(true)
  }, [])

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?page=${current}&size=${pageSize}`)
    },
    [match, history]
  )

  const handleDelete = React.useCallback(
    async (staff: User) => {
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: '确认要删除吗？',
        async onConfirm({ hide }) {
          try {
            if (!staff?.id) return
            const res = await apiClient.deleteStaff(staff.id)
            if (res.success) {
              toast.success('删除人员成功!')
              hide?.()
              if (staffData?.items?.length === 1 && Number(page) > 1) {
                onPaginationChange({ current: Number(page) - 1, pageSize: Number(size) })
              } else {
                refetch()
              }
            } else {
              toast.success(res.message)
            }
          } catch (err) {
            console.error(err)
            toast.error('系统异常，请稍后再试')
          }
        },
      })
    },
    [refetch, onPaginationChange, page, size, staffData?.items]
  )

  const handleHideModal = React.useCallback(
    (isRefresh?: boolean) => {
      if (isRefresh) refetch()
      setShow(false)
      setEditStaff(undefined)
    },
    [setShow, refetch]
  )

  const columns = React.useMemo(
    () => [
      {
        title: '姓名',
        width: 260,
        colKey: 'displayName',
        cell: ({ row }: TableCellParams<User>) => {
          const isYou = session && session.user.id === row.id
          return (
            <div className='d-flex align-items-center'>
              <Avatar size='30' textSizeRatio={1.5} name={row.displayName} round='50%' />
              <EllipsisTooltip maxWidth={120} text={row.displayName} className='mx-2' />
              {isYou && <Tag theme='primary'>你自己</Tag>}
            </div>
          )
        },
      },
      { title: '登录邮箱', colKey: 'email', width: 200, ellipsis: true },
      {
        title: '创建时间',
        colKey: 'createdAt',
        width: 200,
        cell: ({ row }: TableCellParams<User>) => UTCToLocal(row.createdAt),
      },
      {
        title: '操作',
        width: 150,
        colKey: 'action',
        fixed: 'right' as const,
        cell: ({ row }: TableCellParams<User>) => {
          const isYou = session && session.user.id === row.id
          return (
            <>
              <Button variant='text' theme='primary' className='me-4' onClick={() => handleEdit(row)}>
                编辑
              </Button>
              {!isYou && (
                <Button variant='text' theme='danger' className='me-4' onClick={() => handleDelete(row)}>
                  删除
                </Button>
              )}
            </>
          )
        },
      },
    ],
    [handleEdit, handleDelete, session]
  )

  return (
    <BasePageLayout
      title='人员管理'
      remark='管理企业人员。可添加新人员、更新已有人员信息及删除人员'
      headerRight={
        <>
          <WsQuotaLine
            list={[
              { label: '已有人员数量', value: orgQuotaResult?.USER_QUOTA?.useCount },
              { label: '剩余人员数量', value: orgQuotaResult?.USER_QUOTA?.surplusCount },
            ]}
          />
          <Button icon={<i className='iconfont icon-add-select' />} onClick={() => setShow(true)}>
            添加人员
          </Button>
        </>
      }
    >
      <Table
        columns={columns}
        data={staffData?.items || []}
        empty='暂无人员，请进行创建'
        loading={isLoading}
        rowKey='id'
        pagination={{
          current: Number(page),
          pageSize: Number(size),
          total: staffData?.total || 0,
          onChange: onPaginationChange,
        }}
      />
      {show && <NewStaffModal show={show} onHide={handleHideModal} defaultValues={editStaff} />}
    </BasePageLayout>
  )
}

export default StaffsPage
