import { Module } from '../../../../layout/PageLayout'
import { segmentDeviceTypeEnum } from '../../../../constants/enums'
import { Form, FormItem, Input, Textarea, Alert } from '@dmp/components'

export default ({ segment, readonly }: { segment: any; readonly?: boolean }) => {
  return (
    <Module title='基本信息' hasGap={!readonly}>
      <Form labelAlign='left' labelWidth={120} disabled>
        <FormItem label='名称' initialData={segment.name}>
          <Input />
        </FormItem>
        <FormItem label='描述' initialData={segment.description}>
          <Textarea />
        </FormItem>
        <FormItem
          label='人群包设备类型'
          initialData={segmentDeviceTypeEnum.map.get(segment.spec?.selfDeviceType)?.label}
        >
          <Input />
        </FormItem>
        <FormItem label='人群包文件名' initialData={segment.spec?.originFileName}>
          <Input />
        </FormItem>
        <FormItem>
          <Alert className='w-100' message={`共计${segment.spec?.uploadFileVelocity}个设备标识`} />
        </FormItem>
      </Form>
    </Module>
  )
}
