import React from 'react'
import { localToUTC } from '@dmp/utils'
import { QueryCallSummaryReq } from '@dmp/api-sdk'
import { Button, Form, FormItem, Input, DateRangePicker, Select } from '@dmp/components'
import { useQuery } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { useAppSelector } from '../../../app/hooks'
import { TabsEnum } from './MainPage'

interface PropTypes {
  /** 搜索按钮点击函数 */
  onSearch: (values?: QueryCallSummaryReq) => void
  /** 汇总维度 */
  activeKey: TabsEnum
}

export default (props: PropTypes) => {
  const { activeKey, onSearch } = props

  const workspaceId = useAppSelector(selectActiveWorkspace)
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [organizationId, setOrganizationId] = React.useState<string>()

  const { data: channels } = useQuery(['queryChannelList', workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryChannelList(workspaceId)
    return res?.success ? res.data : []
  })

  const { data: organizations } = useQuery(['queryOrganizations'], async () => {
    const res = await apiClient.queryOrganizations()
    return res?.success ? res.data : []
  })

  const { data: workspaces } = useQuery(['queryWorkspaces', organizationId], async () => {
    const res = await apiClient.queryWorkspaces({ organizationId })
    return res?.success ? res.data : []
  })

  const handleValuesChange = React.useCallback(field => {
    if (Object.prototype.hasOwnProperty.call(field, 'organizationId')) {
      setOrganizationId(field.organizationId)
      formRef.current?.setFieldsValue({ workspaceId: '' })
    }
  }, [])

  const handleSubmit = React.useCallback(
    ctx => {
      const values = formRef.current?.getFieldsValue(true) ?? {}
      onSearch({
        ...values,
        dateRange: undefined,
        startDate: localToUTC(values?.dateRange?.[0]),
        endDate: localToUTC(values?.dateRange?.[1]),
      })
    },
    [onSearch]
  )

  const handleReset = React.useCallback(
    ctx => {
      formRef.current?.reset()
      onSearch()
    },
    [onSearch]
  )

  const handleSearch = React.useCallback(() => formRef.current?.submit(), [])

  React.useEffect(() => {
    if (activeKey) handleSearch()
  }, [activeKey])

  const width = 290

  return (
    <div className='d-flex justify-content-between'>
      <Form
        ref={formRef}
        className='d-flex align-items-center flex-wrap'
        labelAlign='top'
        onSubmit={handleSubmit}
        onValuesChange={handleValuesChange}
      >
        <FormItem label='所属企业' name='organizationId' className='mb-2 me-2'>
          <Select
            placeholder='不限'
            options={(organizations || []) as any}
            keys={{ value: 'id', label: 'displayName' }}
            style={{ width }}
          />
        </FormItem>
        {[TabsEnum.Workspace, TabsEnum.Segment].includes(activeKey) && (
          <FormItem label='所属工作空间' name='workspaceId' className='mb-2 me-2'>
            <Select
              placeholder='不限'
              options={(workspaces || []) as any}
              keys={{ value: 'id', label: 'name' }}
              style={{ width }}
            />
          </FormItem>
        )}
        {activeKey === TabsEnum.Segment && (
          <>
            <FormItem label='所属数据源' name='channel' className='mb-2 me-2'>
              <Select
                placeholder='不限'
                options={(channels || []) as any}
                keys={{ value: 'id', label: 'name' }}
                style={{ width }}
              />
            </FormItem>
            <FormItem label='人群包名称' name='segmentName' className='mb-2 me-2'>
              <Input style={{ width }} maxlength={32} placeholder='请输入人群包名称，最多32个字符' />
            </FormItem>
          </>
        )}
        <FormItem label='请求时间' name='dateRange' className='mb-2 me-2'>
          <DateRangePicker style={{ width }} />
        </FormItem>
      </Form>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button onClick={handleSearch}>搜索</Button>
        <Button className='ms-2' variant='outline' onClick={handleReset}>
          重置
        </Button>
      </div>
    </div>
  )
}
