import React from 'react'
import { Alert } from '@dmp/components'

const LockedAlert: React.FC<{ locked?: boolean }> = ({ locked = false }) => {
  return (
    <>
      {locked ? (
        <div className='my-4'>
          <Alert theme='warning' message='此角色已设置为锁定，不允许修改、删除。' />
        </div>
      ) : null}
    </>
  )
}

export default LockedAlert
