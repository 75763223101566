import './index.scss'
import clsx from 'clsx'
import React from 'react'
import { noop } from '@dmp/utils'
import { Dialog, List } from '@dmp/components'
import { useHistory } from 'react-router-dom'
import TagSegmentIcon from '../../../../assets/segment/tag_segment_icon.png'
import ExpandSegmentIcon from '../../../../assets/segment/expand_segment_icon.png'
import SelfSegmentIcon from '../../../../assets/segment/self_segment_icon.png'
import PersonalizedSegmentIcon from '../../../../assets/segment/personalized_segment_icon.png'

const CreateSegmentDialog: React.FC<{ show: boolean; onClose?: () => void }> = ({ show, onClose = noop }) => {
  const his = useHistory()
  const [linkTo, setLinkTo] = React.useState('')

  const handleClick = React.useCallback(() => {
    if (!linkTo) return
    his.push(linkTo)
  }, [his, linkTo])

  React.useEffect(() => {
    if (show) setLinkTo('')
  }, [show])

  const list = React.useMemo(
    () => [
      { title: '标签组合创建', key: 'create', description: '通过标签多维组合提取人群包', image: TagSegmentIcon },
      {
        title: '自有人群数据',
        key: 'create?type=self',
        description: '支持imei-md5、oaid-md5、idfa-md5、手机号-md5四种方式',
        image: SelfSegmentIcon,
      },
      {
        title: '相似受众',
        key: 'create?type=lookalike',
        description: '基于已有人群的特征，寻找更多的相似受众',
        image: ExpandSegmentIcon,
      },
      {
        title: '个性化人群需求',
        key: 'create?type=personalized',
        description: '现有标签不满足人群圈选需求时，可提交个性化人群标签需求',
        image: PersonalizedSegmentIcon,
      },
    ],
    []
  )

  return (
    <Dialog
      visible={show}
      header='新建人群'
      onClose={onClose}
      onConfirm={handleClick}
      confirmBtnProps={{ disabled: !linkTo }}
    >
      <p className='text-muted'>支持以下方式新建人群包</p>
      <List className='create-segment-dialog__list'>
        {list.map(item => (
          <div key={item.key} onClick={() => setLinkTo(item.key)}>
            <List.ListItem className={clsx({ active: linkTo === item.key })}>
              <List.ListItemMeta image={item.image} title={item.title} description={item.description} />
            </List.ListItem>
          </div>
        ))}
      </List>
    </Dialog>
  )
}

export default CreateSegmentDialog
