import React from 'react'
import { noop } from '@dmp/utils'
import { SegmentCallStatusType } from '@dmp/api-sdk'
import { segmentStatusEnum, segmentTypeEnum, segmentRealTimeStatusEnum } from '../../../../constants'
import { Button, Form, FormItem, Input, DateRangePicker, Select } from '@dmp/components'

export interface FilterParam {
  // 名称，支持模糊查询
  segmentName: string | undefined
  // 来源 '3rd' | '2nd' | '1st'
  segmentSource: string | undefined
  // 类型 'label' 多维标签 | 'expand' 拓展人群 | 'self-own' 自有
  segmentType: string | undefined
  // 状态，取值参见 SegementStatusType
  segmentStatus: string | undefined
  // 实时调用状态
  callStatus?: SegmentCallStatusType
  // 创建日期起始
  createdRangeFrom: string | undefined
  // 创建日期截止
  createdRangeTo: string | undefined
}

interface ListFilterPaneProps {
  onReset?: () => void
  onSearch?: (param: FilterParam) => void
}

// 列表过滤面板
const ListFilterPane: React.FC<ListFilterPaneProps> = ({ onSearch = noop, onReset = noop }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const handleSubmit = React.useCallback(
    ctx => {
      const values = formRef.current?.getFieldsValue(true) ?? {}
      onSearch({
        ...values,
        dateRange: undefined,
        createdRangeFrom: values.dateRange?.[0],
        createdRangeTo: values.dateRange?.[1],
      })
    },
    [onSearch]
  )

  const handleReset = React.useCallback(
    ctx => {
      formRef.current?.reset()
      onReset()
    },
    [onReset]
  )

  const width = 260

  return (
    <div className='d-flex justify-content-between'>
      <Form ref={formRef} className='d-flex align-items-center flex-wrap' labelAlign='top' onSubmit={handleSubmit}>
        <FormItem className='mb-2 me-2' label='人群名称' name='segmentName'>
          <Input placeholder='请输入人群名称，最多32个字符' maxlength={32} style={{ width: width }} />
        </FormItem>
        <FormItem className='mb-2 me-2' label='类型' name='segmentType'>
          <Select style={{ width: width }} options={segmentTypeEnum.list} placeholder='不限'></Select>
        </FormItem>
        <FormItem className='mb-2 me-2' label='人群包状态' name='segmentStatus'>
          <Select placeholder='不限' options={segmentStatusEnum.filter('segment')} style={{ width: width }} />
        </FormItem>
        <FormItem className='mb-2 me-2' label='实时调用状态' name='callStatus'>
          <Select style={{ width: width }} options={segmentRealTimeStatusEnum.list} placeholder='不限'></Select>
        </FormItem>
        <FormItem className='mb-2 me-2' label='创建时间' name='dateRange'>
          <DateRangePicker style={{ width }} />
        </FormItem>
      </Form>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button type='submit' onClick={() => formRef.current?.submit()}>
          搜索
        </Button>
        <Button className='ms-2' variant='outline' onClick={handleReset}>
          重置
        </Button>
      </div>
    </div>
  )
}

export { ListFilterPane }
