import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { RoleDetailPage } from './RoleDetailPage'
import RoleListPage from './RoleListPage'

export default () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/:roleId`} render={() => <RoleDetailPage />} />
      <Route path='' exact render={() => <RoleListPage />} />
    </Switch>
  )
}
