import { useCallback } from 'react'
import { useAppSelector } from '../../app/hooks'
import { isAuthenticated, selectActiveMembership, selectActiveWorkspace, selectSession } from './globalSlice'

type StringToStringFunc = (s: string) => string

// 如果在一个 workspace 中，自动将路径添加 workspace 前缀
export const useWorkspaceAwareUrl = (): StringToStringFunc => {
  const activeWorkspace = useAppSelector(selectActiveWorkspace)
  const url = useCallback(
    (to: string) => {
      const fixedTo = to && to.charAt(0) === '/' ? to : `/${to}`
      if (!activeWorkspace) {
        return fixedTo
      }
      return `/workspaces/${activeWorkspace}${fixedTo}`
    },
    [activeWorkspace]
  )
  return url
}

// FIXME: 等待修复
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRBAC = () => {
  const authenticated = useAppSelector(isAuthenticated)
  const membership = useAppSelector(selectActiveMembership)
  const session = useAppSelector(selectSession)

  const hasSystemRole = ({ role }: { role: string }) => {
    if (!session) {
      return false
    }

    const { roles } = session.user
    if (!roles) {
      return false
    }

    return roles.findIndex((r: string) => `${r}`.toLocaleLowerCase() === `${role}`.toLocaleLowerCase()) > -1
  }

  const hasPermission = ({ permission }: { permission: string }) => {
    if (!permission) {
      return true
    }
    if (!membership) {
      return false
    }
    const result = membership.permissions.find(p => p === permission)

    return !!result
  }

  const hasAnyPermission = (permissions: string[]) =>
    permissions?.length ? permissions.some(p => hasPermission({ permission: p })) : false

  return { authenticated, hasPermission, hasSystemRole, hasAnyPermission }
}
