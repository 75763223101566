import { Route, Switch, useRouteMatch } from 'react-router-dom'
import StrategyManagePage from './StrategyManagePage'
import CreateOrUpdateStrategyPage from './CreateOrUpdateStrategyPage'
import StrategyDetailPage from './StrategyDetailPage'
import SegmentDetailPage from '../../SegmentPage/SegmentDetailPage'

export default () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.url}/create`} render={() => <CreateOrUpdateStrategyPage />} />
      <Route exact path={`${match.url}/read/:strategyId`} render={() => <StrategyDetailPage />} />
      <Route path={`${match.path}/segment/read/:segmentId`} exact render={() => <SegmentDetailPage readonly />} />
      <Route path='' render={() => <StrategyManagePage />} />
    </Switch>
  )
}
