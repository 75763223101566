import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Session as SessionBase } from '../../shared/models/auth'
import { eventBus } from '../../utils/eventBus'
import { Organization, User, QueryOrgQuotaRes } from '@dmp/api-sdk'
import {
  bootstrapApplication,
  invalidateSession,
  signupNew,
  newSession,
  loadMemberships,
  loadOrgQuota,
} from './actions'

export interface FeatureSwitches {
  openLoginSideImage: boolean // 登录页大图
  openLoginAuth0: boolean // 登录支持 auth0
  openSignupSideImage: boolean // 注册页大图
  openSignupAuth0: boolean // 注册页 auth0
  openNavDataSource: boolean // 打开数据源导航
}

const switches: FeatureSwitches = {
  openLoginAuth0: !!localStorage.getItem('REACT_APP_OPEN_AUTH0') || process.env.REACT_APP_OPEN_AUTH0 === 'true',
  openLoginSideImage:
    !!localStorage.getItem('REACT_APP_OPEN_LOGIN_IMAGE') || process.env.REACT_APP_OPEN_LOGIN_IMAGE === 'true',
  openSignupAuth0: !!localStorage.getItem('REACT_APP_OPEN_AUTH0') || process.env.REACT_APP_OPEN_AUTH0 === 'true',
  openSignupSideImage:
    !!localStorage.getItem('REACT_APP_OPEN_SIGNUP_IMAGE') || process.env.REACT_APP_OPEN_SIGNUP_IMAGE === 'true',
  openNavDataSource:
    !!localStorage.getItem('REACT_APP_OPEN_NAV_DATASOURCE') || process.env.REACT_APP_OPEN_NAV_DATASOURCE === 'true',
}

export interface Project {
  id: string
  name: string
  description: string
  disabled?: boolean
  tags: string[]
}

type Session = SessionBase<{
  user: User
  projects: Project[]
  organizations: Organization[]
}>

interface GlobalState {
  [x: string]: any
  status: 'idle' | 'loading'
  profile: {
    show: boolean
  }
  // 2021-11-12 没有登录，就没有 session
  session: Session | undefined
  switches: FeatureSwitches
  authenticated: boolean
  // 当前用户所属组织信息
  organization: Organization | null
  // 企业配额信息
  quota: QueryOrgQuotaRes['result'] | null
}

const initialState: GlobalState = {
  status: 'idle',
  profile: {
    show: false,
  },
  session: undefined,
  switches,
  authenticated: false,
  organization: null,
  quota: null,
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization = action.payload
    },
    showProfile: state => {
      state.profile.show = true
    },
    hideProfile: state => {
      state.profile.show = false
    },

    // 需要登录
    requiredAuthentication: state => {
      state.authenticated = false
    },
  },

  extraReducers: builder => {
    builder.addCase(loadMemberships.fulfilled, (state, action) => {
      if (state?.session) {
        state.session.memberships = action.payload
      }
    })

    builder
      .addCase(bootstrapApplication.pending, state => {
        state.status = 'loading'
      })
      .addCase(bootstrapApplication.rejected, state => {
        state.status = 'idle'
        state.authenticated = false
      })
      .addCase(bootstrapApplication.fulfilled, (state, action) => {
        state.status = 'idle'
        if (!action.payload) return
        state.organization = action.payload?.organization || null
        const session: Session = {} as Session
        const { user } = action.payload
        if (user) {
          session.user = user
        }
        state.session = session
        state.authenticated = true // FIXME: 这里假设验证身份成功
      })

    builder
      .addCase(invalidateSession.pending, state => {
        state.status = 'loading'
      })
      .addCase(invalidateSession.rejected, state => {
        // 还能注销失败？
        state.status = 'idle'
      })
      .addCase(invalidateSession.fulfilled, state => {
        state.status = 'idle'
        state.authenticated = false
        state.session = undefined
        eventBus.emit('logout')
      })
      .addCase(newSession.pending, state => {
        state.status = 'loading'
      })
      .addCase(newSession.rejected, (state, action) => {
        console.log('on reject, payload -> ', action.payload)
        console.log('on reject, error -> ', action.error)
        state.status = 'idle'
      })
      .addCase(newSession.fulfilled, state => {
        state.status = 'idle'
        state.authenticated = true
      })

    builder
      .addCase(signupNew.pending, state => {
        state.status = 'loading'
      })
      .addCase(signupNew.rejected, state => {
        state.status = 'idle'
      })
      .addCase(signupNew.fulfilled, (state, action) => {
        state.status = 'idle'
      })

    builder.addCase(loadOrgQuota.fulfilled, (state, action) => {
      state.quota = action.payload
    })
  },
})

// actions
export const { showProfile, hideProfile, requiredAuthentication, setOrganization } = globalSlice.actions

// selectors
export const selectStatus = (state: RootState) => state.global.status
export const selectSession = (state: RootState) => state.global.session
export const selectActiveWorkspace = (state: RootState) => state.workspace?.workspace?.id
export const selectorSwitches = (state: RootState) => state.global.switches
export const isAuthenticated = (state: RootState) => state.global.authenticated
export const selectOrganization = (state: RootState) => state.global.organization
export const selectOrgQuota = (state: RootState) => state.global.quota

// 选择当前 workspace 的对应的 membership
export const selectActiveMembership = (state: RootState) => {
  const workspace = selectActiveWorkspace(state)
  if (workspace) {
    return state?.global?.session?.memberships?.find(m => m.workspaceId === workspace)
  } else {
    return state?.global?.session?.memberships?.[0]
  }
}

// reducer
export default globalSlice.reducer
