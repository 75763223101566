import React from 'react'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { useAppSelector } from '../../../app/hooks'
import { apiClient } from '../../../utils/apiClient'
import { Dialog, Form, FormItem, Select, FormRule } from '@dmp/components'

interface NewRoleModalProps {
  show?: boolean
  onHide: (refresh?: boolean) => void
}

const AddUserToSpaceModal: React.FC<NewRoleModalProps> = ({ show = false, onHide }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [loading, setLoading] = React.useState(false)
  const activeWorkspace = useAppSelector(store => store.workspace.workspace)
  const { data: members } = useQuery(['query/workspace/members', show], async () => {
    const res = await apiClient.queryStaffs(activeWorkspace?.id)
    return res.success ? res.data : []
  })

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      if (!activeWorkspace?.id) return
      setLoading(true)
      const values = formRef.current?.getFieldsValue(true)
      const res = await apiClient.moveUserInSpace({ workspaceId: activeWorkspace.id, userId: values.userId })
      setLoading(false)
      if (res.success) {
        toast.success('操作成功')
        onHide(true)
      } else {
        toast.error(res.message)
      }
    },
    [activeWorkspace, onHide]
  )

  const rules = React.useMemo(
    () => ({
      userId: [{ required: true, message: '请选择用户' }],
    }),
    []
  )

  return (
    <Dialog
      visible={show}
      header={`添加用户至工作空间 "${activeWorkspace?.name}"`}
      onClose={() => onHide()}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ loading }}
    >
      <Form labelAlign='top' rules={rules} ref={formRef} onSubmit={onSubmit}>
        <FormItem label='选择一个想要添加的用户' name='userId'>
          <Select placeholder='请选择用户'>
            {(members || []).map((m: any) => (
              <Select.Option
                key={m.id}
                value={m.id}
                className='text-truncate'
                label={`${m.displayName} (${m.email})`}
              />
            ))}
          </Select>
        </FormItem>
      </Form>
    </Dialog>
  )
}

export default AddUserToSpaceModal
