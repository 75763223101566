import React from 'react'
import { noop } from '@dmp/utils'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { WorkspaceRole } from '@dmp/api-sdk'
import { atom, useRecoilValue } from 'recoil'
import { apiClient } from '../../../utils/apiClient'
import { Dialog, Form, FormItem, Select, FormRule } from '@dmp/components'

export const showModalAtom = atom({
  key: 'showAssignRoleModal',
  default: false,
})

export interface AssignRoleModalProps {
  workspaceId: string
  proceed?: (candidates: Array<number>) => void
  cancel?: () => void
  roles?: WorkspaceRole[] // 已经授权的 role
}

const AssignRoleModal: React.FC<AssignRoleModalProps> = ({
  workspaceId,
  proceed = noop,
  cancel = noop,
  roles = [],
}) => {
  const show = useRecoilValue(showModalAtom)
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [allRoles, setAllRoles] = React.useState<WorkspaceRole[]>([])

  const { isLoading, isSuccess } = useQuery(['roles', show], () => apiClient.listWorkspaceRoles({ workspaceId }), {
    onSuccess: resp => {
      if (resp.success && resp.data) {
        setAllRoles(resp.data)
      } else {
        toast.error('加载数据出错了')
      }
    },
  })

  const rules = React.useMemo(
    () => ({
      role: [{ required: true, message: '请选择角色' }],
    }),
    []
  )

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      if (values?.role) proceed([values?.role])
    },
    [proceed]
  )

  const renderBody = () => {
    if (!isSuccess) {
      return <>出错了</>
    }

    // 过滤出还未选择的 role
    const candidates: any[] = allRoles.filter(r => !roles.some(r0 => r0.id === r.id)) ?? []

    return (
      <Form labelAlign='top' rules={rules} ref={formRef} onSubmit={onSubmit}>
        <FormItem label='选择一个角色' name='role'>
          <Select placeholder='请选择角色' options={candidates} keys={{ value: 'id', label: 'name' }} />
        </FormItem>
      </Form>
    )
  }

  return (
    <Dialog
      visible={show}
      header='分配角色给成员'
      onClose={cancel}
      onConfirm={() => formRef.current?.submit()}
      confirmBtnProps={{ loading: isLoading }}
    >
      {isLoading ? <>加载中 ...</> : <>{renderBody()}</>}
    </Dialog>
  )
}

export default AssignRoleModal
