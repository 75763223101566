import './index.scss'
import React from 'react'
import { Loading } from '@dmp/components'
import { useMutation } from 'react-query'
import { getChartsOptions } from './config'
import { SegmentPortraitConfig } from '@dmp/api-sdk'
import ECharts from '../../../../components/ECharts'
import { apiClient } from '../../../../utils/apiClient'
import { useAppSelector } from '../../../../app/hooks'
import { Module } from '../../../../layout/PageLayout'
import { selectActiveWorkspace } from '../../../../features/global/globalSlice'
import ChartEmpty from '../../../../assets/empty/chart_empty.png'

interface PropsType {
  config: SegmentPortraitConfig
  segmentId?: number
}

export const AttributeChart: React.FC<PropsType> = props => {
  const { segmentId, config } = props
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const insightMutation = useMutation(async () => {
    if (!(segmentId && workspaceId && config?.id)) return Promise.reject()
    const res = await apiClient.querySegmentAttributePortrait(workspaceId, { segmentId, configId: config.id })
    if (res?.success) {
      return res?.data
    } else {
      return Promise.reject()
    }
  })

  React.useEffect(() => {
    insightMutation.mutate()
  }, [])

  const options = React.useMemo(() => getChartsOptions(config, insightMutation?.data), [config, insightMutation?.data])

  return (
    <Loading loading={insightMutation.isLoading}>
      <Module icon={null} title={config?.labelCname}>
        {insightMutation.data?.length ? (
          <ECharts options={options} />
        ) : (
          <div className='d-flex align-items-center justify-content-center' style={{ height: 300 }}>
            <div className='text-center'>
              <img src={ChartEmpty} />
              <div style={{ color: '#CCCCCC' }}>暂无数据</div>
            </div>
          </div>
        )}
      </Module>
    </Loading>
  )
}
