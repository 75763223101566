import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { ApiResponseWrapper } from '@dmp/api-sdk'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import { Table } from '@dmp/components'
import { apiClient } from '../../../utils/apiClient'
import { TrialApplication, trialApplicationsAtom } from './state'
import { Module, WorkspacePageLayout } from '../../../layout/PageLayout'
import { UTCToLocal } from '@dmp/utils'

type PaginatedList = {
  content: TrialApplication[]
  size: number
  first: boolean
  last: true
  totalElements: number
  totalPages: number
}

// NOTE: 这里硬编码，取 ID 倒叙 Top100
const fetchList = async (workspaceId: string) => {
  const { data } = await apiClient.client.axios.get<ApiResponseWrapper<TrialApplication[] | PaginatedList>>(
    `/applyUse/list?workspaceId=${workspaceId}&size=100&sort=id,DESC`
  )
  return data
}

// TODO: 申请试用的表单中，应加入隐藏字段：
// - 实际提交者的 IP 地址
// - 实际提交者的 User-Agent
// - 页面首次打开时间 yyyy-MM-dd HH:mm:ss (+0800)
export const TrialApplicationListPage: React.FC<{ workspaceId: string }> = ({ workspaceId }) => {
  useEffect(() => {}, [])
  const [trials, setTrials] = useRecoilState(trialApplicationsAtom)

  const { isLoading } = useQuery(
    ['applicationForms'],
    () => {
      if (!workspaceId) return Promise.reject()
      return fetchList(workspaceId)
    },
    {
      onSuccess: resp => {
        // 兼容分页查询和不分页
        if (resp.success && resp.data) {
          if ('content' in resp.data) {
            const { content } = resp.data
            setTrials([...content])
          } else {
            setTrials(resp.data || [])
          }
        }
      },
      onError: () => {
        toast.error('加载数据出错了，请稍候刷新重试')
      },
    }
  )

  return (
    <WorkspacePageLayout title='试用申请'>
      <Module>
        <Table
          data={trials || []}
          rowKey='id'
          loading={isLoading}
          columns={[
            { title: '企业名称', colKey: 'companyName', width: 200, ellipsis: true },
            { title: '所属行业类型', colKey: 'industryType', width: 150, ellipsis: true },
            { title: '联系人姓名', colKey: 'contacts', width: 150, ellipsis: true },
            { title: '联系电话', colKey: 'mobile', width: 150, ellipsis: true },
            { title: '需求', colKey: 'remark', width: 200, ellipsis: true },
            { title: '提交时间', colKey: 'createTime', width: 200, cell: ({ row }) => UTCToLocal(row.createTime) },
          ]}
        />
      </Module>
    </WorkspacePageLayout>
  )
}
