import './index.scss'
import React from 'react'
import { toast } from 'react-toastify'
import { SegmentPortraitAttributeTypeEnum, SegmentPortraitConfig } from '@dmp/api-sdk'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../../utils/apiClient'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { WorkspacePageLayout, Module } from '../../../../layout/PageLayout'
import { Form, FormItem, Input, Textarea, Radio, Row, Col } from '@dmp/components'
import { readSegment, selectActiveSegment } from '../../../../features/segment'
import { useThrottledEffect } from '../../../../hooks'
import { AttributeChart } from './AttributeChart'

export const MainPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { segmentId } = useParams<{ segmentId: string }>()
  const activeSegment = useAppSelector(selectActiveSegment)
  const [attributes, setAttributes] = React.useState<SegmentPortraitConfig[]>()
  const [activeAttributeType, setActiveAttributeType] = React.useState(SegmentPortraitAttributeTypeEnum.Senior)

  const querySegmentPortraitConfig = React.useCallback(async () => {
    if (!activeSegment?.channel?.id) return
    const res = await apiClient.querySegmentPortraitConfig({
      attributeType: activeAttributeType,
      channelId: activeSegment?.channel?.id,
    })
    if (res.success) {
      setAttributes(res.data)
      return
    }
    if (res?.message) toast.error(res?.message)
  }, [activeAttributeType, activeSegment?.channel?.id])

  React.useEffect(() => {
    dispatch(readSegment({ id: segmentId }))
  }, [])

  useThrottledEffect(querySegmentPortraitConfig, [activeSegment?.channel?.id, activeAttributeType])

  if (!activeSegment) return <>loading ...</>

  return (
    <WorkspacePageLayout title='人群洞察' hasBack className='insight-segment'>
      <Module title='基本信息' hasGap>
        <Form labelAlign='left' labelWidth={120} disabled>
          <FormItem label='人群包名称' name='name' initialData={activeSegment?.name}>
            <Input placeholder='请输入人群包名称，最多32个字符' maxlength={32} />
          </FormItem>
          <FormItem label='人群包描述' name='description' initialData={activeSegment?.description}>
            <Textarea placeholder='请输入人群包描述，最多256个字符' maxlength={256} />
          </FormItem>
          <FormItem label='预估人数' name='velocity' initialData={activeSegment?.velocity}>
            <Input />
          </FormItem>
        </Form>
      </Module>
      <div className='insight-segment_attribute-type__wrapper'>
        <Radio.Group
          value={activeAttributeType}
          onChange={val => setActiveAttributeType(val as SegmentPortraitAttributeTypeEnum)}
        >
          <Radio.Button value={SegmentPortraitAttributeTypeEnum.Basic}>基础属性</Radio.Button>
          <Radio.Button value={SegmentPortraitAttributeTypeEnum.Senior}>高级属性</Radio.Button>
        </Radio.Group>
      </div>
      <Row className='insight-segment_attribute-charts__wrapper' gutter={20}>
        {attributes?.map(config => {
          return (
            <Col md={6} span={12} key={config.labelName}>
              <AttributeChart config={config} segmentId={activeSegment?.id} />
            </Col>
          )
        })}
      </Row>
    </WorkspacePageLayout>
  )
}
