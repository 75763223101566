import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import Filters from './Filters'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { useExpand, useThrottledEffect } from '../../../hooks'
import { CallSummary, QueryCallSummaryReq } from '@dmp/api-sdk'
import { Table, TableCellParams, Radio } from '@dmp/components'
import { UTCToLocal, digitization, percentage } from '@dmp/utils'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

export enum TabsEnum {
  Organization = '1',
  Workspace = '2',
  Segment = '3',
}
export const useRadioTabs = () => {
  const list = [
    { value: TabsEnum.Organization, label: '企业维度数据' },
    { value: TabsEnum.Workspace, label: '空间维度数据' },
    { value: TabsEnum.Segment, label: '人群包维度数据' },
  ]
  const [value, setValue] = React.useState(list[0].value)

  return {
    value,
    radioTabs: (
      <Radio.Group value={value} onChange={val => setValue(val as TabsEnum)}>
        {list.map(it => (
          <Radio.Button value={it.value} key={it.value}>
            {it.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    ),
  }
}

export default () => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { value: activeKey, radioTabs } = useRadioTabs()
  const { expand, expandIcon } = useExpand()
  const [filters, setFilters] = React.useState<QueryCallSummaryReq>()
  const { page = '1', size = '10' } = qs.parse(location.search)

  const {
    isLoading,
    mutate,
    data: dataSource,
  } = useMutation(async () => {
    const res = await apiClient.queryCallSummaryPage({
      page: Number(page) - 1,
      size: Number(size),
      sort: 'created_at,desc',
      ...(filters || {}),
    })

    if (res.success) {
      return res.data
    } else {
      toast.error(res.message)
      return Promise.reject()
    }
  })

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${match.url}?page=${current}&size=${pageSize}`)
    },
    [match, history]
  )

  const handleSearch = React.useCallback(
    (values?: QueryCallSummaryReq) => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const columns = React.useMemo(() => {
    return [
      {
        title: '请求时间',
        colKey: 'createdAt',
        width: 200,
        fixed: 'left' as const,
        cell({ row }: TableCellParams<CallSummary>) {
          return UTCToLocal(row.createdAt)
        },
      },
      {
        title: '所属企业',
        colKey: 'organizationName',
        width: 150,
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return row.organization?.name
        },
      },
      {
        title: '所属工作空间',
        colKey: 'workspaceName',
        width: 150,
        ellipsis: true,
        hidden: ![TabsEnum.Workspace, TabsEnum.Segment].includes(activeKey),
      },
      {
        title: '所属数据源',
        colKey: 'channel',
        width: 150,
        ellipsis: true,
        hidden: activeKey !== TabsEnum.Segment,
        cell({ row }: TableCellParams<CallSummary>) {
          return row.channel?.name
        },
      },
      {
        title: '人群包名称',
        width: 200,
        colKey: 'segmentName',
        ellipsis: true,
        hidden: activeKey !== TabsEnum.Segment,
      },
      {
        title: '请求次数',
        width: 150,
        colKey: 'requestCount',
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return digitization(row.requestCount)
        },
      },
      {
        title: '返回次数',
        width: 150,
        colKey: 'returnCount',
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return digitization(row.returnCount)
        },
      },
      {
        title: '返回命中数',
        width: 150,
        colKey: 'returnHitCount',
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return digitization(row.returnHitCount)
        },
      },
      {
        title: '查得率',
        width: 150,
        colKey: 'searchRate',
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return percentage(row.searchRate)
        },
      },
      {
        title: '返回未命中数',
        width: 150,
        colKey: 'returnsMissCount',
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return digitization(row.returnsMissCount)
        },
      },
      {
        title: '返回其他错误数',
        width: 150,
        colKey: 'returnOtherErrorCount',
        ellipsis: true,
        cell({ row }: TableCellParams<CallSummary>) {
          return digitization(row.returnOtherErrorCount)
        },
      },
    ]
  }, [activeKey])

  useThrottledEffect(mutate, [page, size, filters])

  return (
    <WorkspacePageLayout title='调用汇总' headerRight={expandIcon} headerChildren={radioTabs}>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters activeKey={activeKey} onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          columns={columns}
          data={dataSource?.items || []}
          loading={isLoading}
          rowKey='id'
          // footData={dataSource?.statistics ? [dataSource?.statistics] : []}
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: dataSource?.total || 0,
            onChange: onPaginationChange,
          }}
        />
      </Module>
    </WorkspacePageLayout>
  )
}
