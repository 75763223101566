import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { useHistory, useLocation } from 'react-router'
import { useAppSelector } from '../../../app/hooks'
import { useMutation, useQuery } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import CopyableText from '../../../components/CopyableText'
import { WorkspacePageLayout, Module } from '../../../layout/PageLayout'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { CreateOrUpdateStrategyValueAtom } from '../../../recoil/rta/strategy'
import { Button, Alert, Form, FormItem, Input, Select } from '@dmp/components'
import { BidUrl, CreateOrUpdateStrategyReg, SegmentCallStatusType } from '@dmp/api-sdk'
import { platformEnum, rtaStrategyAttributeEnum, rtaStrategyStatusEnum } from '../../../constants/enums'
import { composeBidUrl } from '../../../utils'

function BidUrlIdWatched({ launchBindId, options }: { launchBindId?: number; options: Array<BidUrl> }) {
  if (!launchBindId) return null
  const item = options?.find(it => it.id == launchBindId)
  return (
    <FormItem>
      <Alert
        style={{ width: '100%' }}
        icon={<i />}
        message={
          <>
            <FormItem label='广告平台'>{platformEnum.map.get(item?.advertPlatform)?.label}</FormItem>
            <FormItem label='bidUrl'>{composeBidUrl(item)}</FormItem>
          </>
        }
      />
    </FormItem>
  )
}

function SegmentIdWatched({ segmentId, onBeforeLeave }: { segmentId?: number; onBeforeLeave: () => void }) {
  const workspaceId = useAppSelector(selectActiveWorkspace)
  const history = useHistory()

  const handleDetailClick = React.useCallback(() => {
    if (!workspaceId) return
    onBeforeLeave()
    history.push(`/workspaces/${workspaceId}/strategies/segment/read/${segmentId}`)
  }, [segmentId, workspaceId, onBeforeLeave, history])

  if (!segmentId) return null
  return (
    <div className='col-1 mt-2 ms-3'>
      <span className='link-action me-2' onClick={handleDetailClick}>
        详情
      </span>
    </div>
  )
}

export default () => {
  const history = useHistory()
  const location = useLocation()
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [segmentId, setSegmentId] = React.useState<number>()
  const [launchBindId, setLaunchBindId] = React.useState<number>()
  const workspaceId = useAppSelector(selectActiveWorkspace) || ''
  const [strategyValue, setStrategyValue] = useRecoilState(CreateOrUpdateStrategyValueAtom)
  const { platform } = qs.parse(location.search)

  const createOrUpdateMutation = useMutation(
    (params: CreateOrUpdateStrategyReg) => {
      if (!workspaceId) return Promise.reject()
      params.workspaceId = workspaceId
      return apiClient.createOrUpdateStrategy(params)
    },
    {
      onSuccess: (res: any) => {
        if (res.success) {
          toast.success('创建成功')
          if (!res.data?.strategyId) return
          setStrategyValue(null)
          history.replace(`/workspaces/${workspaceId}/strategies/read/${res.data?.strategyId}`)
        } else {
          toast.error(res.message)
        }
      },
      onError: () => {
        toast.error('系统异常')
      },
    }
  )

  const strategyIdQuery = useMutation(() => (workspaceId ? apiClient.queryStrategyId(workspaceId) : Promise.reject()), {
    onSuccess(res: any) {
      if (res.success && res.data) {
        formRef.current?.setFieldsValue({ strategyId: res.data })
      }
    },
  })

  const bidUrlQuery = useQuery(['queryBoundBidUrls', workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryBoundBidUrls({ workspaceId, advertPlatform: platform as string })
    return res.success ? res.data : Promise.reject()
  })

  const segmentsQuery = useQuery(['querySegments', workspaceId], async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.querySegmentList({ workspaceId, callStatus: SegmentCallStatusType.Available })
    return res.success ? res.data : Promise.reject()
  })

  const onSubmit = React.useCallback(
    async ctx => {
      if (ctx.validateResult !== true) return
      const values = formRef.current?.getFieldsValue(true)
      createOrUpdateMutation.mutate(values)
    },
    [createOrUpdateMutation]
  )

  const handleCancel = React.useCallback(() => {
    setStrategyValue(null)
    history.goBack()
  }, [history, setStrategyValue])

  const handleBeforeLeave = React.useCallback(() => {
    setStrategyValue(formRef?.current?.getFieldsValue(true))
  }, [setStrategyValue])

  const init = React.useCallback(() => {
    const values: any = {
      ...strategyValue,
      status: strategyValue?.status ?? rtaStrategyStatusEnum.list[0].value,
      strategyProperty: strategyValue?.strategyProperty ?? rtaStrategyAttributeEnum.list[0].value,
    }
    formRef?.current?.setFieldsValue(values)
    setSegmentId(values.segmentId)
    setLaunchBindId(values.launchBindId)
    if (!strategyValue?.strategyId) {
      strategyIdQuery.mutate()
    }
  }, [strategyValue, strategyIdQuery])

  const rules = React.useMemo(
    () => ({
      name: [{ required: true, message: '请输入策略名称' }],
      strategyId: [{ required: true, message: '请输入RTA策略id' }],
      launchBindId: [{ required: true, message: '请选择已绑定的投放平台' }],
      segmentId: [{ required: true, message: '请选择人群' }],
      strategyProperty: [{ required: true, message: '请选择策略属性' }],
      status: [{ required: true, message: '请选择状态' }],
    }),
    []
  )

  React.useEffect(() => {
    init()
  }, [])

  return (
    <WorkspacePageLayout title='新建RTA策略' hasBack>
      <Module>
        <Form ref={formRef} labelAlign='left' rules={rules} onSubmit={onSubmit} labelWidth={150} onReset={handleCancel}>
          <FormItem label='策略名称' name='name'>
            <Input placeholder='请输入策略名称，最多32个字符' maxlength={32} />
          </FormItem>
          <FormItem label='RTA策略id' name='strategyId'>
            <CopyableText placeholder='请输入RTA策略id，最多64个字符' maxlength={64} readonly />
          </FormItem>
          <FormItem label='选择已绑定的投放平台' name='launchBindId'>
            <Select
              placeholder='请选择已绑定的投放平台'
              options={(bidUrlQuery?.data || []) as any}
              keys={{ value: 'id', label: 'name' }}
              onChange={val => setLaunchBindId(val as number)}
            />
          </FormItem>
          <BidUrlIdWatched launchBindId={launchBindId} options={bidUrlQuery?.data || []} />
          <FormItem label='圈定人群' name='segmentId'>
            <Select
              options={(segmentsQuery?.data || []) as any}
              placeholder='请选择人群'
              keys={{ value: 'id', label: 'name' }}
              onChange={val => setSegmentId(val as number)}
            />
            <SegmentIdWatched segmentId={segmentId} onBeforeLeave={handleBeforeLeave} />
          </FormItem>
          <FormItem label='策略属性' name='strategyProperty'>
            <Select options={rtaStrategyAttributeEnum.list} placeholder='请选择策略属性' />
          </FormItem>
          <FormItem label='状态' name='status'>
            <Select options={rtaStrategyStatusEnum.list} placeholder='请选择状态' />
          </FormItem>
          <div className='mt-5 d-flex justify-content-between'>
            <Button variant='outline' type='reset'>
              取消
            </Button>
            <Button type='submit'>确认</Button>
          </div>
        </Form>
      </Module>
    </WorkspacePageLayout>
  )
}
