import { toast } from 'react-toastify'
import { UTCToLocal } from '@dmp/utils'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { WorkspaceRole } from '@dmp/api-sdk'
import { useAppSelector } from '../../app/hooks'
import { apiClient } from '../../utils/apiClient'
import NewRoleModal from './components/NewRoleModal'
import { workspaceRolesAtom } from '../../recoil/role'
import { useQuery, useQueryClient } from 'react-query'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { Module, WorkspacePageLayout } from '../../layout/PageLayout'
import { Button, Table, TableCellParams, dialog } from '@dmp/components'
import { selectActiveWorkspace } from '../../features/global/globalSlice'
import { selectWorkspaceQuota } from '../../features/workspace/workspaceSlice'
import WsQuotaLine from '../../components/WsQuotaLine'

const LiteView = () => {
  const his = useHistory()
  const match = useRouteMatch()
  const queryClient = useQueryClient()
  const workspaceRoles = useRecoilValue(workspaceRolesAtom)
  const setWorkspaceRoles = useSetRecoilState(workspaceRolesAtom)
  const activeWorkspace = useAppSelector(selectActiveWorkspace)

  // 加载 workspace-scope roles
  useQuery(
    ['workspaceRoles', activeWorkspace],
    () => {
      if (!activeWorkspace) return Promise.reject()
      return apiClient.listWorkspaceRoles({ workspaceId: activeWorkspace })
    },
    {
      staleTime: 1 * 1000, // 30s 过期
      onSuccess: resp => {
        if (resp.success && resp.data) {
          setWorkspaceRoles([...resp.data])
        }
      },
    }
  )

  const handleDelete = useCallback(
    async (role: WorkspaceRole) => {
      if (!role || !activeWorkspace) {
        return
      }
      dialog({
        icon: <i className='iconfont icon-warning-fill text-warning' />,
        body: `确认要删除角色——${role?.name}吗?`,
        async onConfirm({ hide }) {
          const res = await apiClient.deleteWorkspaceRole({ workspaceId: activeWorkspace, roleId: role.id })
          if (res?.success) {
            toast.success('删除成功')
            hide?.()
            queryClient.invalidateQueries(['workspaceRoles', activeWorkspace])
            return
          }
          toast.error(res.message || '删除失败')
        },
      })
    },
    [activeWorkspace, queryClient]
  )

  const columns = useMemo(() => {
    return [
      {
        title: '名称',
        width: 200,
        colKey: 'name',
        ellipsis: true,
        cell({ row }: TableCellParams<WorkspaceRole>) {
          return <Link to={`${match.url}/${row.id}`}>{row.name}</Link>
        },
      },
      { title: '描述', width: 150, ellipsis: true, colKey: 'description' },
      {
        title: '创建时间',
        colKey: 'createdAt',
        width: 200,
        cell({ row }: TableCellParams<WorkspaceRole>) {
          return UTCToLocal(row.createdAt)
        },
      },
      {
        title: '操作',
        width: 200,
        colKey: 'action',
        cell({ row }: TableCellParams<WorkspaceRole>) {
          return (
            <>
              <Button
                variant='text'
                theme='primary'
                className='me-4'
                onClick={() => his.push(`${match.url}/${row.id}`)}
              >
                详情
              </Button>
              {!(row.admin || row.locked) && (
                <Button variant='text' theme='danger' className='me-4' onClick={() => handleDelete(row)}>
                  删除
                </Button>
              )}
            </>
          )
        },
      },
    ]
  }, [handleDelete, his, match.url])

  return <Table columns={columns} empty='还没有任何角色' data={workspaceRoles || []} rowKey='id' />
}

const RoleListPage: React.FC = () => {
  const queryClient = useQueryClient()
  const wsQuotaResult = useAppSelector(selectWorkspaceQuota)
  const activeWorkspace = useAppSelector(selectActiveWorkspace)

  const [showModal, setShowModal] = useState(false)

  const onProceed = useCallback(() => {
    if (!activeWorkspace) {
      return
    }
    setShowModal(false)
    queryClient.invalidateQueries(['workspaceRoles', activeWorkspace])
  }, [queryClient, activeWorkspace])

  return (
    <WorkspacePageLayout
      title='角色管理'
      remark='工作空间所属所有角色。角色包含了一组权限，可以分配给用户。'
      headerTop={
        <WsQuotaLine
          list={[
            { label: '已有角色数量', value: wsQuotaResult?.ROLE_QUOTA?.useCount },
            { label: '剩余角色数量', value: wsQuotaResult?.ROLE_QUOTA?.surplusCount },
          ]}
        />
      }
      headerRight={
        <>
          <Button icon={<i className='iconfont icon-add-select' />} onClick={() => setShowModal(true)}>
            新建角色
          </Button>
        </>
      }
    >
      <Module>
        <LiteView />
      </Module>
      <NewRoleModal
        workspaceId={activeWorkspace}
        show={showModal}
        cancel={() => setShowModal(false)}
        proceed={onProceed}
      />
    </WorkspacePageLayout>
  )
}

export default RoleListPage
