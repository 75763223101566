import clsx from 'clsx'
import React from 'react'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { apiClient } from '../../../utils/apiClient'
import { useThrottledEffect } from '../../../hooks'
import { QueryCrowdPackageLaunchDetailsReq, CrowdPackageLaunchDetail } from '@dmp/api-sdk'
import { Table, Button, Form, FormItem, Input, Select, DateRangePicker, Dialog, TableColumnType } from '@dmp/components'
import { Module } from '../../../layout/PageLayout'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectActiveWorkspace } from '../../../features/global/globalSlice'
import { platformEnum } from '../../../constants'
import { digitization } from '@dmp/utils'
import { defaultFilters } from './Advertiser'

const CrowdPackageDetail: React.FC<{
  visible: boolean
  onClose: () => void
  info: CrowdPackageLaunchDetail | null
}> = ({ visible, info, onClose }) => {
  const { data, isLoading, mutate } = useMutation(async () => {
    if (!info?.segmentIdList?.length) return []
    const res = await apiClient.querySegmentInfosOfReport({ segmentIds: info?.segmentIdList })
    if (res?.success) return res.data || []
  })

  React.useEffect(() => {
    if (visible) mutate()
  }, [visible])

  return (
    <Dialog width={600} visible={visible} header='人群包详情' onClose={onClose} onConfirm={onClose}>
      <Table
        rowKey='id'
        data={data}
        maxHeight={350}
        loading={isLoading}
        columns={[
          { title: '人群包名称', colKey: 'segmentName', width: 150, ellipsis: true },
          { title: '所属企业', colKey: 'organizationName', width: 150, ellipsis: true },
          { title: '所属工作空间', colKey: 'workspaceName', width: 150, ellipsis: true },
        ]}
      />
    </Dialog>
  )
}

const Filters: React.FC<{
  onSearch: (values?: QueryCrowdPackageLaunchDetailsReq) => void
}> = ({ onSearch }) => {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const handleSubmit = React.useCallback(() => {
    const values = formRef.current?.getFieldsValue(true)
    onSearch({
      ...values,
      dateRange: undefined,
      startDate: values?.dateRange?.[0],
      endDate: values?.dateRange?.[1],
    })
  }, [onSearch])

  const handleReset = React.useCallback(() => {
    formRef.current?.reset()
    setTimeout(handleSubmit, 100)
  }, [handleSubmit])

  React.useEffect(() => {
    handleSubmit()
  }, [])

  const width = 290
  return (
    <Form ref={formRef} className='d-flex' labelAlign='top' onSubmit={handleSubmit} onReset={handleReset}>
      <div className='flex-fill d-flex align-items-center flex-wrap'>
        <FormItem label='企业名称' name='organizationName' className='mb-2 me-2'>
          <Input placeholder='请输入企业名称' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告平台' name='advertPlatform' className='mb-2 me-2'>
          <Select placeholder='不限' style={{ width }} options={platformEnum.list} />
        </FormItem>
        <FormItem label='已授权帐号' name='accountId' className='mb-2 me-2'>
          <Input placeholder='请输入帐号ID' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告主ID' name='advertiserId' className='mb-2 me-2'>
          <Input placeholder='请输入广告主ID' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告计划ID' name='campaignId' className='mb-2 me-2'>
          <Input placeholder='请输入广告计划ID' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告组名称' name='adgroupName' className='mb-2 me-2'>
          <Input placeholder='请输入广告组名称' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='广告组ID' name='adgroupId' className='mb-2 me-2'>
          <Input placeholder='请输入广告组ID' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='人群包名称' name='segmentName' className='mb-2 me-2'>
          <Input placeholder='请输入人群包名称' style={{ width }} maxlength={32} />
        </FormItem>
        <FormItem label='创建时间' name='dateRange' className='mb-2 me-2' initialData={defaultFilters.dateRange}>
          <DateRangePicker
            style={{ width }}
            format='YYYY-MM-DD'
            endDisableDate={{ after: defaultFilters.dateRange[1] }}
          />
        </FormItem>
      </div>
      <div className='ms-2 flex-shrink-0' style={{ marginTop: 22 }}>
        <Button type='submit'>搜索</Button>
        <Button className='ms-2' variant='outline' type='reset'>
          重置
        </Button>
      </div>
    </Form>
  )
}

export default (props: { expand: boolean }) => {
  const { expand } = props
  const history = useHistory()
  const location = useLocation()
  const workspaceId = useAppSelector(selectActiveWorkspace)

  const [visible, setVisible] = React.useState(false)
  const [crowdInfo, setCrowdInfo] = React.useState(null)
  const [filters, setFilters] = React.useState()

  const search = qs.parse(location.search)
  const { page = '1', size = '10' } = search

  const onPaginationChange = React.useCallback(
    ({ current, pageSize }) => {
      history.replace(`${location.pathname}?${qs.stringify({ ...search, page: current, size: pageSize })}`)
    },
    [location.pathname, history, search]
  )

  const { isLoading, mutate, data } = useMutation(async () => {
    if (!workspaceId) return Promise.reject()
    const res = await apiClient.queryCrowdPackageLaunchDetailsByPage(
      {
        page: Number(page) - 1,
        size: Number(size),
        ...(filters || {}),
      },
      workspaceId
    )
    if (res.success) {
      return res.data
    }
    toast.error(res.message)
    return Promise.reject()
  })

  const handleSearch = React.useCallback(
    values => {
      setFilters(values)
      onPaginationChange({ current: 1, pageSize: Number(size) })
    },
    [onPaginationChange, size]
  )

  const handleViewCrowdInfo = React.useCallback(row => {
    setVisible(true)
    setCrowdInfo(row)
  }, [])

  const columns = React.useMemo<TableColumnType<CrowdPackageLaunchDetail>[]>(() => {
    return [
      { title: '时间', colKey: 'date', width: 140, fixed: 'left', foot: '合计' },
      { title: '企业名称', colKey: 'organization_name', width: 150, ellipsis: true },
      {
        title: '广告平台',
        colKey: 'advert_platform',
        width: 150,
        cell: ({ row }) => platformEnum.map.get(row?.advert_platform)?.label,
      },
      { title: '已授权账号', colKey: 'account_id', width: 180, ellipsis: true },
      { title: '广告组名称', colKey: 'adgroup_name', width: 180, ellipsis: true },
      { title: '广告主ID', colKey: 'advertiser_id', width: 180, ellipsis: true },
      { title: '广告计划ID', colKey: 'campaign_id', width: 180, ellipsis: true },
      { title: '广告组ID', colKey: 'adgroup_id', width: 180, ellipsis: true },
      {
        title: '绑定人群包',
        colKey: 'segment_names',
        width: 180,
        ellipsis: true,
        cell: ({ row }) => (
          <Button variant='text' theme='primary' content={row.segment_names} onClick={() => handleViewCrowdInfo(row)} />
        ),
      },
      {
        title: '展现',
        colKey: 'view_count',
        width: 150,
        cell: ({ row }) => digitization(row.view_count),
        foot: ({ row }) => digitization(row.view_count),
      },
      {
        title: '点击',
        colKey: 'valid_click_count',
        width: 150,
        cell: ({ row }) => digitization(row.valid_click_count),
        foot: ({ row }) => digitization(row.valid_click_count),
      },
      {
        title: '点击率',
        colKey: 'ctr',
        width: 150,
        // 点击/展现, 展现为0,用-表示无效态
        cell: ({ row }) => (row.view_count > 0 ? `${row.ctr || 0}%` : '-'),
        foot: ({ row }) => (row.view_count > 0 ? `${row.ctr || 0}%` : '-'),
      },
      {
        title: 'ecpm/元',
        colKey: 'ecpm',
        width: 150,
        // 消耗*1000/展现, 展现为0,用-表示无效态
        cell: ({ row }) => (row.view_count > 0 ? digitization(row.ecpm) : '-'),
        foot: ({ row }) => (row.view_count > 0 ? digitization(row.ecpm) : '-'),
      },
      {
        title: 'cpc/元',
        colKey: 'cpc',
        width: 150,
        // 消耗/点击， 点击为0， 用-表示无效态
        cell: ({ row }) => (row.valid_click_count ? digitization(row.cpc) : '-'),
        foot: ({ row }) => (row.valid_click_count ? digitization(row.cpc) : '-'),
      },
      {
        title: '消耗/元',
        colKey: 'cost',
        width: 150,
        cell: ({ row }) => digitization(row.cost),
        foot: ({ row }) => digitization(row.cost),
      },
    ]
  }, [handleViewCrowdInfo])

  useThrottledEffect(mutate, [page, size, filters])

  return (
    <>
      <Module hasGap className={clsx({ 'd-none': !expand })}>
        <Filters onSearch={handleSearch} />
      </Module>
      <Module>
        <Table
          rowKey='id'
          loading={isLoading}
          data={data?.items}
          footData={data?.statistics ? [data?.statistics] : []}
          pagination={{
            current: Number(page),
            pageSize: Number(size),
            total: data?.total || 0,
            onChange: onPaginationChange,
          }}
          columns={columns}
        />
      </Module>
      <CrowdPackageDetail visible={visible} onClose={() => setVisible(false)} info={crowdInfo} />
    </>
  )
}
